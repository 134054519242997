import React, {useEffect, useState, useContext} from "react";
import Datetime from "react-datetime";
import "moment-timezone";
import {unzeroDay, dayCalendar, colorSkeleton} from "../../utils/helpers";
import {UserContext} from "../../context/user";
import moment from "moment";
import api from "../../api";
import CalendarItems from "../calendar-items/CalendarItems";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const CalendarMini = () => {
  const SUser = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [now, setNow] = useState(null); // выбранный день

  const [churchDates, setChurchDates] = useState({});
  const [notices, setNotices] = useState({});

  useEffect(() => {
    if (dates.length > 0) {
      fetch(dates[0], dates[6]);

      return;
    }

    setNow(moment().format("YYYY-MM-DD"));

    for (let i = 0; i <= 6; i++) {
      setDates((dateArr) => [
        ...dateArr,
        Datetime.moment(new Date().setDate(new Date().getDate() + i)).format(
          "YYYY-MM-DD"
        ),
      ]);
    }
  }, [dates]);

  const fetch = async (from, to) => {
    try {
      const cd = await api.getChurchDates(from, to);
      setChurchDates(cd.church_dates);

      if (SUser.user) {
        const ns = await api.getNotices();
        setNotices(ns.notices);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleNow = (date) => {
    setNow(moment(date).format("YYYY-MM-DD"));
  };

  const getCds = () => {
    let cds = null;

    Object.keys(churchDates).forEach((date) => {
      if (date === now && churchDates[date].length > 0) {
        cds = churchDates[date];
      }
    });

    return cds;
  };

  const getNs = () => {
    let ns = null;

    Object.keys(notices).forEach((date) => {
      if (date === moment(now).format("MM-DD") && notices[date].length > 0) {
        ns = notices[date];
      }
    });

    return ns;
  };

  const cds = getCds();
  const ns = getNs();

  return (
    <>
      <div className="calendar-mini-area">
        <div className="calendar-mini">
          {dates.map((day, index) => {
            const classes = ["calendar-mini__number"];
            const isSelected =
              moment(day).format("DD.MM") === moment(now).format("DD.MM");

            if (moment(day).format("MM") !== moment().format("MM"))
              classes.push("calendar-mini__number--other-month");
            if (isSelected) classes.push("calendar-mini__number--active");
            if (
              !isSelected &&
              churchDates[day] &&
              churchDates[day].length > 0
            ) {
              let isBig = false;

              churchDates[day].forEach((cd) => {
                if (cd.gage === "BIG") isBig = true;
              });

              if (isBig) {
                classes.push("calendar-mini__number--holiday"); // BIG
              } else {
                classes.push("calendar-mini__number--holiday _holiday-small"); // SMALL
              }
            }

            let calendarDots = "";
            if (notices[moment(day).format("MM-DD")]) {
              let hasH = false;
              let hasR = false;

              notices[moment(day).format("MM-DD")].forEach((notice) => {
                if (notice.date2) {
                  hasR = true;
                } else {
                  hasH = true;
                }
              });

              calendarDots = (
                <div className="calendar-dots-area">
                  <div className="calendar-dots">
                    {hasH && <span className="calendar-dot calendar-dot--h" />}
                    {hasR && <span className="calendar-dot calendar-dot--r" />}
                  </div>
                </div>
              );
            }

            let dateDay = moment(day).format("dddd, MMMM DD YYYY").split(" ");
            let dayOfWeek = dayCalendar(dateDay[0].slice(0, -1));

            const dayClasses = ["calendar-mini__day"];
            if (isSelected) dayClasses.push("calendar-mini__day--active");

            return (
              <div
                key={day}
                className="calendar-mini__col"
                onClick={() => handleNow(moment(day).format("YYYY-MM-DD"))}
              >
                <p className={dayClasses.join(" ")}>
                  {index === 0 ? `СЕГОДНЯ, ${dayOfWeek}` : dayOfWeek}
                </p>

                <div className={classes.join(" ")}>
                  {unzeroDay(dateDay[2])}

                  {!isSelected && calendarDots}
                </div>

                {isSelected && (
                  <p className="calendar-mini__month">
                    {moment(day).format("MMM")}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      
      <div className="container">
        {!isLoading && <CalendarItems cds={cds} ns={ns} hasUser={!!SUser.user} />}
        {isLoading && (
          <div style={{ marginBottom: '24px' }}>
            <SkeletonTheme
              baseColor={colorSkeleton.baseColor}
              highlightColor={colorSkeleton.highlightColor}
            >
              <Skeleton
                count={1}
                containerClassName="skeleton"
                className="skeleton-list"
                style={{ height: "98px" }}
              />
            </SkeletonTheme>
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarMini;
