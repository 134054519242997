import {IMAGES} from "../../constants/images";
import {useToasts} from 'react-toast-notifications';

const Alert = (props) => {
  const {removeToast} = useToasts();

  return (
    <div className="alert" onClick={() => removeToast(props.appearance)}>
      <div
        className={"alert__content " + (props.appearance === "GOOD" ? "" : "alert__content--bad")}>
        <div className="alert__icon">
          {props.appearance === "GOOD" && <img src={IMAGES.Icon22} alt="Сохранено"/>}
          {props.appearance === "BAD" && <img src={IMAGES.Icon23} alt="Ошибка"/>}
        </div>
        <span className="alert__text">
          {props.children}
        </span>
      </div>
    </div>
  );
};

export default Alert;