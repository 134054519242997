import Header from "../components/header/Header";
import BottomMenu from "../components/bottom-menu/BottomMenu";
import React, {useContext, useEffect, useRef} from "react";
import HashController from "../components/hash-controller/HashController";
import {UserContext} from "../context/user";
import ModalEditCategories from "../components/modals/ModalEditCategories";
import {Modal} from "react-bootstrap";
import {CategoriesContext} from "../context/categories";
import ModalDelete from "../components/modals/ModalDelete";
import {ListsContext} from "../context/lists";
import ModalDeleteNotice from "../components/modals/ModalDeleteNotice";
import ModalPay from "../components/modals/ModalPay";
import ModalNote from "../components/modals/modal-note/ModalNote";
import {useLocation} from "react-router-dom";
import CalendarRight from "../components/calendar-right/CalendarRight";
import GetSize from "../hooks/getSizer";
import {HelperContext} from "../context/helper";
import {motion} from "framer-motion";

const MainLayout = ({children}) => {

  const location = useLocation();

  const SUser = useContext(UserContext);

  const SHelper = useContext(HelperContext);

  const SCategories = useContext(CategoriesContext);

  const SLists = useContext(ListsContext);

  const hideModalNote = () => {
    SUser.setShowModalNotice(false);
    SUser.setShowModalNoticeType("");
    SUser.setNames(null);
  };

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];
    if (SUser.isOpenCalendarRight) {
      html.style.overflow = "hidden";
    } else {
      html.style.overflow = "";
    }
  }, [SUser.isOpenCalendarRight]);

  useEffect(() => {
    SCategories.setModalEditCategory(false);
    SCategories.setModalDeleteCategory(false);
    SLists.setModalDeleteNotice(false);
    hideModalNote();

  }, [location.pathname]);

  const bottomMenuRef = useRef();
  const headerArea = useRef();

  const _sizeFooter = GetSize(bottomMenuRef);
  const _sizeHeader = GetSize(headerArea);

  useEffect(() => {
    SHelper.setSizeHeader(_sizeHeader || -10);
    SHelper.setSizeFooter(_sizeFooter || -10);
  });

  const variants = {
    open: {x: "-5%"},
    closed: {x: "0"}
  };

  const isFooterPadding =()=>{
    return _sizeFooter
  }

  const hideModalPay =()=>{
    SUser.setShowModalPay((prevState) => ({
      ...prevState,
      show: false
    }))
    hideModalNote();
  }

  return (

    <>
      <header className="header-area" ref={headerArea}>
        <Header/>
      </header>
      {SUser.user && <CalendarRight/>}


      <motion.div
        animate={SUser.isOpenCalendarRight ? "open" : "closed"}
        variants={variants}>
        {children}
      </motion.div>


      {!SUser.user && <HashController padding={isFooterPadding()}/>}

      <div
        className="bottom-menu-area"
        ref={bottomMenuRef}
      >
        <BottomMenu/>
      </div>


      <Modal
        onHide={() => SCategories.setModalEditCategory(false)}
        show={SCategories.modalEditCategory} className='modal-role'
        style={{paddingBottom: `${isFooterPadding()}px`}}>
        <ModalEditCategories setShow={() => SCategories.setModalEditCategory(false)}/>
      </Modal>

      <Modal
        onHide={() => SCategories.setModalDeleteCategory(false)}
        show={SCategories.modalDeleteCategory}
        className='modal-role'
        style={{paddingBottom: `${isFooterPadding()}px`}}>
        <ModalDelete setShow={() => SCategories.setModalDeleteCategory(false)}/>
      </Modal>

      <Modal
        onHide={() => SLists.setModalDeleteNotice(false)}
        show={SLists.modalDeleteNotice}
        className='modal-role'
        style={{paddingBottom: `${isFooterPadding()}px`}}>
        <ModalDeleteNotice setShow={() => SLists.setModalDeleteNotice(false)}/>
      </Modal>


      <Modal
        onHide={() => hideModalPay()}
        show={SUser.showModalPay.show}
        className='modal-role'
        style={{paddingBottom: `${isFooterPadding()}px`}}>
        <ModalPay
          setShow={()=>hideModalPay()}
        />
      </Modal>

      <Modal
        style={{paddingBottom: `${isFooterPadding()}px`}}
        show={SUser.showModalNotice}
        onHide={hideModalNote}
        className='modal-role'>
        <ModalNote
          setShow={hideModalNote}/>
      </Modal>
    </>

  );
};

export default MainLayout;
