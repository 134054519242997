import {baseUrl} from "../../utils/helpers";
import {IMAGES} from "../../constants/images";
import {ReactComponent as NoticeCalendar} from "../../assets/icons/notice-calendar.svg";
import {Link} from "react-router-dom";

const NoticeItem = ({notice, className, handleClick = null}) => {

  const isRest = () => {
    return !!notice?.date2;
  };

  const classes = ['notice-item'];
  if (className) classes.push(className);
  if (isRest()) classes.push('notice-item--rest');

    return (
      <Link
        to={`/notice/${notice.id}`}
        onClick={() => handleClick ? handleClick(notice) : {}}
        className={classes.join(' ')}
        style={isRest() ? { backgroundImage: `url(${IMAGES.FonGray})` } : { backgroundImage: `url(${IMAGES.FonGreen})` }}
      >
        {notice?.img ? (
          <img
            className="notice-item__img"
            loading="lazy"
            src={`${baseUrl}storage/${notice?.img}`}
            alt={notice.firstname}
          />
        ) : isRest() ? (
          <img
            className="notice-item__img-placeholder"
            src={IMAGES.Pic20}
            alt="Нет фото"
          />
        ) : (
          <img
            className="notice-item__img-placeholder"
            src={IMAGES.Pic19}
            alt="Нет фото"
          />
        )}

        <div className="notice-item__description">
          <div className="notice-item__fio">
            {notice.firstname} {notice?.lastname || ''}
          </div>
          <div className="notice-item__type">
            <NoticeCalendar
              style={isRest() ? {fill: "#B7B9BA"} : {fill: "#A8DBD9"}}
            />
            <span>{notice.date2 ? "День памяти" : "День рождения"}</span>
          </div>
        </div>
      </Link>
    );
};

export default NoticeItem;
