import React, {useContext, useRef, useState} from "react";
import {IMAGES} from "../../constants/images";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {NoticesContext} from "../../context/notices";

const NoticePhoto = ({isRest,handleCropData}) => {

  const stateNotices = useContext(NoticesContext);

  //фото добавлено
  // const [image, setImage] = useState(null);
  //если фото в режиме crop
  const [cropData, setCropData] = useState(null);
  //если фото оберзано
  const [cropper, setCropper] = useState();

  const selectPhoto =async (e) => {
    e.preventDefault();
    let _image;
    if (e.dataTransfer) {
      _image = e.dataTransfer.files;
    } else if (e.target) {
      _image = e.target.files;
    }
    const reader = new FileReader();
    console.log('load true')
    reader.onload = () => {
      handleCropData(true)
      setCropData(null);
      stateNotices.setFile(reader.result);
      console.log('load false')
    };
    reader.readAsDataURL(_image[0]);
  };

  const getCropData = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (typeof cropper !== "undefined") {
      handleCropData(true)
      setCropData(cropper.getCroppedCanvas().toDataURL());
      stateNotices.setFile(cropper.getCroppedCanvas().toDataURL())
    }
  };
  // console.log('stateNotices.setFile', stateNotices.file)

  return (
    <>
      {stateNotices.file
        ?
        //если фото добавлено
        <div className="event__photo">
          {!cropData
            ?
            //если фото в режиме crop
            <>
              <Cropper
                style={{height: "100%", width: "100%"}}
                zoomTo={0}
                // initialAspectRatio={0}
                // preview=".img-preview"
                src={stateNotices.file}
                aspectRatio={2/2}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                // autoCropArea={1}
                // scaleY={1}
                // scaleX={1}
                zoomin={0}
                zoomOnWheel={true}
                zoomOnTouch={true}
                dragMode="move"
                cropBoxMovable={false}
                cropBoxResizable={false}
                toggleDragModeOnDblclick={false}
                // checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                zoom={(e)=> {
                  if (e.detail.ratio > 0.25) {
                    //вырубает zoom в определенном моменте приближения
                    e.preventDefault();
                    // cropper.zoomTo = 1
                  }
                }}
                // guides={false}
              />
              <div className="event__photo-button-area">
                <button type="button"
                        onClick={getCropData}
                        className="button button--t2 event__photo-button">
                  <span>Сохранить</span>
                </button>
              </div>
            </>
            :
            //если фото оберзано
            <>
              <img src={cropData} alt=""/>
              <div className="event__photo-button-area">
                <button
                  onChange={selectPhoto}
                  type="file"
                  className="button button--t2 event__photo-button">
                  <span>Изменить</span>
                  <input type="file"
                         accept="image/*"
                  />
                </button>
              </div>
            </>
          }
        </div>
        :
        //если фото нет
        <button className="button event__stub" type="file" onChange={selectPhoto}>
          <div className={"event__stub-content " + (isRest() ? "event__stub-content--rest" : "")}>
            <img src={isRest() ? IMAGES.Icon35 : IMAGES.Icon12}
                 alt=""
                 className="event__stub-image mr-0"/>
            <p className="event__stub-text">
              Добавить фотографию
            </p>
          </div>
          <input type="file"
                 accept="image/*"
          />
        </button>
      }
    </>
  );
};

export default NoticePhoto;
