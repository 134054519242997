import React, {useState} from "react";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

const OverlayScrollRightCalendar = ({children}) => {

  const [op, setOp] = useState(0);
  const defaultOpacity = 1;

  return (
    <div className="scroll-overlay-area">
      <div
        className="scroll-overlay"
        style={{opacity: op, top: 0}}>
        <span className="scroll-overlay__bg"/>
        <span className="scroll-overlay__gr"/>
      </div>
      <OverlayScrollbarsComponent
        style={{
          maxHeight: "430px",
          // border: "1px solid red",
        }}
        options={
          {
            resize: "none",
            className: " os-theme-dark custom-theme theme-hover",
            scrollbars: {
              visibility: "auto",
              autoHide: "never", //scroll //leave
              autoHideDelay: 50
            },
            callbacks: {
              onScroll: function(e) {
                let _pos = e.target.scrollTop;
                let count = 0;

                if (_pos <= 200) {
                  count = count + (_pos / 90);
                  setOp(count);
                  if (op > defaultOpacity) {
                    setOp(defaultOpacity);
                  }
                }
              },
              onScrollStop: function(e) {
                if (op > defaultOpacity) {
                  setOp(defaultOpacity);
                }
              }
            }
          }
        }
      >

        {children}
      </OverlayScrollbarsComponent>
    </div>

  );
};

export default OverlayScrollRightCalendar;
