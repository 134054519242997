import React, {createContext, useState, useRef} from 'react';
import api from '../api';

export const ChurchDatesStateContext = createContext();

export const ChurchDatesContextProvider = ({children}) => {

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const [loadingCalendarMini, setLoadingCalendarMini] = useState(true);

  //выбраный день в мини
  const [todayCalendarMini, setTodayCalendarMini] = useState('');
  const [churchDates, setChurchDates] = useState({});

  //выбраный день в календаре
  const [todayCalendar, setTodayCalendar] = useState('');
  const [churchDatesMonth, setChurchDatesMonth] = useState({});
  const [fastingsMonth, setFastingsMonth] = useState([]);
  const [isLoadingChurchDatesMonth, setIsLoadingChurchDatesMonth] = useState(true);
  const [isLoadingFastingsMonth, setIsLoadingFastingsMonth] = useState(true);

  const [churchDate, setChurchDate] = useState(
    null
  );

  const [noticeCategories, setNoticeCategories] = useState({});
  const [notices, setNotices] = useState({});

  //запрос праздников для календаря мини
  const getChurchDates = async (from, to) => {
    setLoadingCalendarMini(true);
    await api.getChurchDates(from, to).then(res => {
      setChurchDates(res.church_dates);
    }).catch(e => {
      console.log('e', e.response.data.error);
    }).finally(e => {
      setLoadingCalendarMini(false);
    });
  };

  const getNotices = async () => {
    await api.getNotices().then(res => {
      // console.log('res.notices', res.notices)
      setNotices(res.notices);
    });
  };

  //запрос праздников для большого календаря
  //чтобы не перезагружать мини календарь
  const getChurchDatesMonth = async (from, to) => {
    setIsLoadingChurchDatesMonth(true);
    await api.getChurchDates(from, to).then(res => {
      setChurchDatesMonth(res.church_dates);
    }).catch(e => {
      console.log('e', e.response.data.error);
    }).finally(e => {
      setIsLoadingChurchDatesMonth(false);
    });
  };

  //запрос постов для большого календаря
  //чтобы не перезагружать мини календарь
  const getFastingsMonth = async (from, to) => {
    setIsLoadingFastingsMonth(true);
    await api.getFastings(from, to).then(res => {
      setFastingsMonth(res.fastings);
    }).catch(e => {
      console.log('e', e.response.data.error);
    }).finally(e => {
      setIsLoadingFastingsMonth(false);
    });
  };

  //Проверка типа записки
  const scanNotices = (key, type) => {
    let result = false;

    notices[key]?.forEach(notice => {
      if (notice.d1 === key && type === 'BIRTH') result = true;

      if (notice.d2 === key && type === 'REST') result = true;
    })

    return result
  }

  return (
    <ChurchDatesStateContext.Provider value={{
      index, setIndex,
      loadingCalendarMini, setLoadingCalendarMini,
      churchDates, setChurchDates,
      churchDatesMonth, setChurchDatesMonth,
      fastingsMonth, setFastingsMonth,
      isLoadingChurchDatesMonth, setIsLoadingChurchDatesMonth,
      churchDate, setChurchDate,
      notices, setNotices,
      noticeCategories, setNoticeCategories,
      todayCalendarMini, setTodayCalendarMini,
      todayCalendar, setTodayCalendar,
      getChurchDates,
      getNotices,
      getChurchDatesMonth,
      getFastingsMonth,
      scanNotices,
      loading, setLoading
    }}>
      {children}
    </ChurchDatesStateContext.Provider>
  );
};
