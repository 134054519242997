import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import OverlayScroll from "../components/OverlayScroll";
import {parseLink} from "../utils/helpers";
import {IMAGES} from "../constants/images";

const DraftLayout = ({children}) => {
  const [activeLink, setActiveLink] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setActiveLink(parseLink());
  }, []);

  return (
    <OverlayScroll>
      <section className="container-fluid">
        <div className="container">
          <nav
            className={"navigation " + (open ? "navigation--open" : "")}
            onClick={() => setOpen(!open)}
          >
            <div className="mobile-link-area">
              {activeLink}
              <img
                src={IMAGES.Icon30}
                alt="Открыть"
                className="select-categories-area__icon"
              />
            </div>
            <ul className="navigation__list">
              <li className="navigation__item">
                <NavLink to="/draft-health" className="navigation__link ">
                  О Здравии
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink to="/draft-rest" className="navigation__link ">
                  Об Упокоении
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="container">{children}</div>
      </section>
    </OverlayScroll>
  );
};

export default DraftLayout;
