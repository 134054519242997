import {useState} from "react";
import {IMAGES} from "../../../constants/images";
import api from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import Load from "../../load/Load";
import {useToasts} from "react-toast-notifications";
import GoogleSignin from "../../google-signin/GoogleSignin";
import {HASH} from "../../../constants/hash";

const Signup = ({setShow}) => {
  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const submitSignup = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setErrors({});

      const data = {
        email,
        name,
        password,
        source: "WEB"
      };

      const localContentHealth = localStorage.getItem("__local_content--HEALTH");
      const localContentRest = localStorage.getItem("__local_content--REST");

      if (localContentHealth) {
        data.health = localContentHealth;
      }
      if (localContentRest) {
        data.rest = localContentRest;
      }

      const resp = await api.signup(data);

      addToast(resp.message, {
        id: "GOOD",
        appearance: "GOOD",
        autoDismiss: true
      });

      setEmail("");
      setName("");
      setPassword("");

      navigate("/");
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setErrors({message: error?.response?.data?.message});
      } else {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });
      }

      console.log("error", error);

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={(e) => submitSignup(e)} className="modal-window modal-login">
      <div className="modal-main ">
        <button
          type="button"
          className="button modal-window__button-close"
          onClick={() => setShow(false)}
        >
          <img src={IMAGES.Icon5} alt="" className="m-0"/>
        </button>
        <div className="modal-window__content">
          <div className="modal-window__header">
            <h2 className="modal-window__title">Регистрация</h2>
          </div>
          <div className="modal-window__body">
            <div className="input-container ">
              <input
                type="text"
                className="input"
                placeholder="ФИО"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div
                className={
                  "input-error " + (errors.name ? "input-error--open" : "")
                }
              >
                {errors.name}
              </div>
            </div>
            <div className="input-container  ">
              <input
                type="text"
                className="input"
                placeholder="E-mail"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className={
                  "input-error " + (errors.email ? "input-error--open" : "")
                }
              >
                {errors.email}
              </div>
            </div>
            <div className="input-container  ">
              <input
                type="password"
                className="input"
                placeholder="Пароль"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className={
                  "input-error " + (errors.password ? "input-error--open" : "")
                }
              >
                {errors.password}
              </div>

              <div
                className={
                  "input-error " + (errors.message ? "input-error--open" : "")
                }
              >
                {errors.message}
              </div>
            </div>

            <div className="modal-login__buttons">
              <button
                disabled={!(email && password && name)}
                type="submit"
                className="button button--t2 modal-window__button login-modal-button">
                {!isLoading && <span>Зарегистрироваться</span>}
                {isLoading && <Load/>}
              </button>
            </div>

          </div>
        </div>

        <div className="modal-note__footer modal-window__footer">
          <Link to={HASH.LOGIN}
            className="button  button--t5 modal-login__button">
            <span>Войти на сайт</span>
          </Link>
        </div>
      </div>
    </form>
  );
};

export default Signup;
