import React, {useState, useEffect, useContext, forwardRef} from "react";
import moment from "moment";
import "moment/locale/ru";
import {defaultDescription, getDatesBetweenDates} from "../utils/helpers";
import api from "../api";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {unzeroDay, colorSkeleton} from "../utils/helpers";
import {UserContext} from "../context/user";
import CalendarItems from "../components/calendar-items/CalendarItems";
import OverlayScroll from "../components/OverlayScroll";
import {IMAGES} from "../constants/images";
import DatePicker from "react-datepicker";
import MetaTags from "react-meta-tags";

const CalendarPage = () => {
  const SUser = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [now, setNow] = useState(null); // выбранный день
  const [month, setMonth] = useState(null); // отображаемый месяц
  const [churchDates, setChurchDates] = useState({});
  const [notices, setNotices] = useState({});
  const [selectedChurchDates, setSelectedChurchDates] = useState(null);
  const [selectedNotices, setSelectedNotices] = useState(null);

  const currentMonth = moment(month);
  const lastMonth = moment(month).add(-1, "months");
  const nextMonth = moment(month).add(1, "months");

  useEffect(() => {
    if (!now) {
      const today = moment().format("YYYY-MM-DD");
      setNow(today);
      setMonth(today);

      fetch(today, today);
    } else {
      const newSelectedChurchDates = getCds(now, churchDates);
      setSelectedChurchDates(newSelectedChurchDates);
      const newSelectedNotices = getNs(now, notices);
      setSelectedNotices(newSelectedNotices);
    }
  }, [now]);

  const fetch = async (month, selectedDate) => {
    try {
      const from = moment(month).startOf("month").format("YYYY-MM-DD");
      const to = moment(month).endOf("month").format("YYYY-MM-DD");
      const isBetween = moment(selectedDate).isBetween(from, to, undefined, "[]");
      // console.log('isBetween', isBetween);

      const cd = await api.getChurchDates(from, to);
      setChurchDates(cd.church_dates);
      if (isBetween) {
        const newSelectedChurchDates = getCds(selectedDate, cd.church_dates);
        setSelectedChurchDates(newSelectedChurchDates);
      }

      if (SUser.user) {
        const ns = await api.getNotices();
        setNotices(ns.notices);
        if (isBetween) {
          const newSelectedNotices = getNs(selectedDate, ns.notices);
          setSelectedNotices(newSelectedNotices);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const getCds = (day, items) => {
    let cds = null;

    Object.keys(items).forEach((date) => {
      if (date === day && items[date].length > 0) {
        cds = items[date];
      }
    });

    return cds;
  };

  const getNs = (day, items) => {
    let ns = null;

    Object.keys(items).forEach((date) => {
      if (date === moment(day).format("MM-DD") && items[date].length > 0) {
        ns = items[date];
      }
    });

    return ns;
  };

  const handleNow = (date, fromPicker = false) => {
    if (fromPicker || moment(date).format("MM") !== moment(currentMonth).format("MM")) {
      setMonth(date);
      setIsLoading(true);
      fetch(date, date);
    }

    setNow(date);
  };

  const handleMonth = (date) => {
    setMonth(date);
    fetch(date, now);
  };

  const getCalendar = () => {
    let days;

    const A = moment(month).startOf("month"); //первый день выбранного месяца
    const deltaA = A.day() - 1; //количество дней перед месяцем
    let startDate = moment(A).add(-deltaA, "days"); //первый день для отрисовки месяца

    // если вдруг начало на неделю промахнулось
    if (
      startDate.format("MM") === moment(month).format("MM") &&
      +startDate.format("DD") !== 1
    ) {
      startDate = startDate.add(-7, "days");
    }

    const B = A.endOf("month"); //последний день выбранного месяца
    const deltaB = 7 - B.day();
    const endDate = moment(B).add(deltaB === 7 ? 0 : deltaB, "days");

    days = getDatesBetweenDates(startDate, endDate);

    return days;
  };

  const days = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

  let calendarWeeks = [[]];
  let weekIndex = 1;
  const calendar = getCalendar();

  calendar.forEach((day, index) => {
    calendarWeeks[calendarWeeks.length - 1].push(day);
    weekIndex++;

    if (weekIndex > 7 && index < calendar.length - 1) {
      calendarWeeks.push([]);
      weekIndex = 1;
    }
  });

  const calendarRows = calendarWeeks.map((row, index) => {
    const rowDays = row.map((day) => {
      const classes = ["calendar-mini__number"];

      if (moment(day).format("MM") !== moment(currentMonth).format("MM"))
        classes.push("calendar-mini__number--other-month");
      if (moment(day).format("DD.MM") === moment(now).format("DD.MM"))
        classes.push("calendar-mini__number--now");
      if (churchDates[day] && churchDates[day].length > 0) {
        let isBig = false;

        churchDates[day].forEach((cd) => {
          if (cd.gage === "BIG") isBig = true;
        });

        if (isBig) {
          classes.push("calendar-mini__number--holiday"); // BIG
        } else {
          classes.push("calendar-mini__number--holiday _holiday-small"); // SMALL
        }
      }

      let calendarDots = "";
      if (moment(day).format("MM") === moment(currentMonth).format("MM") && notices[moment(day).format("MM-DD")]) {
        let hasH = false;
        let hasR = false;

        notices[moment(day).format("MM-DD")].forEach((notice) => {
          if (notice.date2) {
            hasR = true;
          } else {
            hasH = true;
          }
        });

        calendarDots = (
          <div className="calendar-dots-area">
            <div className="calendar-dots">
              {hasH && <span className="calendar-dot calendar-dot--h"/>}
              {hasR && <span className="calendar-dot calendar-dot--r"/>}
            </div>
          </div>
        );
      }

      return (
        <div
          className="calendar-mini__col"
          key={day}
          onClick={() => handleNow(moment(day).format("YYYY-MM-DD"))}
        >
          <div className={classes.join(" ")}>
            {unzeroDay(moment(day).format("DD"))}

            {calendarDots}
          </div>
        </div>
      );
    });

    return (
      <div className="calendar__row" key={index}>
        {rowDays}
      </div>
    );
  });

  const MonthPicker = forwardRef(({value, onClick}, ref) => (
    <button className="month-picker" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <OverlayScroll>
      <MetaTags>
        <title>Помянник</title>
        <meta
          name="description"
          content={defaultDescription}
        />
      </MetaTags>

      <section className="container-fluid">
        <div className="container ">
          <div className="block-main block-main--calendar event-view__content">
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image1"
              alt=""
            />
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image2"
              alt=""
            />

            <div className="calendar-right__calendar calendar">
              <div className="calendar__header justify-content-center">
                <button
                  className="button calendar__button-month calendar__button-month--color"
                  onClick={() => handleMonth(lastMonth)}
                >
                  <span>{moment(lastMonth).format("MMMM")}</span>
                </button>

                <button className="button calendar__button-month">
                  <DatePicker
                    customInput={<MonthPicker />}
                    popperPlacement="bottom"
                    locale="ru"
                    dateFormat="MMMM d, yyyy"
                    onChange={(date) =>
                      handleNow(moment(date).format("YYYY-MM-DD"), true)
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  <span>
                    <strong>{moment(currentMonth).format("MMMM")}</strong>{" "}
                    {moment(currentMonth).format("YYYY")}
                  </span>

                  <img
                    className="date-picker-calendar-area__icon"
                    src={IMAGES.Icon37}
                    alt="Дата"
                  />
                </button>

                <button
                  className="button calendar__button-month calendar__button-month--color"
                  onClick={() => handleMonth(nextMonth)}
                >
                  <span>{moment(nextMonth).format("MMMM")}</span>
                </button>
              </div>

              <div className="calendar__body calendar__body--thin">
                <div className="calendar__days">
                  {days.map((day) => (
                    <p key={day} className="calendar-mini__day">
                      {day}
                    </p>
                  ))}
                </div>

                {calendarRows}
              </div>
            </div>

            <div>
              <div className="calendar__choosen-date">
                {unzeroDay(moment(now).format("DD MMMM"))}
              </div>

              {!isLoading && (
                <CalendarItems
                  cds={selectedChurchDates}
                  ns={selectedNotices}
                  hasUser={!!SUser.user}
                />
              )}
              {isLoading && (
                <div style={{marginBottom: "24px"}}>
                  <SkeletonTheme
                    baseColor={colorSkeleton.baseColor}
                    highlightColor={colorSkeleton.highlightColor}
                  >
                    <Skeleton
                      count={1}
                      containerClassName="skeleton"
                      className="skeleton-list"
                      style={{height: "98px"}}
                    />
                  </SkeletonTheme>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </OverlayScroll>
  );
};

export default CalendarPage;
