import React, {useContext, useRef, useState} from "react";
import {baseUrl, colorSkeleton, unzeroDay} from "../../utils/helpers";
import {IMAGES} from "../../constants/images";
import {ReactComponent as Icon28} from "../../assets/icons/icon-28.svg";
import {ReactComponent as Icon29} from "../../assets/icons/icon-29.svg";
import useClickOutside from "../../hooks/useClickOutside";
import {Link} from "react-router-dom";
import {ListsContext} from "../../context/lists";
import moment from "moment";
import "moment/locale/ru";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {AnimatePresence, motion} from "framer-motion";
import DateBlockStub from "../date-block/DateBlockStub";

const ListNotice = ({type, data}) => {
  const SLists = useContext(ListsContext);

  const toogle = useRef();

  useClickOutside(toogle, () => setIsOpen(false));

  const [isOpen, setIsOpen] = useState(false);

  const isRest = () => type === "REST";

  const compareNumbers = (n1, n2) => {
    // console.log('n1', n1.d1)
    // console.log('n2', n2.d1)
    // console.log('----')
    if (isRest()) {
      // console.log('if')
      if (n1.d2 === n2.d2) {
        return 0;
      }
      if (moment("2000-" + n1.d2).isAfter(moment("2000-" + n2.d2))) {
        return 1;
      } else {
        return -1;
      }
    } else {
      // console.log('else')
      if (n1.d1 === n2.d1) {
        return 0;
      }
      if (moment("2000-" + n1.d1).isAfter(moment("2000-" + n2.d1))) {
        return 1;
      } else {
        return -1;
      }
    }
  };

  const handleDeleteNotice = (notice) => {
    SLists.setModalDeleteNotice(true);

    SLists.setDeletingNotice(notice);
  };
  const box = {
    initial: {
      opacity: 0,
      y: -5,
      transition: {duration: 0.2},
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {duration: 0.2},
    },
    exit: {
      display: "none",
    },
  };
  return (
    <>
      {SLists.loadingScreen ? (
        <SkeletonTheme
          baseColor={colorSkeleton.baseColor}
          highlightColor={colorSkeleton.highlightColor}
        >
          <Skeleton
            count={2}
            containerClassName="skeleton "
            className="skeleton-list"
          />
        </SkeletonTheme>
      ) : data?.length > 0 ? (
        data?.sort(compareNumbers)?.map((notice, index) => {
          let lineDate = moment(notice.date1).format("DD MMMM");
          if (notice.date2) {
            lineDate = moment(notice.date2).format("DD MMMM");
          }
          if (index > 0) {
            let lineDateBefore = moment(data[index - 1].date1).format(
              "DD MMMM"
            );
            if (data[index - 1].date2) {
              lineDateBefore = moment(data[index - 1].date2).format("DD MMMM");
            }
            if (lineDateBefore === lineDate) {
              lineDate = null;
            }
          }
          return (
            <div
              key={index}
              className="list-item-area"
              style={{zIndex: data?.length - index}}
            >
              {lineDate && (
                <div
                  key={index}
                  className={
                    "list-item-line " + (isRest() ? "list-item-line__rest" : "")
                  }
                >
                  <span className="list-item-line__date">
                    {unzeroDay(lineDate)}
                  </span>
                </div>
              )}
              <AnimatePresence>
                <motion.div
                  className="list-item"
                  initial="initial"
                  animate={
                    notice.id === SLists.deleteId ? "initial" : "animate"
                  }
                  exit="exit"
                  variants={box}
                >
                  <div
                    className={
                      "list-item__image-area " +
                      (isRest() ? "list-item__image-area--rest" : "")
                    }
                  >
                    {notice?.img ? (
                      <img
                        src={`${baseUrl}storage/${notice?.img}`}
                        alt=""
                      />
                    ) : isRest() ? (
                      <img src={IMAGES.Pic20} alt="Нет фото" />
                    ) : (
                      <img src={IMAGES.Pic19} alt="Нет фото" />
                    )}
                  </div>
                  <div className="list-item__content">
                    <Link
                      to={`/notice/${notice?.id}`}
                      className={
                        "list-item__name " +
                        (isRest() ? "item__name--rest" : "")
                      }
                    >
                      <span className="firstname">{notice?.firstname} </span>
                      {notice.lastname && <span className="lastname">{notice.lastname}</span>}
                    </Link>
                    <div className="list-item__tag">
                      {isRest() ? (
                        <img
                          src={IMAGES.Icon31}
                          alt="тэг"
                          className="event-info__image"
                        />
                      ) : (
                        <img
                          src={IMAGES.Icon15}
                          alt="тэг"
                          className="event-info__image"
                        />
                      )}
                      <p
                        className={
                          "event-info__text " +
                          (type === "REST" ? "event-info__text--rest" : "")
                        }
                      >
                        {notice?.category?.title}
                      </p>
                    </div>
                  </div>
                  <div className="list-item__menu">
                    <div
                      className={
                        "category__dots " +
                        (isRest() ? "category__dots--rest" : "")
                      }
                      style={isOpen === notice.id ? {zIndex: 2} : {zIndex: 1}}
                    >
                      {isOpen === notice.id && (
                        <div className="drop-window" ref={toogle}>
                          <button
                            type="button"
                            tabIndex={1}
                            className="button category__content category__toggle"
                            onClick={() => setIsOpen(false)}
                          >
                            <span className="category__dot" />
                            <span className="category__dot" />
                            <span className="category__dot" />
                          </button>

                          <div className="drop-window__content">
                            <Link
                              to={
                                isRest()
                                  ? `/notice-rest-edit/${notice.id}`
                                  : `/notice-health-edit/${notice.id}`
                              }
                              type="button"
                              className="button drop-window__button"
                            >
                              <div className="drop-window__button-icon-area">
                                <Icon28 className="drop-window__button-icon " />
                              </div>
                              <span>Редактировать</span>
                            </Link>
                            <button
                              onClick={() => handleDeleteNotice(notice)}
                              type="button"
                              className="button drop-window__button"
                            >
                              <div className="drop-window__button-icon-area">
                                <Icon29 className="drop-window__button-icon " />
                              </div>
                              <span>Удалить</span>
                            </button>
                          </div>
                        </div>
                      )}

                      <button
                        type="button"
                        tabIndex={1}
                        className="button category__content category__toggle"
                        onClick={() => setIsOpen(notice.id)}
                      >
                        <span className="category__dot" />
                        <span className="category__dot" />
                        <span className="category__dot" />
                      </button>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          );
        })
      ) : (
        <div className="list-notice-stub">
          <DateBlockStub mini={true} link={isRest() ? '/notice-rest-create' : '/notice-health-create'} />
        </div>
      )}
    </>
  );
};

export default ListNotice;
