import React from "react";
import Notice from "../../components/notice/Notice";

const NoticeRestEditPage = ({layout: Layout}) => {
  return (
    <Layout>
      <Notice type={"REST"} edit={true}/>
    </Layout>
  );
};

export default NoticeRestEditPage;
