import React, {useContext, useEffect} from "react";
import {IMAGES} from "../../constants/images";
import {ReactComponent as Icon29} from "../../assets/icons/icon-29.svg";
import {useToasts} from "react-toast-notifications";
import {CategoriesContext} from "../../context/categories";
import Load from "../load/Load";
import {useLocation} from "react-router-dom";

const ModalDelete = ({setShow}) => {

  const SCategories = useContext(CategoriesContext);

  const {addToast} = useToasts();

  const Submit =(e)=>{
    e.preventDefault()
    SCategories.deleteCategory(addToast)
  }

  return (
    <form onSubmit={Submit} className="modal-window modal-delete">
      {/*<button*/}
      {/*  onClick={() => setShow(false)}*/}
      {/*  type="button"*/}
      {/*  className="button modal-window__button-close">*/}
      {/*  <img src={IMAGES.Icon5} alt="" className="m-0"/>*/}
      {/*</button>*/}
      <div className="modal-window__content">
        <div className="modal-window__header">
          <Icon29 className="modal-window__header-icon"/>
          Вы хотите удалить категорию?
        </div>
        <div className="modal-window__body">
          <button
            type="submit"
            className="button button--t4 mb-20">
            {SCategories.loadingEditCategory
              ?
              <Load/>
              :
              <span>Да, удалить</span>
            }

          </button>
          <button
            type="button"
            onClick={()=>setShow(false)}
            className="button button--t5">

            <span>Отмена</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default ModalDelete;
