import React, {useContext, useEffect, useState, useRef} from "react";
import {CategoriesContext} from "../../context/categories";
import {useToasts} from "react-toast-notifications";
import Category from "../category/Category";
import Load from "../load/Load";
import {NoticesContext} from "../../context/notices";
import {IMAGES} from "../../constants/images";
import useClickOutside from "../../hooks/useClickOutside";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import {motion} from "framer-motion";

const SelectCategory = () => {

  const [isOpen, setIsOpen] = useState(false);

  const SCategories = useContext(CategoriesContext);
  const SNotices = useContext(NoticesContext);
  const {addToast} = useToasts();

  useEffect(() => {
    if (SCategories.loadingScreen) {
      SCategories.initCategory();
    }
  }, [SCategories.loadingScreen]);

  const select_categories_drop = useRef();

  useClickOutside(select_categories_drop, () => setIsOpen(false));

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await SCategories.createCategory(addToast);
  };

  const selectCategoryMenu = (item) => {
    SNotices.setNoticeCategory(item);
    setIsOpen(false);
  };

  const categoryList = SCategories.categories.map(category => {
    return (
      <button
        key={category.id}
        type="button"
        onClick={() => selectCategoryMenu(category)}
        className="button select-categories-drop__item">
        <span>
          {category.title}
        </span>
      </button>
    );
  });

  const toggleSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const subMenuAnimate = {
    open: {
      opacity: 1,
      pointerEvents: 'all',
      // rotateX: 0,
      transition: {
        duration: 0.1
      },
      // display: "block"
    },
    closed: {
      opacity: 0,
      pointerEvents: 'none',
      // rotateX: -15,
      transition: {
        duration: 0.1,
        delay: 0.1
      },
      transitionEnd: {
        // display: "none"
      }
    }
  };

  return (
    <div className="input-container ">
      <div className="select-categories-area"
           ref={select_categories_drop}>
        <div

          className="select-categories-area__input"
          onClick={toggleSelect}>
          <input
            placeholder="Выбор категории"
            readOnly
            value={SNotices.noticeCategory?.title || ""}
            type="text"
            className="input"
          />
          <img src={IMAGES.Icon30} alt="Открыть"
               className={"select-categories-area__icon " + (isOpen ? "select-categories-area__icon--open" : "")}/>
        </div>

        <motion.div
          variants={subMenuAnimate}
          animate={isOpen ? "open" : "closed"}
          // className={"select-categories-drop " + (isOpen ? "d-block" : "d-none")}>
          className="select-categories-drop">
          <div className="select-categories-drop__content">
            {/*<div className="select-categories-drop__scroll">*/}
            <OverlayScrollbarsComponent
              style={{maxHeight: "120px"}}
              options={{
                scrollbars: {
                  visibility: "auto",
                  autoHide: "none", //scroll //leave
                  autoHideDelay: 50
                },
                className: " os-theme-dark custom-theme theme-hover"
              }}
              // extensions={['extensionA', 'extensionB']}
            >
              {categoryList}
            </OverlayScrollbarsComponent>
            {/*</div>*/}

            <div
              className="input-container form-create-category">
              <input
                value={SCategories.title || ""}
                type="text"
                className="input "
                placeholder="Название категории"
                onChange={e => SCategories.setTitle(e.target.value)}
              />
              <p className="input-container__text">
                Введите имя категории, которую хотите добавить
              </p>
              <div className="event__buttons">
                <button
                  onClick={submit}
                  className="button button--t2 event__button-create"
                  type="button"
                  disabled={SCategories.title.length <= 3 || SCategories.loadingAddCategories}>
                  {SCategories.loadingAddCategories
                    ?
                    <Load/>
                    :
                    <span>Добавить категорию</span>
                  }
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SelectCategory;
