import React, {useContext, useEffect, useState} from "react";
import {IMAGES} from "../constants/images";
import {useParams} from "react-router-dom";
import api from "../api";
import {baseUrl, colorSkeleton} from "../utils/helpers";
import {ChurchDatesStateContext} from "../context/church_dates";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import moment from "moment";
import "moment/locale/ru";
import {UserContext} from "../context/user";
import OverlayScroll from "../components/OverlayScroll";
import {unzeroDay} from "../utils/helpers";
import MetaTags from "react-meta-tags";

const ChurchDatePage = () => {
  const [loading, setLoading] = useState(true);

  const {id} = useParams();

  const SChurchDate = useContext(ChurchDatesStateContext);
  const SUser = useContext(UserContext);

  useEffect(() => {
    SUser.setNames(null);

    api
      .getChurchDate(id)
      .then((resp) => {
        SChurchDate.setChurchDate(resp.church_date);
      })
      .catch((e) => {
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const showModalPay = (type) => {
    SUser.setTypeNoticePay(SChurchDate.churchDate);
    SUser.setShowModalNotice(true);
    SUser.setShowModalNoticeType(type);
  };

  if (loading)
    return (
      <main>
        <section className="container-fluid">
          <div className="container">
            <div className="church-date-area">
              <div className="church-date">
                <div className="church-date-skeleton">
                  <SkeletonTheme
                    baseColor={colorSkeleton.baseColor}
                    highlightColor={colorSkeleton.highlightColor}
                  >
                    <Skeleton
                      count={1}
                      containerClassName="skeleton "
                      className="skeleton-church-title"
                    />
                    <Skeleton
                      count={1}
                      containerClassName="skeleton "
                      className="skeleton-church-date"
                    />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );

  const cdTitle = `${
    !SChurchDate?.churchDate?.full_title
      ? SChurchDate?.churchDate?.pre + " " + SChurchDate?.churchDate?.title
      : SChurchDate?.churchDate?.full_title
  }`;

  return (
    <main>
      <MetaTags>
        <title>{`${unzeroDay(
          moment(SChurchDate?.churchDate?.date).format("DD MMMM")
        )} - ${cdTitle} - Помянник`}</title>
        <meta
          name="description"
          content={`${unzeroDay(
            moment(SChurchDate?.churchDate?.date).format("DD MMMM")
          )} православный праздник: ${cdTitle}. Оставьте церковные записки за себя и родных.`}
        />
      </MetaTags>
      <OverlayScroll>
        <section className="container-fluid">
          <div className="container">
            <div className="church-date-area">
              <img
                src={IMAGES.Pic16}
                className="church-date-area__image"
                alt=""
              />

              <div className="church-date">
                <div className="title-block church-date__title-block">
                  <h1 className="title p-0">
                    {!SChurchDate?.churchDate?.full_title && (
                      <>
                        {SChurchDate?.churchDate?.pre}
                        <strong>{SChurchDate?.churchDate?.title}</strong>
                      </>
                    )}
                    {!!SChurchDate?.churchDate?.full_title &&
                      SChurchDate?.churchDate?.full_title}
                  </h1>
                </div>

                <div className="church-date__date-block">
                  <p className="church-date__date">
                    {unzeroDay(
                      moment(SChurchDate?.churchDate?.date).format("DD MMMM")
                    )}
                  </p>
                </div>

                <div className="church-date__image-block">
                  <img
                    src={`${baseUrl}storage/${SChurchDate?.churchDate?.img3}`}
                    loading="lazy"
                    alt="Праздник"
                  />
                </div>

                <div className="church-date__buttons flex-md-row flex-column">
                  {(SChurchDate?.churchDate?.note_type === "HEALTH" ||
                    SChurchDate?.churchDate?.note_type === "ANY") && (
                    <button
                      className="button button--t2"
                      type="button"
                      onClick={() => showModalPay("HEALTH")}
                    >
                      <span>Записка о Здравии</span>
                    </button>
                  )}

                  {(SChurchDate?.churchDate?.note_type === "REST" ||
                    SChurchDate?.churchDate?.note_type === "ANY") && (
                    <button
                      className="button button--t3"
                      type="button"
                      onClick={() => showModalPay("REST")}
                    >
                      <span>Записка об Упокоении</span>
                    </button>
                  )}
                </div>

                {SChurchDate?.churchDate?.html && (
                  <p
                    className="church-date__text"
                    dangerouslySetInnerHTML={{
                      __html: SChurchDate?.churchDate?.html,
                    }}
                  ></p>
                )}

                {!SChurchDate?.churchDate?.html && (
                  <p className="church-date__text">Описание отсутствует</p>
                )}
              </div>
            </div>
          </div>
        </section>
      </OverlayScroll>
    </main>
  );
};

export default ChurchDatePage;
