import React from "react";
import "./date-block.sass";
import {IMAGES} from "../../constants/images";
import {Link} from "react-router-dom";

const DateBlockStub = ({mini = false, handleClick = null, link='/notice-health-create'}) => {
  return (
    <div className="date-block-stub">
      <div
        className={`date-block-stub__content${
          mini ? " date-block-stub--mini" : ""
        }`}
      >
        <div className="date-block-stub__info mb-15">
          <img src={IMAGES.Pic7} alt="" className="date-block-stub__image" />
          <p className="date-block-stub__text">Памятных дат нет</p>
        </div>

        <Link
          to={link}
          onClick={() => handleClick ? handleClick() : {}}
          className={`button button--t2 date-block-stub__button ${
            mini ? "mb-15" : "mb-15"
          }`}
        >
          Добавить дату
        </Link>
      </div>
    </div>
  );
};

export default DateBlockStub;
