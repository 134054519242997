import {Link, useLocation} from "react-router-dom";
import {useContext} from "react";
import "./header.sass";
import {IMAGES} from "../../constants/images";
import {HASH} from "../../constants/hash";
import {UserContext} from "../../context/user";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import {ReactComponent as Icon2} from "../../assets/icons/icon-2.svg";

const Header = () => {
  const SUser = useContext(UserContext);

  const handleOpenCalendar = () => {
    SUser.setIsOpenCalendarRight(true);
  };

  const location = useLocation()

  return (

    <div className="header" id="header">
      <div className=" header__content">
        <div className="col-lg-3 col-6">
          <Link to="/" className="header__logo-area">
            <img src={IMAGES.Pic11} alt=""/>
          </Link>
        </div>
        <div className="col-3 d-lg-block d-none">
          <Breadcrumbs/>
        </div>


        {!SUser.user && (
          <div className="col-lg-3 col-6 justify-content-end d-flex">
            <Link
              to={HASH.LOGIN}
              className="button button--t1 button-registration"
            >
              {/*<img src={IMAGES.Icon4} alt=""/>*/}
              <span>Вход и Регистрация</span>
            </Link>
          </div>
        )}

        {SUser.user && location.pathname === '/' &&  (
          <div className="col-lg-3 col-6 justify-content-end d-flex">
            <div
              role="button"
              tabIndex={0}
              onClick={handleOpenCalendar}
              className="calendar-right-toggle"
            >
            <span className="calendar-right-toggle__text">
              Календарь на месяц
            </span>
              <Icon2 className="calendar-right-toggle__icon" fill="#46ADA0"/>
            </div>
          </div>
        )}
        {SUser.user && location.pathname !== '/' && (
        <div className="col-lg-3 col-6 justify-content-end d-flex">
          <Link to="/" className="d-lg-none d-flex">
            <img src={IMAGES.Icon39} alt="На главную"/>
          </Link>
          <Link to="/"  className="button button--t1 button-back d-lg-flex d-none">
            <img src={IMAGES.Icon38} alt="На главную"/>
            <span>На главную</span>
          </Link>
        </div>
        )}
      </div>
    </div>
  );
};

export default Header;
