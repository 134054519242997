import {useState, useEffect, useContext} from "react";
import {IMAGES} from "../../constants/images";
import api from "../../api";
import {UserContext} from "../../context/user";
import Load from "../../components/load/Load";
import {defaultDescription} from "../../utils/helpers";
import MetaTags from "react-meta-tags";

const NotificationsPage = ({layout: Layout}) => {
  const SUser = useContext(UserContext);
  const [subscription, setSubscription] = useState({});
  const [push, setPush] = useState({});

  const init = async () => {
    try {
      let res = await api.getSubscription();
      let resPush = await api.getNotification();

      setSubscription(res.subscription);
      setPush(resPush.push_notification);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const toggleSubscription = async () => {
    let fallBackStatus = 0;

    try {
      if (subscription.id === undefined) {
        setSubscription({status: 1});
        const res = await api.createSubscription();

        setSubscription(res.subscription);
      } else {
        let status = 0;
        fallBackStatus = subscription.status;

        if (subscription.status === 0) status = 1;

        setSubscription({...subscription, status});

        await api.editSubscription(subscription.id, {status});
      }
    } catch (e) {
      console.log("e", e);
      setSubscription({...subscription, status: fallBackStatus});
    }
  };

  const toggleSubscriptionPush = async () => {
    const data = {
      status: push.status === 1 ? 0 : 1,
    };

    await api
      .pushNotification(data)
      .then(async () => {
        setPush((prev) => {
          return {...prev, status: data.status};
        });

        // if (data.status) {
        //   analytics().logEvent('notifications_enable', {
        //     kind: 'push',
        //     screen_name: SCREEN_NAME,
        //   });
        //   AppMetrica.reportEvent('notifications_enable', {
        //     kind: 'push',
        //     screen_name: SCREEN_NAME,
        //   });
        // } else {
        //   analytics().logEvent('notifications_disable', {
        //     kind: 'push',
        //     screen_name: SCREEN_NAME,
        //   });
        //   AppMetrica.reportEvent('notifications_disable', {
        //     kind: 'push',
        //     screen_name: SCREEN_NAME,
        //   });
        // }
      })
      .catch((e) => {
        console.log("toggleSubscriptionPush", e);
      });
  };

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area view-block-size">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <div className="draft-area__content notifications-content">
                <div className="history-scroll">
                  <div className="notifications">
                    <div className="notification">
                      <div className="notification__content">
                        <div className="notification__icon">
                          <img src={IMAGES.Icon20} alt="Mail" />
                        </div>
                        <p className="notification__text notification__mail">
                          {SUser.user.email}
                        </p>

                        {!subscription.id && (
                          <div
                            style={{
                              margin: 0,
                              marginLeft: "auto",
                              position: "relative",
                            }}
                          >
                            <Load position="relative" />
                          </div>
                        )}

                        {subscription.id && (
                          <button
                            type="button"
                            className="button switch"
                            onClick={() => toggleSubscription()}
                          >
                            <span
                              className={`switch__circle${
                                subscription.status === 1
                                  ? " switch__circle--active"
                                  : ""
                              }`}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="notification">
                      <div className="notification__content">
                        <div className="notification__icon">
                          <img src={IMAGES.Icon21} alt="Mail" />
                        </div>
                        <p className="notification__text ">PUSH-уведомления</p>

                        {!subscription.id && (
                          <div
                            style={{
                              margin: 0,
                              marginLeft: "auto",
                            }}
                          >
                            <Load position="relative" />
                          </div>
                        )}

                        {subscription.id && (
                          <button
                            type="button"
                            className="button switch"
                            onClick={() => toggleSubscriptionPush()}
                          >
                            <span
                              className={`switch__circle${
                                push.status === 1
                                  ? " switch__circle--active"
                                  : ""
                              }`}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotificationsPage;
