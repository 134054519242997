import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAo4gqlUv4Fu95smmOzgtB5-5t3zrZNjgM",
  authDomain: "pomyannik-14fb2.firebaseapp.com",
  projectId: "pomyannik-14fb2",
  storageBucket: "pomyannik-14fb2.appspot.com",
  messagingSenderId: "708888549192",
  appId: "1:708888549192:web:86290896259870784e057d",
  measurementId: "G-6KYS98TTY2",
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
