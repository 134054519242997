import React, {useState, useEffect, useContext} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {HASH} from "../../constants/hash";
import {Modal} from "react-bootstrap";
import Login from "../modals/login/Login";
import Signup from "../modals/login/Signup";
import Reset from "../modals/login/Reset";

const HashController = ({padding}) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showReset, setShowReset] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    switch (location.hash) {
      case HASH.SIGNUP:
        setShowReset(false);
        setShowLogin(false);
        setShowSignup(true);

        break;
      case HASH.LOGIN:
        setShowReset(false);
        setShowSignup(false);
        setShowLogin(true);

        break;

      case HASH.RESET:
        setShowSignup(false);
        setShowLogin(false);
        setShowReset(true);

        break;

      default:
        setShowReset(false);
        setShowSignup(false);
        setShowLogin(false);
    }
  }, [location.hash]);

  const goBack = () => {
    navigate(location.pathname);
  };

  return (
    <>
      <Modal show={showSignup} onHide={goBack} className='modal-role' style={{paddingBottom: `${padding}px`}}>
        <Signup setShow={goBack}/>
      </Modal>

      <Modal show={showLogin} onHide={goBack} className='modal-role' style={{paddingBottom: `${padding}px`}}>
        <Login setShow={goBack}/>
      </Modal>

      <Modal show={showReset} onHide={goBack} className='modal-role' style={{paddingBottom: `${padding}px`}}>
        <Reset setShow={() => navigate(HASH.LOGIN)}/>
      </Modal>

    </>
  );
};

export default HashController;
