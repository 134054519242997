import React, {useContext, useEffect, useRef} from "react";
import "./bottom-menu.sass";
import {Link, NavLink, useLocation} from "react-router-dom";
import {ReactComponent as Icon1} from "../../assets/icons/icon-1.svg";
import {ReactComponent as Icon2} from "../../assets/icons/icon-2.svg";
import {ReactComponent as Icon3} from "../../assets/icons/icon-3.svg";
import {ReactComponent as Icon25} from "../../assets/icons/icon-25.svg";
import {ReactComponent as Icon26} from "../../assets/icons/icon-26.svg";
import {ReactComponent as Icon27} from "../../assets/icons/icon-27.svg";
import {ReactComponent as Icon32} from "../../assets/icons/icon-32.svg";
import {ReactComponent as Icon33} from "../../assets/icons/icon-33.svg";
import {ReactComponent as Icon34} from "../../assets/icons/icon-34.svg";
import {HASH} from "../../constants/hash";
import {UserContext} from "../../context/user";
import GetSizeFooter from "../../hooks/getSizer";

const BottomMenu = () => {
  const SUser = useContext(UserContext);
  const location = useLocation();

  const profileLinks = ["/history", "/categories", "/data", "/notifications", "/support"];
  const createNoticeLinks = ["/notice-health-create", "/notice-rest-create"];
  const draftLinks = ["/draft-health", "/draft-rest"];
  const noticesLinks = ["/birthdays", "/restdays"];

  const isActiveWithSublinks = (links) => {
    return links.indexOf(location?.pathname) >= 0;
  };

  useEffect(() => {
    // SUser.setModalPay();
  }, [location.pathname]);

  return (
    <>
      <div
        className={"bottom-menu " + (draftLinks.indexOf(location.pathname) !== -1 ? "bottom-menu--not-gradient" : "")}>
        <div className="bottom-menu__content">

          {SUser.user &&
          <NavLink
            to="/birthdays"
            className={isActiveWithSublinks(noticesLinks) ? "bottom-menu__link bottom-menu__link--active" : "bottom-menu__link"}
          >
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon25 className="bottom-menu__icon icon1" fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
                Списки
              </span>
            </div>
          </NavLink>
          }

          <NavLink
            className={isActiveWithSublinks(draftLinks) ? "bottom-menu__link bottom-menu__link--active" : "bottom-menu__link"}
            to="/draft-health">
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon1 className="bottom-menu__icon icon1" fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
              Помянник
            </span>
            </div>
          </NavLink>

          {SUser.user &&
          <NavLink
            to="/notice-health-create"
            className={isActiveWithSublinks(createNoticeLinks) ? "bottom-menu__link bottom-menu__link--active" : "bottom-menu__link"}
          >
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon26 className="bottom-menu__icon icon1" fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
                Добавить событие
              </span>
            </div>
          </NavLink>
          }


          <NavLink
            to="/calendar"
            className={({isActive}) => isActive ? "bottom-menu__link bottom-menu__link--active" : "bottom-menu__link"}
          >
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon2 className="bottom-menu__icon icon2" fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
              Календарь
            </span>
            </div>
          </NavLink>

          {SUser.user &&
          <NavLink
            to="/history"
            className={isActiveWithSublinks(profileLinks) ? "bottom-menu__link bottom-menu__link--active" : "bottom-menu__link"}
          >
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon27 className="bottom-menu__icon icon2" fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
                Профиль
              </span>
            </div>
          </NavLink>
          }

          {!SUser.user &&
          <NavLink to={HASH.LOGIN} className="bottom-menu__link bottom-menu-enter">
            <div className="bottom-menu__link-block">
              <div className="bottom-menu__icon-area">
                <Icon3 className="bottom-menu__icon icon3 " fill="#ADDDDB"/>
              </div>
              <span className="bottom-menu__text">
               Вход и Регистрация
              </span>
            </div>

          </NavLink>
          }

        </div>
      </div>
      <div className="bottom-menu-info">
        <div className="bottom-menu-info__content">
          <span className="bottom-menu-info__text">
            © 2022 Помянник - проект православной социальной сети «Елицы». Все права защищены.
            {" "}<Link to="/privacy">
              Политика конфиденциальности
            </Link>
          </span>
          <div className="bottom-menu-info__soc">
            <Link to="/" className="bottom-menu-info__soc-item">
              <Icon32/>
            </Link>
            <Link to="/" className="bottom-menu-info__soc-item">
              <Icon33/>
            </Link>
            <Link to="/" className="bottom-menu-info__soc-item">
              <Icon34/>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomMenu;
