import MainPage from "./pages/MainPage";
import NotFound from "./pages/404";
import ChurchDatePage from "./pages/ChurchDatePage";
import NoticeHealthCreatePage from "./pages/NoticeCreate/NoticeHealthCreatePage";
import NoticeViewPage from "./pages/NoticeViewPage";
import SupportPage from "./pages/Profile/SupportPage";
import NotificationsPage from "./pages/Profile/NotificationsPage";
import DataPasswordPage from "./pages/Profile/DataPasswordPage";
import HistoryNotesPage from "./pages/Profile/HistoryNotesPage";
import CategoriesPage from "./pages/Profile/CategoriesPage";
import BirthdaysPage from "./pages/Lists/BirthdaysPage";
import MemorableDatesPage from "./pages/Lists/MemorableDatesPage";
import NoticeRestCreatePage from "./pages/NoticeCreate/NoticeRestCreatePage";
import NoticeHealthEditPage from "./pages/NoticeEdit/NoticeHealthEditPage";
import ProfileLayout from "./layouts/ProfileLayout";
import NoticeCreateLayout from "./layouts/NoticeCreateLayout";
import ListLayout from "./layouts/ListLayout";
import DraftLayout from "./layouts/DraftLayout";
import HealthPage from "./pages/Draft/HealthPage";
import RestPage from "./pages/Draft/RestPage";
import CalendarPage from "./pages/CalendarPage";
import NoticeEditLayout from "./layouts/NoticeEditLayout";
import NoticeRestEditPage from "./pages/NoticeEdit/NoticeRestEditPage";
import PaymentSuccessPage from "./pages/Payment/PaymentSuccessPage";
import PaymentErrorPage from "./pages/Payment/PaymentErrorPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

export const routes = {
  any: [
    {
      title: "Главная страница",
      dontShowTitle: true,
      path: "/",
      element: <MainPage />,
    },
    // {title: "404", path: "/", element: <NotFound />},
    {
      title: "Праздник",
      path: "/church-date/:id",
      include: "/church-date/",
      element: <ChurchDatePage />,
    },
    {title: "Календарь", path: "/calendar", element: <CalendarPage />},
    {
      title: "Помянник",
      path: "/draft-health",
      element: <HealthPage layout={DraftLayout} />,
    },
    {
      title: "Помянник",
      path: "/draft-rest",
      element: <RestPage layout={DraftLayout} />,
    },
    {title: "Платеж принят", path: "/payment-success", element: <PaymentSuccessPage />},
    {title: "Платеж отклонен", path: "/payment-error", element: <PaymentErrorPage />},
    {
      title: "",
      path: "/privacy",
      element: <PrivacyPolicyPage/>,
    },
  ],

  protected: [
    {
      title: "Создание события",
      path: "/notice-health-create",
      element: <NoticeHealthCreatePage layout={NoticeCreateLayout} />,
    },
    {
      title: "Создание события",
      path: "/notice-rest-create",
      element: <NoticeRestCreatePage layout={NoticeCreateLayout} />,
    },
    {
      title: "Просмотр события",
      path: "/notice/:id",
      include: "/notice/",
      element: <NoticeViewPage />,
    },
    {
      title: "Редактирование события",
      path: "/notice-health-edit/:id",
      include: "/notice-health-edit/",
      element: <NoticeHealthEditPage layout={NoticeEditLayout} />,
    },
    {
      title: "Редактирование события",
      path: "/notice-rest-edit/:id",
      include: "/notice-rest-edit/",
      element: <NoticeRestEditPage layout={NoticeEditLayout} />,
    },
    {
      title: "История записок",
      path: "/history",
      element: <HistoryNotesPage layout={ProfileLayout} />,
    },
    {
      title: "Данные пользователя",
      path: "/data",
      element: <DataPasswordPage layout={ProfileLayout} />,
    },
    {
      title: "Уведомления",
      path: "/notifications",
      element: <NotificationsPage layout={ProfileLayout} />,
    },
    {
      title: "Техподдержка",
      path: "/support",
      element: <SupportPage layout={ProfileLayout} />,
    },
    {
      title: "Категории",
      path: "/categories",
      element: <CategoriesPage layout={ProfileLayout} />,
    },

    {
      title: "Мои события",
      path: "/birthdays",
      element: <BirthdaysPage layout={ListLayout} />,
    },
    {
      title: "Мои события",
      path: "/restdays",
      element: <MemorableDatesPage layout={ListLayout} />,
    }
  ],
};
