import {routes} from "../../routeList";
import {useLocation} from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();

  let title = "";

  const allRoutes = routes.any.concat(routes.protected);

  allRoutes.forEach((route) => {
    if (!route.dontShowTitle) {
      if (route.include && location.pathname.includes(route.include)) {
        title = route.title;
        return;
      }
      if (!route.include && location.pathname.includes(route.path)) {
        title = route.title;
        return;
      }
    }
  });

  return <h1 className="header__page-info">{title}</h1>;
};

export default Breadcrumbs;
