import React, {useContext, useEffect} from "react";
import {ListsContext} from "../../context/lists";
import ListNotice from "../../components/list-item/ListNotice";
import "react-loading-skeleton/dist/skeleton.css";
import {IMAGES} from "../../constants/images";
import MetaTags from "react-meta-tags";
import {defaultDescription} from "../../utils/helpers";

const BirthdaysPage = ({layout: Layout}) => {
  const SLists = useContext(ListsContext);

  useEffect(() => {
    SLists.initGetNoticesNoDate();
  }, []);

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <div className="container">
        <div className="event-view-area draft-area min-h">
          <div className="event-view history-area">
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image1"
              alt=""
            />
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image2"
              alt=""
            />
            <div className="draft-area__content history-content lists-content">
              <div className="history-scroll">
                <ListNotice
                  type="HEALTH"
                  data={SLists?.data?.filter((item) => item.date2 === null)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BirthdaysPage;
