import {createContext, useState} from "react";

export const UserContext = createContext();

export const UserContextProvider = ({children}) => {
  const [user, setUser] = useState(null);
  // const [modalPay, setModalPay] = useState(false);

  //данные для модплки при оплате
  const [typeNoticePay, setTypeNoticePay] = useState({})
  //какой тип модалки открываем
  const [showModalPay, setShowModalPay] = useState({
    type: null, //HEALTH, REST
    typeNotice: null, //church_date, notice, draft
    show: false
  });

  //Модалка праздника с вводом имен
  const [showModalNotice, setShowModalNotice] = useState(false)
  const [showModalNoticeType, setShowModalNoticeType] = useState('')

  const [showModalAccess, setModalAccess] = useState(false);
  //имена в списке для подачи записки
  const [names, setNames] = useState(null);

  const [isOpenCalendarRight, setIsOpenCalendarRight] = useState(false)

  const resetModalPay =()=>{
    // setModalPay(false)
    setNames(null)
    setShowModalPay({
      type: null, //HEALTH, REST
      typeNotice: null, //church_date, notice
      show: false
    })
    setTypeNoticePay({})
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        // modalPay, setModalPay,
        typeNoticePay, setTypeNoticePay,
        showModalPay, setShowModalPay,
        showModalAccess, setModalAccess,
        names, setNames,
        resetModalPay,
        isOpenCalendarRight, setIsOpenCalendarRight,
        showModalNotice, setShowModalNotice,
        showModalNoticeType, setShowModalNoticeType
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
