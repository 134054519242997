import React, {useContext, useEffect} from "react";
import {NoticesContext} from "../context/notices";
import {useParams} from "react-router-dom";
import api from "../api";
import Load from "../components/load/Load";
import OverlayScroll from "../components/OverlayScroll";

const NoticeEditLayout = ({children}) => {
  const SNotices = useContext(NoticesContext);

  const {id} = useParams();

  useEffect(() => {
    SNotices.setLoadingScreen(true);
    api.getNotice(id).then(resp => {
      SNotices.editNotice(resp.notice);
      SNotices.setLoadingScreen(false);
      // console.log(resp);
    }).catch(e => {
      // setLoading(true)
    }).finally(() => {
      // setLoading(false)
    });

    return () => {
      SNotices.ResetStateNotices();
    };
  }, []);
  return (
    <>
      {SNotices.loadingScreen
        ?
        <section className="container-fluid">
          <div className="container">
            <div className="event-area view-block-size">
              <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                <Load/>
              </div>
            </div>
          </div>
        </section>
        :
        <OverlayScroll>
          {children}
        </OverlayScroll>
      }
    </>
  );
};

export default NoticeEditLayout;
