import {useState, useEffect, useRef, useContext} from "react";
import {ReactComponent as Icon5} from "../../assets/icons/icon-5.svg";
import useClickOutside from "../../hooks/useClickOutside";
import moment from "moment";
import "moment/locale/ru";
import {getDatesBetweenDates} from "../../utils/helpers";
import api from "../../api";
import ChurchDate from "../church-date/ChurchDate";
import {unzeroDay, colorSkeleton} from "../../utils/helpers";
import DateBlockStub from "../date-block/DateBlockStub";
import {IMAGES} from "../../constants/images";
import NoticeItems from "../notice/NoticeItems";
import {UserContext} from "../../context/user";
import OverlayScrollRightCalendar from "./OverlayScrollRightCalendar";
import {AnimatePresence, motion} from "framer-motion";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const CalendarRight = () => {
  const [now, setNow] = useState(null); // выбранный день
  const [churchDates, setChurchDates] = useState({});
  const [notices, setNotices] = useState({});
  const SUser = useContext(UserContext);
  const currentMonth = moment(now);
  const [isLoading, setIsLoading] = useState(true);

  const toogle = useRef();

  useClickOutside(toogle, () => SUser.setIsOpenCalendarRight(false));

  useEffect(() => {
    if (SUser.isOpenCalendarRight) {
      setNow(moment().format("YYYY-MM-DD"));
      fetch(moment().format("YYYY-MM-DD"));
    }
  }, [SUser.isOpenCalendarRight]);

  const getCds = () => {
    let cds = null;

    Object.keys(churchDates).forEach((date) => {
      if (date === now && churchDates[date].length > 0) {
        cds = churchDates[date];
      }
    });

    return cds;
  };

  const getNs = () => {
    let ns = null;

    Object.keys(notices).forEach((date) => {
      if (date === moment(now).format("MM-DD") && notices[date].length > 0) {
        ns = notices[date];
      }
    });

    return ns;
  };

  const fetch = async (month) => {
    try {
      const from = moment(month).startOf("month").format("YYYY-MM-DD");
      const to = moment(month).endOf("month").format("YYYY-MM-DD");

      const cd = await api.getChurchDates(from, to);
      setChurchDates(cd.church_dates);

      const ns = await api.getNotices();
      setNotices(ns.notices);

      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeCalendar = (_) => {
    SUser.setIsOpenCalendarRight(false);
  };

  const getCalendar = () => {
    let days;

    const A = moment(now).startOf("month"); //первый день выбранного месяца
    const deltaA = A.day() - 1; //количество дней перед месяцем
    let startDate = moment(A).add(-deltaA, "days"); //первый день для отрисовки месяца

    // если вдруг начало на неделю промахнулось
    if (
      startDate.format("MM") === moment(now).format("MM") &&
      +startDate.format("DD") !== 1
    ) {
      startDate = startDate.add(-7, "days");
    }

    const B = A.endOf("month"); //последний день выбранного месяца
    const deltaB = 7 - B.day();
    const endDate = moment(B).add(deltaB === 7 ? 0 : deltaB, "days");

    days = getDatesBetweenDates(startDate, endDate);

    return days;
  };

  const days = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

  let calendarWeeks = [[]];
  let weekIndex = 1;
  const calendar = getCalendar();

  calendar.forEach((day, index) => {
    calendarWeeks[calendarWeeks.length - 1].push(day);
    weekIndex++;

    if (weekIndex > 7 && index < calendar.length - 1) {
      calendarWeeks.push([]);
      weekIndex = 1;
    }
  });

  const calendarRows = calendarWeeks.map((row, index) => {
    const rowDays = row.map((day) => {
      const classes = ["calendar-mini__number"];

      if (moment(day).format("MM") !== moment(currentMonth).format("MM"))
        classes.push("calendar-mini__number--other-month");
      if (moment(day).format("DD.MM") === moment(now).format("DD.MM"))
        classes.push("calendar-mini__number--now");
      if (churchDates[day] && churchDates[day].length > 0) {
        let isBig = false;

        churchDates[day].forEach((cd) => {
          if (cd.gage === "BIG") isBig = true;
        });

        if (isBig) {
          classes.push("calendar-mini__number--holiday"); // BIG
        } else {
          classes.push("calendar-mini__number--holiday _holiday-small"); // SMALL
        }
      }

      let calendarDots = "";
      if (moment(day).format("MM") === moment(currentMonth).format("MM") && notices[moment(day).format("MM-DD")]) {
        let hasH = false;
        let hasR = false;

        notices[moment(day).format("MM-DD")].forEach((notice) => {
          if (notice.date2) {
            hasR = true;
          } else {
            hasH = true;
          }
        });

        calendarDots = (
          <div className="calendar-dots-area">
            <div className="calendar-dots">
              {hasH && <span className="calendar-dot calendar-dot--h" />}
              {hasR && <span className="calendar-dot calendar-dot--r" />}
            </div>
          </div>
        );
      }

      return (
        <div
          className="calendar-mini__col"
          key={day}
          onClick={() => handleNow(moment(day).format("YYYY-MM-DD"))}
        >
          <div className={`${classes.join(" ")}`}>
            {unzeroDay(moment(day).format("DD"))}

            {calendarDots}
          </div>
        </div>
      );
    });

    return (
      <div className="calendar__row" key={index}>
        {rowDays}
      </div>
    );
  });

  const handleNow = (date) => {
    if (moment(date).format("MM") === moment(currentMonth).format("MM")) {
      setNow(moment(date).format("YYYY-MM-DD"));
    }
  };

  const cds = getCds();
  const ns = getNs();

  useEffect(() => {
    let body = document.getElementsByTagName("body")[0];
    if (SUser.isOpenCalendarRight) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "";
    }
  }, [SUser.isOpenCalendarRight]);

  const variants = {
    open: {opacity: 1, x: 0},
    closed: {opacity: 0, x: "100%"},
  };
  const variants1 = {
    open: {opacity: 1},
    closed: {opacity: 0},
  };
  return (
    <>
      <motion.div
        animate={SUser.isOpenCalendarRight ? "open" : "closed"}
        variants={variants1}
        className="calendar-right-area"
      ></motion.div>
      <AnimatePresence>
        <motion.div
          animate={SUser.isOpenCalendarRight ? "open" : "closed"}
          variants={variants}
          // className={`calendar-right${SUser.isOpenCalendarRight ? " calendar-right--open" : ""}`}
          className="calendar-right"
          ref={toogle}
          style={{backgroundImage: `url(${IMAGES.CalendarBg})`}}
        >
          <div className="calendar-right__content">
            <button
              type="button"
              className="button calendar-right__button-close"
              onClick={() => SUser.setIsOpenCalendarRight(false)}
            >
              <Icon5 width={24} height={24} />
            </button>

            <div className="calendar-right__calendar calendar">
              <div className="calendar__header justify-content-center">
                <button className="button calendar__button-month">
                  <span>
                    <strong>{moment(currentMonth).format("MMM")}</strong>{" "}
                    {moment(currentMonth).format("YYYY")}
                  </span>
                </button>
              </div>

              <div className="calendar__body">
                <div className="calendar__days">
                  {days.map((day) => (
                    <p key={day} className="calendar-mini__day">
                      {day}
                    </p>
                  ))}
                </div>

                {calendarRows}
              </div>
            </div>

            <div className="calendar__choosen-date">
              {unzeroDay(moment(now).format("DD MMMM"))}
            </div>

            <div>
              {/* СЮДА СКРОЛЛ */}
              <OverlayScrollRightCalendar>
                {cds && (
                  <ChurchDate handleClick={closeCalendar} churchDates={cds} />
                )}

                {ns && <NoticeItems handleClick={closeCalendar} notices={ns} />}

                {(!ns && !isLoading) && <DateBlockStub mini handleClick={closeCalendar} />}

                {isLoading && (
                  <div style={{ marginBottom: "24px" }}>
                    <SkeletonTheme
                      baseColor={colorSkeleton.baseColor}
                      highlightColor={colorSkeleton.highlightColor}
                    >
                      <Skeleton
                        count={1}
                        containerClassName="skeleton"
                        className="skeleton-list"
                        style={{ height: "98px" }}
                      />
                    </SkeletonTheme>
                  </div>
                )}
              </OverlayScrollRightCalendar>
              {/* СЮДА СКРОЛЛ */}
            </div>
          </div>

          {/*<div className="calendar-right__bg"></div>*/}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default CalendarRight;
