import React, {useContext} from "react";
import {IMAGES} from "../../constants/images";
import {Link} from "react-router-dom";
import {HASH} from "../../constants/hash";
import GoogleSignin from "../google-signin/GoogleSignin";
import Load from "../load/Load";
import {CategoriesContext} from "../../context/categories";
import {useToasts} from "react-toast-notifications";

const ModalEditCategories = ({setShow}) => {

  const SCategories = useContext(CategoriesContext);

  const {addToast} = useToasts();

  const Submit = async (e) => {
    e.preventDefault();

    await SCategories.editCategorySubmit(addToast);

  };

  const handleEditCategory = (value) => {
    let _category = {...SCategories.editCategory, title: value};
    SCategories.setEditCategory(_category);
  };

  return (
    <form onSubmit={Submit} className="modal-window edit-category">
      <div className="modal-main ">
        <button
          onClick={() => setShow(false)}
          type="button"
          className="button modal-window__button-close">
          <img src={IMAGES.Icon5} alt="" className="m-0"/>
        </button>
        <div className="modal-window__content">
          <div className="modal-window__header">
            <h2 className="modal-window__title"><strong>Редактирование</strong><br/>
              категории</h2>
          </div>
          <div className="modal-window__body">
            <div className="input-container">
              <input
                type="text"
                className="input"
                placeholder="Название категории"
                name="text"
                value={SCategories.editCategory?.title}
                onChange={(e) => handleEditCategory(e.target.value)}
              />
              <p className="input-container__text text-left">
                Введите имя категории, которую хотите добавить
              </p>
              {/*{errors.email && <div className="input-error">{errors.email}</div>}*/}
            </div>

          </div>
        </div>

        <div className="modal-main__footer modal-window__footer flex-column">
          <button
            disabled={SCategories.editCategory?.title.length <= 3 || SCategories.loadingEditCategory}
            type="submit"
            className="button button--t2 modal-main__button modal-window__button mb-0">
            {SCategories.loadingEditCategory
              ?
              <Load/>
              :
              <span>Сохранить изменения</span>
            }

          </button>
        </div>
      </div>
    </form>
  );
};

export default ModalEditCategories;
