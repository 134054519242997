import React, {useContext, useState} from "react";
import {IMAGES} from "../../../constants/images";
import {UserContext} from "../../../context/user";
import {baseUrl} from "../../../utils/helpers";
import Content from "../../draft/Content";
import {useToasts} from "react-toast-notifications";
import Load from "../../load/Load";
import api from "../../../api";
import {Link} from "react-router-dom";
import {HASH} from "../../../constants/hash";

const ModalNote = ({setShow}) => {
  const [isLoading, setIsLoading] = useState(false);
  const SUser = useContext(UserContext);
  const type = SUser.showModalNoticeType;

  const {addToast} = useToasts();

  const saveToState = (data) => {
    SUser.setNames(data);
  };

  const sendNotice = async () => {
    setIsLoading(true);

    const data = {
      type: type.toUpperCase(),
      content: SUser.names,
      church_date_id: SUser.typeNoticePay.id,
    };

    await api
      .postNote(data)
      .then(async (response) => {
        SUser.setShowModalPay((prevState) => ({
          ...prevState,
          type: type,
          typeNotice: "church_date",
          show: true,
          price: response.note.price,
          invoice: response.note.id,
        }));
      })
      .catch((error) => {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });

        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setShow(false);

  }

  return (
    <div className="modal-window modal-main-area">
      <div className={"modal-main modal-note " + type}>
        <button
          className="button modal-main__close"
          onClick={() => handleClose()}
        >
          <img src={IMAGES.Icon5} alt="" className="m-0" />
        </button>

        <div className="modal-main__header">
          <img
            src={`${baseUrl}storage/${SUser?.typeNoticePay?.img3}`}
            alt=""
          />
        </div>

        <div className="modal-main__static">
          <>
            <div className="modal-main__content">
              <div className="note-content">
                <h2
                  className={
                    "note-content__title " +
                    (type === "REST" ? "note-content__title--rest" : "")
                  }
                >
                  {type === "REST"
                    ? "Записка об Упокоении"
                    : "Записка о Здравии"}
                </h2>

                <Content
                  initialContent={SUser.names}
                  onSave={null}
                  type={type}
                  onChange={saveToState}
                />
              </div>
            </div>
            <div className="modal-main__footer modal-main__footer--border-radius-0">
              {SUser.user && (
                <button
                  onClick={() => sendNotice()}
                  className={
                    "button  modal-main__button " +
                    (type === "REST" ? "button--t3" : "button--t2")
                  }
                >
                  {!isLoading && <span>Подать записку</span>}

                  {isLoading && (
                    <div style={{height: "20px"}}>
                      <Load />
                    </div>
                  )}
                </button>
              )}

              {!SUser.user && (
                <Link
                  to={HASH.SIGNUP}
                  className={
                    "button  modal-main__button " +
                    (type === "REST" ? "button--t3" : "button--t2")
                  }
                >
                  <span>Подать записку</span>
                </Link>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ModalNote;
