import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import '../src/assets/styles/index.sass';
import 'react-loading-skeleton/dist/skeleton.css'
import 'overlayscrollbars/css/OverlayScrollbars.css';
ReactDOM.render(
  <App />,
  document.getElementById('root')
);
