import React, {useEffect, useState} from "react";
import api from "../../api";
import {IMAGES} from "../../constants/images";
import HistoryItem from "../../components/history-item/HistoryItem";
import {colorSkeleton, defaultDescription} from "../../utils/helpers";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import MetaTags from "react-meta-tags";

const HistoryNotesPage = ({layout: Layout}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState([]);

  const initNotes = async () => {
    try {
      const resp = await api.getNotes();
      setNotes(resp.notes);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    initNotes();
  }, []);

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area draft-area min-h">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <div className="draft-area__content history-content">
                <div className="history-scroll">
                  {isLoading ? (
                    <SkeletonTheme
                      baseColor={colorSkeleton.baseColor}
                      highlightColor={colorSkeleton.highlightColor}
                    >
                      <Skeleton
                        count={1}
                        containerClassName="skeleton "
                        className="skeleton-history"
                      />
                    </SkeletonTheme>
                  ) : !notes?.length ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="navigation__link">Список пуст</span>
                    </div>
                  ) : (
                    notes.map((note) => {
                      return (
                        <HistoryItem
                          key={note.id}
                          note={note}
                          refresh={initNotes}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HistoryNotesPage;
