import {useContext, useState, useEffect} from "react";
import ReactDOM from "react-dom";
import {Link} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import Content from "./Content";
import {UserContext} from "../../context/user";
import api from "../../api";
import moment from "moment";
import "moment/locale/ru";
import {HelperContext} from "../../context/helper";
import {useToasts} from "react-toast-notifications";
import Load from "../load/Load";
import {HASH} from "../../constants/hash";

const DraftContent = ({type}) => {
  const SUser = useContext(UserContext);
  const SHelper = useContext(HelperContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPring, setIsLoadingPrint] = useState(false);
  const {addToast} = useToasts();
  const [isInited, setIsInited] = useState(false);
  const [content, setContent] = useState([]);

  const isRest = () => type === "REST";

  const saveToState = (data) => {
    setContent(JSON.parse(data));
  };

  useEffect(() => {
    const update = async () => {
      const resp = await api.getMe();
      SUser.setUser(resp.user);
      setContent(JSON.parse(resp.user[type.toLowerCase()]));
    };

    if (SUser.user) update();
  }, []);

  useEffect(() => {
    if (isInited) return;

    if (SUser.user) {
      // если пользователь залогинен
      if (SUser.user[type.toLowerCase()]) {
        setContent(JSON.parse(SUser.user[type.toLowerCase()]));
      } else {
        setContent([]);
      }
    } else {
      const localContent = localStorage.getItem(`__local_content--${type}`);
      if (localContent) {
        setContent(JSON.parse(localContent));
      } else {
        localStorage.setItem(`__local_content--${type}`, JSON.stringify([]));
      }
    }

    setIsInited(true);
  }, [isInited]);

  const saveDraft = async (data) => {
    if (SUser.user) {
      const sendData = {};
      sendData[type.toLowerCase()] = data;

      api
        .saveDraft(sendData)
        .then(async (resp) => {
          SUser.setUser(resp.user);
          setContent(JSON.parse(resp.user[type.toLowerCase()]));
        })
        .catch((e) => {
          console.log("e", e.message);
        });
    } else {
      localStorage.setItem(`__local_content--${type}`, data);
    }
  };

  const showModalPay = (type, typeNotice, price, invoice) => {
    SUser.setShowModalPay((prevState) => ({
      ...prevState,
      type: type,
      typeNotice: typeNotice,
      show: true,
      price,
      invoice,
    }));
  };

  const sendNotice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      type: type.toUpperCase(),
      content: isRest() ? SUser.user.rest : SUser.user.health,
      church_date_id: null,
      notice_id: null,
    };

    await api
      .postNote(data)
      .then(async (response) => {
        showModalPay(
          type === "REST" ? "REST" : "HEALTH",
          "draft",
          response.note.price,
          response.note.id
        );
      })
      .catch((error) => {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });

        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const printNotice = async (e) => {
    e.preventDefault();
    setIsLoadingPrint(true);

    const data = {
      type: type.toUpperCase(),
      content: isRest() ? SUser.user.rest : SUser.user.health,
    };

    await api
      .printNote(data)
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment().format("YYYYMMDDhmmss")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });

        console.log("error", error);
      })
      .finally(() => {
        setIsLoadingPrint(false);
      });
  };

  return (
    <>
      <div className="event-view-area draft-area min-h">
        <div className="event-view">
          <img
            src={IMAGES.Pic18}
            className="event-view__content-image1"
            alt=""
          />
          <img
            src={IMAGES.Pic18}
            className="event-view__content-image2"
            alt=""
          />
          <div className="draft-area__content history-area">
            <div className="event-view__content">
              {isInited && (
                <Content
                  initialContent={content !== null ? JSON.stringify(content) : null}
                  onSave={saveDraft}
                  type={type}
                  onChange={(e) => saveToState(e)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {ReactDOM.createPortal(
        <div
          className="modal-main__footer flex-column draft-buttons"
          style={{bottom: `${SHelper.sizeFooter}px`}}
        >
          {SUser.user && (
            <button
              type="button"
              onClick={sendNotice}
              className={
                "button mb-10 draft-area__button  modal-main__button " +
                (type === "REST" ? "button--t3" : "button--t2")
              }
              disabled={!content?.length}
            >
              {!isLoading && <span>Отправить записку</span>}

              {isLoading && (
                <div style={{height: "20px"}}>
                  <Load />
                </div>
              )}
            </button>
          )}

          {SUser.user && (
            <button
              onClick={printNotice}
              className={
                "button draft-area__button  button-pdf " +
                (type === "REST" ? "button--t3" : "button--t2")
              }
              disabled={!content?.length}
            >
              <div className="button-pdf__icon-block">
                <img src={IMAGES.Icon18} alt="PDF" />
              </div>

              {!isLoadingPring && <span>Скачать записку в PDF</span>}

              {isLoadingPring && <Load />}
            </button>
          )}

          {!SUser.user && (
            <Link
              to={HASH.SIGNUP}
              className={
                "button mb-10 draft-area__button  modal-main__button " +
                (type === "REST" ? "button--t3" : "button--t2")
              }
            >
              <span>Отправить записку</span>
            </Link>
          )}

          {!SUser.user && (
            <Link
              to={HASH.SIGNUP}
              className={
                "button draft-area__button  button-pdf " +
                (type === "REST" ? "button--t3" : "button--t2")
              }
            >
              <div className="button-pdf__icon-block">
                <img src={IMAGES.Icon18} alt="PDF" />
              </div>

              <span>Скачать записку в PDF</span>
            </Link>
          )}
        </div>,
        document.getElementById("root")
      )}
    </>
  );
};
export default DraftContent;
