import axios from 'axios';

export default {
  // праздники
  getChurchDate: async (id) =>
    axios.get(`church_dates/${id}`).then((res) => res.data),
  getNearChurchDates: async (dateFrom = "") =>
    axios.get(`church_dates/near/${dateFrom}`).then((res) => res.data),
  getChurchDates: async (dateFrom = "", dateTo = "") =>
    axios
      .get(`church_dates?from=${dateFrom}&to=${dateTo}&sorted=1`)
      .then((res) => res.data),
  getFastings: async (dateFrom = "", dateTo = "") =>
    axios
      .get(`fastings?from=${dateFrom}&to=${dateTo}&sorted=1`)
      .then((res) => res.data),
  // пользователь
  googleSignIn: async (data) =>
    axios.post("users/google", data).then((res) => res.data),
  login: async (data) =>
    axios.post("users/login", data).then((res) => res.data),
  signup: async (data) =>
    axios.post("users/signup", data).then((res) => res.data),
  getMe: async () => axios.get("users/me").then((res) => res.data),
  postMe: async (data) => axios.post("users/me", data).then((res) => res.data),
  changePassword: async (data) =>
    axios.post("users/change_password", data).then((res) => res.data),
  updateToken: async (data) =>
    axios.post("update_token", data).then((res) => res.data),
  passwordRecovery: async (email) =>
    axios.post(`users/reset`, {email}).then((res) => res.data),
  passwordRecoveryConfirmReset: async (data) =>
    axios.post(`users/confirm_reset`, data).then((res) => res.data),
  // подписка
  getSubscription: async () =>
    axios.get("subscription").then((res) => res.data),
  createSubscription: async (data) =>
    axios.post("subscription", data).then((res) => res.data),
  editSubscription: async (id, data) =>
    axios.post(`subscription/${id}`, data).then((res) => res.data),
  pushNotification: async (data) =>
    axios.post(`push_notification`, data).then((res) => res.data),
  getNotification: async () =>
    axios.get(`push_notification`).then((res) => res.data),
  // записки
  saveDraft: async (data) =>
    axios.post("users/save_draft", data).then((res) => res.data),
  postNote: async (data) => axios.post("notes", data).then((res) => res.data),
  printNote: async (data) =>
    axios({url: "notes/print", method: "POST", responseType: "blob", data}),
  getNotes: async () => axios.get("notes").then((res) => res.data),
  // обратная связь
  postFeedback: async (data) => axios.post("feedback", data),
  // категориии событий
  getNoticeCategories: async () =>
    axios.get("notice_categories").then((res) => res.data),
  createCategory: async (data) => axios.post("notice_categories", data),
  deleteCategory: async (id) =>
    axios.delete(`notice_categories/${id}`).then((res) => res.data),
  editCategory: async ({id, title}) =>
    axios.post(`notice_categories/${id}`, {title}).then((res) => res.data),
  // события
  createNotice: async (data) =>
    axios.post("notices", data, {
      "Content-Type": "multipart/form-data",
    }),
  getNotices: async () => axios.get("notices?sorted=1").then((res) => res.data),
  getNotice: async (id) => axios.get(`notices/${id}`).then((res) => res.data),
  getNoticesNoDate: async () => axios.get("notices").then((res) => res.data),
  deleteNotice: async (id) =>
    axios.delete(`notices/${id}`).then((res) => res.data),
  updateNotice: async (id, data) =>
    axios.post(`notices/${id}`, data).then((res) => res.data),
  // оплата
  payment: async (data) => axios.post("payment", data).then((res) => res.data),
  paymentWeb: async (data) =>
    axios.post("payment_web", data).then((res) => res.data)
};
