import {Routes, Route} from "react-router-dom";
import axios from "axios";
import {useEffect, useState, useContext} from "react";
import ScrollToTop from "./hooks/ScrollToTop";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import MainLayout from "./layouts/MainLayout";
import api from "./api";
import {UserContext} from "./context/user";
import {routes} from "./routeList";
import {ToastProvider} from "react-toast-notifications";
import Alert from "./components/alert/Alert";
import Load from "./components/load/Load";
import {baseUrl} from "./utils/helpers";
import {useLocation} from "react-router";
import {AnimatePresence} from "framer-motion";

export default function MainRouter() {
  const [inited, setInited] = useState(false);
  const SUser = useContext(UserContext);

  const anyRoutes = routes.any.map((route) => {
    return <Route key={route.path} path={route.path} element={route.element} />;
  });

  const protectedRoutes = routes.protected.map((route, index) => {
    return <Route key={index} path={route.path} element={route.element} />;
  });

  useEffect(() => {
    const init = async () => {
      axios.defaults.baseURL = baseUrl;

      const token = localStorage.getItem("token");

      if (!token) {
        setInited(true);
        return;
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let user = null;

      try {
        user = await api.getMe();
      } catch (error) {
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("token");
        setInited(true);
      }

      if (user) SUser.setUser(user.user);
      setInited(true);
    };

    if (!inited) init();
  });

  const location = useLocation();

  if (inited)
    return (
      <ToastProvider components={{Toast: Alert}}>
        <MainLayout>
          <ScrollToTop>
            <AnimatePresence exitBeforeEnter>
              <Routes location={location} key={location.pathname}>
                {anyRoutes}
                <Route element={<ProtectedRoute />}>{protectedRoutes}</Route>
              </Routes>
            </AnimatePresence>
          </ScrollToTop>
        </MainLayout>
      </ToastProvider>
    );

  return (
    <div style={{position: "absolute", left: "50%", top: "50%"}}>
      <div style={{position: "relative"}}>
        <Load />
      </div>
    </div>
  );
}
