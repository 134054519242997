import {createContext, useState} from "react";

export const HelperContext = createContext();

export const HelperContextProvider = ({children}) => {
  const [sizeHeader, setSizeHeader] = useState(0)
  const [sizeFooter, setSizeFooter] = useState(0)
  return (
    <HelperContext.Provider
      value={{
        sizeHeader, setSizeHeader,
        sizeFooter, setSizeFooter
      }}>
      {children}
    </HelperContext.Provider>
  );
}
