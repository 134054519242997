import CalendarItemsCd from "./CalendarItemsCd";
import CalendarItemsN from "./CalendarItemsN";
import DateBlockStub from "../date-block/DateBlockStub";

const CalendarItems = ({cds, ns, hasUser}) => {
  
  return (
    <div className="calendar-items-container">
      {cds && <CalendarItemsCd cds={cds} />}
      {ns && <CalendarItemsN ns={ns} />}

      {!ns && <DateBlockStub />}
    </div>
  );
};

export default CalendarItems;
