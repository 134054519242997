import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import {parseLink} from '../../utils/helpers'
const Navigation = ({style, links}) => {
  const [activeLink, setActiveLink] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(()=>{
    setActiveLink(parseLink())
  },[])

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav
              className={"navigation " + (open ? "navigation--open" : "")}
              onClick={e=> setOpen(!open)}
            >
              <div className="mobile-link-area">
                {activeLink}
                <img src={IMAGES.Icon30} alt="Открыть"
                     className="select-categories-area__icon" />
              </div>
              <ul className="navigation__list">
                <li className="navigation__item">
                  <NavLink to="/history"
                           className="navigation__link navigation__link--rest"
                  >
                    История записок
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/categories" className="navigation__link  navigation__link--rest">
                    Категории
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/data" className="navigation__link  navigation__link--rest">
                    Мои данные
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/notifications"
                           className="navigation__link  navigation__link--rest">
                    Уведомления
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink to="/support" className="navigation__link  navigation__link--rest">
                    Тех.Поддержка
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
