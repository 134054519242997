import React, {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {NoticesContext} from "../context/notices";
import Load from "../components/load/Load";
import OverlayScroll from "../components/OverlayScroll";
import {parseLink} from "../utils/helpers";
import {IMAGES} from "../constants/images";

const NoticeCreateLayout = ({children}) => {
  const SNotices = useContext(NoticesContext);
  const [loadPage, setLoadPage] = useState(true);
  const [activeLink, setActiveLink] = useState('')
  const [open, setOpen] = useState(false)
  const init = async () => {
    await SNotices.ResetStateNotices();
    await setLoadPage(false);
  };

  useEffect(() => {
    init();
    setActiveLink(parseLink())
    return () => {
      setLoadPage(true);
      SNotices.ResetStateNotices();
    };
  }, []);

  return (
    <>
      <OverlayScroll>
        <section className="container-fluid">
          <div className="container">
            <nav
              className={"navigation " + (open ? "navigation--open" : "")}
              onClick={e=> setOpen(!open)}
            >
              <div className="mobile-link-area">
                {activeLink}
                <img src={IMAGES.Icon30} alt="Открыть"
                     className="select-categories-area__icon" />
              </div>
              <ul className="navigation__list">
                <li className="navigation__item">
                  <NavLink
                    to="/notice-health-create"
                    className="navigation__link navigation__link--rest">
                    Дни Рождения
                  </NavLink>
                </li>
                <li className="navigation__item">
                  <NavLink
                    to="/notice-rest-create"
                    className="navigation__link  navigation__link--rest">
                    Памятные даты
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          {loadPage
            ?
            <Load/>
            :

            children

          }
        </section>
      </OverlayScroll>
    </>
  );
};

export default NoticeCreateLayout;
