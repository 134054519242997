import React from "react";
import OverlayScroll from "../components/OverlayScroll";

const PrivacyPolicyPage = () => {
  return (
    <OverlayScroll>
      <section className="container-fluid">
        <div className="privacy-policy-page">
          <div className="container privacy-policy">
            <div className="title-block">
              <h1 className="title">
                Политика конфиденциальности
              </h1>
            </div>
            <h3 className="sub-title">
              Оферта интернет-сервиса "Елицы.Записки"
            </h3>
            <div className="privacy-policy__item">
              <p>Настоящая Оферта представляет собой официальное предложение ИП Петренко Антона Павловича, далее
                именуемого Исполнитель, дееспособному, совершеннолетнему физическому лицу, далее именуемому Заказчик, на
                заключение договора (далее - Договор) на оказание курьерских, посреднических услуг посредством
                Интернет-сервиса «ЕЛИЦЫ.ЗАПИСКИ», расположенного по адресу в сети Интернет <a
                  href="https://zapiski.elitsy.ru">https://zapiski.elitsy.ru</a> (далее – Сайт), вместе именуемые
                Стороны, на нижеперечисленных условиях.<br/><br/>
                В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации данный документ является
                публичной офертой и, в случае принятия изложенных ниже условий и/или внесения денежных средств, лицо,
                осуществившее акцепт настоящей Оферты, становится Заказчиком. Акцептом (безусловным принятием) условий
                настоящей публичной оферты (моментом заключения Договора) в соответствии со статьей 438 ГК РФ считается
                факт регистрации Заказчика на Сайте и/или факт оплаты Заказчиком Услуг, предоставляемых Исполнителем в
                соответствии с условиями настоящей Оферты, при отсутствии явно выраженного письменного соглашения Сторон
                об ином.<br/><br/>
                Не признается надлежащим акцептом Оферты действия по регистрации на Сайте и/или оплате Услуг,
                осуществленные:
                <br/>- лицом, с которым Исполнителем ранее был расторгнут Договор в связи с нарушением указанным лицом
                положений Договора, а также
                <br/>- лицом, дублирующим Заказчика, с которым Исполнителем ранее был расторгнут Договор, в связи с нарушением
                им положений Договора.
                <br/><br/>Указанные действия не влекут возникновения каких-либо обязательств со стороны Исполнителя.
                <br/><br/>Исполнитель вправе самостоятельно определять технические способы ограничения акцепта Оферты в отношении
                вышеуказанных лиц.
                <br/><br/>Положения настоящего пункта не распространяются на случаи, когда Исполнитель осуществил однозначные
                действия, свидетельствующие о принятии Акцепта, осуществленного вышеуказанными лицами, в качестве
                надлежащего.
                <br/><br/>Заказчик понимает и принимает, что в результате использования Сайта и/или получения Услуг, приобретаемых
                посредством использования Сайта, он и/или указанные им третьи лица не приобретают специальных
                профессиональных знаний и навыков, не получают оздоровительный эффект, экономическую или какую-либо иную
                выгоду.
              </p>
              <span/>
              <h5>1. <strong>Предмет Договора</strong></h5>
              <p>
                1.1. Исполнитель с помощью функционала Сайта обязуется оказать Заказчику Услуги, выбранные Заказчиком на
                Сайте, в интересах Заказчика или третьего лица, в интересах которого действует Заказчик, в порядке,
                установленном настоящей Офертой.
              </p>
              <span/>
              <h5>2. <strong>Понятия и определения</strong></h5>
              <p>
                2.1. Сайт – интернет-сайт <a href="https://zapiski.elitsy.ru">https://zapiski.elitsy.ru</a>, на котором
                располагается Интернет-сервис «Елицы.Записки»
                <br/>2.2. Услуги – курьерские и посреднические услуги по передаче Религиозным организациями, указанным
                на Сайте, выбранных Заказчиком Обращений на проведение религиозных обрядов и иной информации, услуги по
                выполнению иных сопутствующих мероприятий, указанных на Сайте (например, поставить свечу в Религиозной
                организации), услуги по изготовлению макета изображения. Более подробное описание Услуг приведено на
                Сайте.
                <br/>2.3. В настоящей Оферте под Религиозными организациями понимаются некоммерческие организации такие
                как церкви, храмы, монастыри и иные некоммерческие организации религиозной направленности, указанные на
                Сайте. Понятие «Религиозны организации» для целей настоящей Оферты не соответствует термину, изложенному
                в Федеральном законе «О свободе совести и о религиозных объединениях» от 26.09.1997 N 125-ФЗ.
              </p>
              <span/>
              <h5>3. <strong>Порядок и сроки исполнения обязательств</strong></h5>
              <p>
                3.1. Заказчик с помощью ресурсов Сайта имеет возможность передавать Религиозным организациям, указанным
                на Сайте, Обращения с просьбой проведения религиозных обрядов, и иную информацию, а также заказать
                выполнение Исполнителем иных сопутствующих услуг, указанных на Сайте, например, поставить свечку
                выбранному Заказчиком святому и т.п.
                По выбору Заказчика, Услуги могут быть оказаны как на разовой, так и на регулярной основе на условиях
                подписки (еженедельно, ежемесячно, т.п.). В этом случае одна и та же выбранная Заказчиком Услуга
                оказывается Исполнителем с регулярностью, выбранной Заказчиком. Заказчик вправе в одностороннем порядке
                отменить подписку на Услуги или изменить периодичность оказания Услуги в своем личном кабинете на Сайте.
                Исполнитель вправе отменить подписку на Услугу в одностороннем порядке, в случае принятия Исполнителем
                решения о прекращении или изменении порядка оказания соответствующей Услуга. При этом Исполнитель обязан
                исполнить оплаченные Заказчиком на этот момент Услуги. В случае отмены подписки на Услугу ни одна из
                Сторон не вправе требовать оплаты какой-либо компенсации от Стороны, отменившей подписку.
                Под Услугой по изготовлению макета изображения подразумевается формирование изображения, предложенного
                Исполнителем с указанием даты оформления макета и имени Заказчика. В зависимости от выбранного
                Заказчиком изображения стоимость Услуги может меняться. Подготовленный Исполнителем макет изображения
                направляется Заказчику на адрес электронной почты, указанный при регистрации. Исполнитель перечисляет
                90% стоимости Услуги по изготовлению макета изображения в некоммерческие организации, указанные на Сайте
                (в Религиозные организации, благотворительные фонды и т.п.). Перечисление денежных средств производится
                на постоянной основе в рамках периода оказания соответствующей Услуги и не является акцией.
                <br/>3.2. В соответствии с настоящей Офертой, Исполнитель не позднее 7 (семи) календарных дней выполняет
                Услугу.
                Исполнением религиозных обрядов занимаются Религиозные организации, выбранные Заказчиком из указанных на
                Сайте. Сроки и порядок исполнения запрошенных религиозных обрядов, устанавливает Религиозная
                организация, выбранная Заказчиком из указанных на Сайте. Заказчик подтверждает, что понимает и осознает
                то, что Исполнитель не является Религиозной организацией, либо ее представителем. Исполнитель не несет
                ответственность за исполнение религиозных обрядов и выполнение каких-либо иных действий Религиозными
                организациями.
                <br/>3.4. В соответствии с данной Офертой, фактом выполнения Исполнителем своих обязательств по оказанию
                Услуг перед Заказчиком, является предоставление Заказчику информации о приёме и обработке Исполнителем
                заказа на Услуги и последующего отчета по факту передачи Обращения Заказчика в Религиозную организацию и
                исполнения иных Услуг, согласно п. 2.2 настоящей Оферты. Данная информация предоставляется Исполнителем
                по адресу электронной почты, указанному Заказчиком при оформлении заявки на оказание Услуги и/или при
                регистрации на Сайте.
                Моментом оказания Услуги по изготовлению макета изображения признается момент направления
                соответствующего изображения Исполнителем Заказчику по электронной почте.3.5. Исполнитель совершает
                Услуги посреднического и курьерского характера, указанные на Сайте, что является его коммерческой
                деятельностью, соответствующей Уставу и иным правоустанавливающим документам Исполнителя, а, так же, не
                противоречит действующему законодательству РФ.
              </p>
              <span/>
              <h5>4. <strong>Обязанности и права Сторон</strong></h5>
              <p>
                4.1. Обязанности Заказчика:
                <br/>4.1.1. Внимательно ознакомиться с настоящей Офертой, иной информацией на Сайте, имеющей отношение к
                порядку заказа Услуг, а также следить за изменениями в них на Сайте. При этом Исполнитель направляет
                Заказчику информацию об изменениях, внесенных в настоящую Оферту и иные правовые документы, размещенные
                на Сайте, посредством направления уведомления в личный кабинет Заказчика. .
                <br/>4.1.2. Нести личную ответственность за любые последствия, вызванные действиями, совершенными
                Заказчиком с использованием ресурсов Сайта.
                <br/>4.1.3. При заказе Услуг с помощью ресурсов Сайта указать достоверные, актуальные и точные сведения
                и нести личную ответственность за представление неверной информации. Предварительно получить согласие
                субъекта персональных данных на передачу и обработку персональных данных или иной конфиденциальной
                информации при передаче Исполнителю посредством Сайта персональных данных или иной конфиденциальной
                информации третьих лиц.
                <br/>4.1.4. Строго соблюдать политику конфиденциальности, установленную в гл. 6 настоящей Оферты.
                4.1.5. Заказчик настоящим заверяет, гарантирует и соглашается, что при использовании Сайта он:
                - не будет приводить недостоверную, аморальную, противоречащую требованиям законодательства информацию;
                - не будет нарушать, подстрекать или поощрять нарушение авторского права, ноу-хау, коммерческие тайны
                или другие имущественные, или неимущественные права на результаты интеллектуальной деятельности, права
                на неприкосновенность частной жизни или другие законные права любого третьего лица в соответствии с
                любым применимым законодательством или нормами международного права;
                - не будет выдавать себя за другое физическое или юридическое лицо, представляться другим лицом;
                - не будет предпринимать попытки копирования, воспроизведения, использования или лишения права
                собственности или любых ограничений в отношении различных собственных справочников, баз данных
                Исполнителя, а также принадлежащих ему любых иных объектов интеллектуальной собственности;
                - не будет распространять компьютерные вирусы или другие разрушающие устройства и коды, которые могут
                повредить, помешать работе, перехватить данные или лишить права собственности на какую-либо программную
                или аппаратную систему, данные или персональную информацию; а также
                - не будет участвовать в какой-либо схеме с целью нарушения целостности компьютерных систем или сетей,
                используемых Исполнителем и/или нарушения прав и законных интересов Исполнителя и/или третьих лиц.

                4.2. Обязанности Исполнителя:
                <br/>4.2.1. Обеспечить работоспособность Сайта, за исключением случаев, когда доступ к Сайту закрыт по
                причинам, не зависящим от воли Исполнителя
                <br/>4.2.2. Должным образом выполнять принятые на себя обязательства по передаче информации от Заказчика
                Религиозным организациям, в соответствии с заказанной Заказчиком Услугой и исполнять иные Услуги,
                заказанные и оплаченные Заказчиком.
                <br/>4.2.3. Предпринимать общепринятые технические и организационные меры, направленные на обеспечение
                сохранности информации Заказчика.
                <br/>4.2.5. Строго соблюдать положения о конфиденциальности, установленные в гл. 6 настоящей Оферты.
                4.3. Права Исполнителя:
                <br/>4.3.1. Временно ограничить доступ к Сайту, если это вызвано необходимостью восстановления
                работоспособности Сайта.
                <br/>4.3.2. Исполнитель оставляет за собой право вносить изменения в настоящую Оферту в одностороннем
                порядке. Изменения вступают в силу и становятся обязательными для Сторон сразу после публикации на Сайте
                (см. п. 10.1). Исполнитель вправе в одностороннем порядке менять перечень, порядок, место, время
                оказания Услуг и их стоимость, а также добавлять новые услуги. Изменение стоимости, порядка, места и
                времени оказания оплаченных Заказчиком услуг возможно с согласия Заказчика. В случае отказа Заказчика от
                согласования таких изменений, Исполнитель обязуется вернуть стоимость соответствующей Услуги, а сама
                Услуга не оказывается.
                <br/>4.3.3. Отказаться от исполнения Договора и/или ограничить доступ Заказчика к Сайту, если действия
                Заказчика будут препятствовать нормальной работоспособности Сайта, нарушать права Исполнителя и/или
                третьих лиц или нарушать требования законодательства Российской Федерации.
                <br/>4.3.4. Использовать указанные Заказчиком на Сайте адреса электронной почты, номера сотовых
                телефонов и иные контактные данные для рассылки уведомлений и информации, связанной с деятельностью
                Сайта и ходом оказания Услуг. Заказчик разрешает Исполнителю направлять ему на номера сотовых телефонов,
                электронную почту, а также на иные контактные данные, указанные им на Сайте сведения рекламного и
                информационного характера в виде текстовых сообщений и (или) графических изображений, если Заказчик
                предоставил свое согласие на получение информации рекламного характера.
                <br/>4.3.5. Исполнитель оказывает услуги своими силами, а также вправе привлекать третьих лиц в целях
                оказания Услуг, без предварительного согласия Заказчика.
                4.4. Права Заказчика:
                <br/>4.4.1. Пользоваться ресурсами Сайта для передачи информации Религиозным организациям в своих
                интересах.
                <br/>4.4.2. Получать информацию от Исполнителя о ходе оказания Услуг.
              </p>
              <span/>
              <h5>5. <strong>Порядок оплаты и оказания услуг</strong></h5>
              <p>
                <br/>5.1. Акцептуя настоящую Оферту, Заказчик подтверждает, что он ознакомлен с размерами платы за
                оказываемые услуги и согласен оплачивать их в предварительном порядке.
                <br/>5.2. Оплата производится Заказчиком в форме 100% предоплаты в момент составления заказа на Услуги по
                форме, установленной на Сайте.
                <br/>5.3. Моментом исполнения обязанности Заказчика по оплате Услуг является момент зачисления денежных
                средств на расчетный счет Исполнителя.
                <br/>5.4. При оплате Услуг, Заказчик вправе избрать способ оплаты (безналичный, электронный, мобильный), из
                перечня способов оплаты доступных и указанных на Сайте.
                <br/>5.5. Заказчик несет полную ответственность за правильность производимых им платежей.
                <br/>5.6. При осуществлении оплаты в безналичном, электронном порядке Заказчик указывает платежные реквизиты,
                которые Исполнитель передает платежному сервису. Оплата производится в соответствии с условиями
                платежного сервиса. Исполнитель не несет ответственности за порядок и условия перечисления денежных
                средств платежным сервисом.
                <br/>5.7. При заказе Заказчиком Услуг на регулярной основе на условиях подписки, списание денежных средств,
                за оказываемые Услуги производится автоматически в порядке предоплаты. Списание денежных средств
                производится в день оказания Услуги. В случае отсутствия денежных средств на счету Заказчика, услуга не
                оказывается. В случае невозможности списания денежных средств за Услугу более трех раз, подписка на
                оказание Услуг подлежит автоматической отмене.
              </p>
              <span/>
              <h5>6. <strong>Политика конфиденциальности</strong></h5>
              <p>6.1. Любая персональная информация, помимо общеизвестной, переданная Сторонами друг другу при
                заключении, исполнении условий Оферты, является конфиденциальной информацией.
                <br/>6.2. Стороны обязуются соблюдать действующее законодательство РФ, регламентирующее правоотношения,
                связанные с установлением, изменением и прекращением режима конфиденциальности в отношении персональной
                информации Сторон и не разглашать конфиденциальную информацию третьим лицам.
                <br/>6.3. Заказчик дает разрешение Исполнителю на сбор, обработку и хранение своих личных персональных данных
                на условиях, установленных в настоящей Оферте, в Политиках конфиденциальности и сбора cookie-файлов.
                <br/>6.4. В целях исполнения обязательств по Договору, Исполнитель собирает два вида информации о Заказчике:
                - персональную информацию, которую Заказчик сознательно раскрыл Исполнителю в целях пользования
                ресурсами Сайта: имя, номер сотового телефона, адрес электронной почты, платежные реквизиты и пр.;
                - техническую информацию, автоматически собираемую программным обеспечением Сайта во время его
                посещения.
                При этом после передачи платежных реквизитов платежному сервису они подлежат немедленному уничтожению.
              </p>
              <span/>
              <h5>7. <strong>Ответственность Сторон</strong></h5>
              <p>
                7.1. За неисполнение или ненадлежащее исполнение обязательств по Договору Стороны несут ответственность
                в соответствии с действующим законодательством Российской Федерации.
                <br/>7.2. Исполнитель несет ответственность за передачу обращений Заказчиков и иной информации Религиозным
                организациям, выбранным Заказчиком, в соответствии с заказанной и оплаченной Заказчиком Услугой.
                <br/>7.3. Исполнитель не несет ответственность за полные или частичные прерывания в работоспособности Сайта,
                связанные с заменой/сбоями оборудования, программного обеспечения или проведения ремонтных,
                профилактических и иных работ, действием провайдеров или иными причинами, не зависящими от воли
                Исполнителя.
                <br/>7.4 Совокупная ответственность Исполнителя по Договору по вопросу оказания Заказчиком Услуги, по любому
                иску или претензии в отношении действия Договора или его исполнения, ограничивается суммой платежа,
                уплаченного Заказчиком по Договору.
                <br/>7.5. Доступ Заказчику к Сайту предоставляется на условиях «как есть». Непосредственно использование
                Сайта является безвозмездным, возмездно предоставляются Услуги, которые доступны к заказу посредством
                Сайта.
                <br/>7.6. Исполнитель не несет ответственности за убытки, которые могут возникнуть у Заказчика в связи с
                использованием Сайта. В любом случае ответственность Исполнителя перед Заказчиком за любые нарушения,
                связанные с использованием Сайта (помимо нарушений, указанных в п. 7.4. настоящей Оферты) ограничивается
                суммой 100 (Сто) рублей.
              </p>
              <span/>
              <h5>8. <strong>Обстоятельства непреодолимой силы</strong></h5>
              <p>
                8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по
                Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, а также принятия
                государственными органами законодательных актов, препятствующих выполнению условий Договора. В этом
                случае выполнение обязательств по Договору откладывается на время действия обстоятельств непреодолимой
                силы и их последствий.
              </p>
              <span/>
              <h5>9. <strong>Срок действия Договора</strong></h5>
              <p>
                9.1. Договор вступает в силу с момента акцепта настоящей Оферты и действует бессрочно. Стороны вправе
                расторгнуть Договор уведомив об этом вторую Сторону не позднее чем за 2 (два) календарных дня до даты
                расторжения. Заказчик направляет такое уведомление по адресу электронной почты, указанному Заказчиком
                при регистрации, а Заказчик – по адресу электронной почты, указанному в п. 11 Договора.
              </p>
              <span/>
              <h5>10. <strong>Прочие условия</strong></h5>
              <p>
                10.1. Настоящая Оферта в актуальном состоянии публикуется в сети Интернет по адресу
                <a href="https://zapiski.elitsy.ru/">https://zapiski.elitsy.ru/.</a> Исполнитель оставляет за собой право изменять и/или дополнять настоящую
                Оферту. Исполнитель будет публиковать изменения на этой странице Сайта, Заказчик обязан самостоятельно
                отслеживать изменения настоящей Оферты, пользование Сайтом означает принятие Заказчиком таких
                изменений/дополнений.
                Исполнитель направляет Заказчику информацию об изменениях, внесенных в настоящую Оферту и иные правовые
                документы, размещенные на Сайте, в личный кабинет Заказчика не менее чем за 24 часа до моменту
                вступления в силу соответствующих изменений.
                <br/>10.2. Вопросы, не урегулированные настоящей Офертой, регламентируются нормами действующего
                законодательства Российской Федерации.
                <br/>10.3. В случае если положения настоящей Оферты противоречат требованиям законодательства Российской
                Федерации, данные положения не подлежат исполнению, а Договор применяется в остальной части.
                <br/>10.4. Заказчик подтверждает, что использует Сайт и заказывает Услуги в личных целях, не связанных с
                предпринимательской деятельностью. Использование Сайта не предусматривает возможность получения
                какой-либо прибыли для Заказчика.
                <br/>10.5. Все разногласия, которые могут возникнуть в связи с настоящей Офетой и Договором, будут решаться
                Сторонами путем проведения взаимных переговоров.
                <br/>10.6. Если Стороны не смогут прийти к согласию в ходе переговоров в течение 30 (тридцати) календарных
                дней с момента получения соответствующей претензии, споры подлежат разрешению в судебном порядке по
                месту регистрации Исполнителя.
              </p>
              <span/>
              <h5>11. <strong>Адреса и реквизиты Исполнителя</strong></h5>
              <p>
                Фактический адрес: 125222, гор.Москва, ул. Барышиха, дом 2, кв. 39.
                <br/>Электронная почта: admin@zapiski.elitsy.ru
                <br/>Телефоны: +7 985 973-45-97
                <br/>Реквизиты:
                <br/>ИП Петренко А.П.
                <br/>ИНН 773307598446
                <br/>ОГРНИП 308770000279749
                <br/>ПАО СБЕРБАНК Г. МОСКВА
                <br/>БИК 044525225
                <br/>р/с 40802810938000187264
                <br/>к/с 30101810400000000225
                <br/>
                Редакция от «15» февраля 2021г.
              </p>
            </div>
          </div>
        </div>
      </section>
    </OverlayScroll>
  );
};

export default PrivacyPolicyPage;
