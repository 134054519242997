import React from "react";
import Datetime from "react-datetime";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import "./date-picker-component.sass";

registerLocale("ru", ru);

const DatePickerComponent = ({placeholderText, dateFormat, selected, onChange, readOnly = false}) => {

  return (
    <DatePicker
      className="input"
      popperPlacement="bottom"
      locale="ru"
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      selected={selected}
      // onChange={date => onChange(type === "create" ? date : Datetime.moment(date).format('YYYY-MM-DD'))}
      onChange={date => onChange(date)}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      maxDate={new Date()}
    />
  );
};

export default DatePickerComponent;
