import {baseUrl, greatDates} from "../../utils/helpers";
import {Link} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

const CalendarItemsCd = ({cds}) => {
  const printChurchDate = (cd, params) => {
    const classes = [];

    if (params?.wide) classes.push("church-date-mini--wide");
    if (cd.gage === "SMALL") classes.push("church-date-mini--small");
    if (greatDates.indexOf(cd.id) >= 0) classes.push("church-date-mini--great");

    return (
      <Link
        key={cd?.id}
        to={`/church-date/${cd?.id}`}
        className={"church-date-mini" + ` ${classes.join(" ")}`}
      >
        {cd.gage === "BIG" && (
          <div
            className="church-date-mini__uzor"
            style={{backgroundImage: `url(${IMAGES.ChurchDateUzor})`}}
          ></div>
        )}
        <div className="church-date-mini__bottom">
          <div className="church-date-mini__pre">
            <span
              className={
                "church-date-mini__pre-text " +
                (cd?.gage === "SMALL"
                  ? "church-date-mini__pre-text--small"
                  : "")
              }
            >
              {cd?.pre}
            </span>
          </div>
          <div className="church-date-mini__title">
            <span
              className={
                "church-date-mini__title-text " +
                (cd?.gage === "SMALL"
                  ? "church-date-mini__title-text--small"
                  : "")
              }
            >
              {cd?.title}
            </span>
          </div>
        </div>

        {cd.gage === "BIG" && (
          <img src={IMAGES.Shadow} className="church-date-mini__shadow" />
        )}

        <img
          className="church-date-mini__bg"
          loading="lazy"
          src={`${baseUrl}storage/${cd?.img1}`}
          alt={cd.title}
        />
      </Link>
    );
  };

  if (cds.length === 1) {
    return printChurchDate(cds[0], {wide: true});
  }

  if (cds.length > 1) {
    const cdsSwiper = cds.map((churchDate) => {
      return (
        <SwiperSlide key={churchDate?.id}>
          {printChurchDate(churchDate)}
        </SwiperSlide>
      );
    });

    return (
      <>
        <div
          className={
            "swiper-custom " + (cds.length > 2 ? "" : "hide-pagination")
          }
        >
          <Swiper
            modules={[Pagination]}
            freeMode={true}
            spaceBetween={30}
            slidesPerView={2}
            pagination={{clickable: true}}
            breakpoints={{
              // when window width is >= 640px
              0: {
                // width: 0,
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              767: {
                // width: 0,
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
            }}
          >
            {cdsSwiper}
          </Swiper>
        </div>
      </>
    );
  }
};

export default CalendarItemsCd;
