import React, {useContext, useState, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
import {UserContext} from "../../../context/user";
import {baseUrl} from "../../../utils/helpers";
import {IMAGES} from "../../../constants/images";
import Load from "../../load/Load";
import validator from "validator";
import moment from "moment";
import api from "../../../api";
import {useToasts} from "react-toast-notifications";

const checkout = new window.cp.Checkout({
  publicId: "pk_a4bc5533b25f5b56bf45bfd7177dd"
});

const PayContent = ({type, setShow}) => {
  const SUser = useContext(UserContext);

  const {addToast} = useToasts();

  const [cardNumber, setCardNumber] = useState("");
  // const [cardNumber, setCardNumber] = useState("4276134007778166");
  const [cardMM, setCardMM] = useState("");
  // const [cardMM, setCardMM] = useState("04");
  const [cardY, setCardY] = useState("");
  // const [cardY, setCardY] = useState("23");
  const [cardCVC, setCardCVC] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [agreements, setAgreements] = useState(true);

  const [PRICE, setPRICE] = useState(0);
  const [INVOICE, setINVOICE] = useState(0);

  const DESCRIPTION = "Оплата записки";

  const refs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    if (SUser.showModalPay.show) {
      setPRICE(SUser.showModalPay.price);
      setINVOICE(SUser.showModalPay.invoice);
    } else {
      SUser.setTypeNoticePay({});
      setPRICE(0);
      setINVOICE(0);
    }
  }, [SUser.showModalPay.show])

  const submit = async (e) => {
    try {
      e.preventDefault();
  
      setIsLoading(true);
      const newErrors = {};
      setErrors(newErrors);

      const date = moment(`20${cardY}-${cardMM}-01`, "YYYY-MM-DD");

      if (!agreements) newErrors["agreements"] = "Примите соглашение";

      // const isCardNumberValid = validator.isCreditCard(cardNumber);

      // if (!isCardNumberValid) {
      //   newErrors["cardNumber"] = "Некорректный номер карты";
      // }

      const isCardDateValid = date.isAfter(moment());

      if (!isCardDateValid) {
        newErrors["cardDate"] = "Некорректная дата";
      }

      if (!cardCVC) {
        newErrors["cardCVC"] = "Введите CVC код";
      }

      if (Object.keys(newErrors).length > 0) {
        // console.log('123133');

        setErrors(newErrors);
        setIsLoading(false);

        return;
      }

      const crypto = await checkout.createPaymentCryptogram({
        cvv: cardCVC,
        cardNumber: cardNumber,
        expDateMonth: cardMM,
        expDateYear: cardY
      });

      const payment = await api.paymentWeb({
        invoice: INVOICE,
        amount: PRICE,
        decription: DESCRIPTION,
        crypto
      });

      const MD = payment.result.Model.TransactionId;
      const PaReq = payment.result.Model.PaReq;
      const AcsUrl = payment.result.Model.AcsUrl;

      const form = document.createElement("form");
      form.action = AcsUrl;
      form.method = 'POST';
      
      const inputMD = document.createElement('input');
      inputMD.setAttribute('type', 'hidden');
      inputMD.name = 'MD';
      inputMD.setAttribute('value', MD);

      const inputPaReq = document.createElement('input');
      inputPaReq.setAttribute('type', 'hidden');
      inputPaReq.name = 'PaReq';
      inputPaReq.setAttribute('value', PaReq);

      const inputTermUrl = document.createElement('input');
      inputTermUrl.setAttribute('type', 'hidden');
      inputTermUrl.name = 'TermUrl';
      inputTermUrl.setAttribute('value', `${baseUrl}payment_result`);

      form.appendChild(inputMD);
      form.appendChild(inputPaReq);
      form.appendChild(inputTermUrl);

      document.body.appendChild(form);
      form.submit();

      return;
    } catch (error) {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });

        console.log("error", error);

      setErrors({});
      setIsLoading(false);
    }
  };

  const delay = 500

  useEffect(() => {
    setTimeout(()=>{
      if (validator.isCreditCard(cardNumber)) refs[1].current.focus();
    },delay)

  }, [cardNumber]);

  useEffect(() => {
    setTimeout(()=>{
      if (cardMM.length === 2) refs[2].current.focus();
    },delay)

  }, [cardMM]);

  useEffect(() => {
    setTimeout(()=>{
      if (cardY.length === 2) refs[3].current.focus();
    },delay)

  }, [cardY]);

  return (
    <div className="modal-window modal-main-area modal-pay">
      <div className={"modal-main modal-note " + type}>
        <button
          className="button modal-main__close"
          onClick={() => setShow(false)}
        >
          <img src={IMAGES.Icon5} alt="" className="m-0"/>
        </button>

        {SUser?.showModalPay?.typeNotice === "church_date" && (
          <div className="modal-main__header">
            <img
              src={`${baseUrl}storage/${SUser?.typeNoticePay?.img3}`}
              alt=""
            />
          </div>
        )}

        {SUser?.showModalPay?.typeNotice === "notice" &&
        (SUser.typeNoticePay?.img ? (
          <div className="modal-main__header">
            <img
              src={`${baseUrl}storage/${SUser.typeNoticePay?.img}`}
              alt=""
            />
          </div>
        ) : type === "REST" ? (
          <div className={"modal-main__header " + type}>
            <img src={IMAGES.Pic20} alt="Нет фото"/>
          </div>
        ) : (
          <div className={"modal-main__header " + type}>
            <img src={IMAGES.Pic19} alt="Нет фото"/>
          </div>
        ))}

        {SUser?.showModalPay?.typeNotice === "draft" &&
        <div className={"modal-main__header " + (type)}/>
        }

        <form className="modal-main__static" onSubmit={submit}>
          <div className="modal-main__content">
            <div className="note-content">
              <h2
                className={
                  "note-content__title " +
                  (type === "REST" ? "note-content__title--rest" : "")
                }
              >
                {type === "REST" ? "Записка об Упокоении" : "Записка о Здравии"}
              </h2>

              <div className="bank-card-area">
                <div className="bank-card">
                  <div
                    className={
                      "bank-card__header " +
                      (type === "REST" ? "bank-card__header--rest" : "")
                    }
                  >
                    <div className="bank-card__header-text">
                      <span className="total-text">Итого:</span>
                      <span className="total-price">
                        <strong>{PRICE}</strong> ₽
                      </span>
                    </div>
                  </div>

                  <div className={
                      "bank-card__body " +
                      (type === "REST" ? "bank-card__body--rest" : "")
                    }
                  >
                    <input
                      ref={refs[0]}
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                      placeholder="Номер карты"
                      name="cc-number"
                      maxLength={16}
                      type="text"
                      className="input input-border"
                    />

                    <div
                      className={
                        "input-error " +
                        (errors.cardNumber ? "input-error--open" : "")
                      }
                    >
                      {errors.cardNumber}
                    </div>

                    <div className="d-flex">
                      <input
                        ref={refs[1]}
                        value={cardMM}
                        onChange={(e) => setCardMM(e.target.value)}
                        placeholder="ММ"
                        name="cc-exp-month"
                        maxLength={2}
                        type="text"
                        className="input input-border card-mm"
                      />

                      <input
                        ref={refs[2]}
                        value={cardY}
                        onChange={(e) => setCardY(e.target.value)}
                        placeholder="ГГ"
                        name="cc-exp-year"
                        maxLength={2}
                        type="text"
                        className="input input-border card-gg"
                      />

                      <input
                        ref={refs[3]}
                        value={cardCVC}
                        onChange={(e) => setCardCVC(e.target.value)}
                        placeholder="CVC"
                        name="cc-cvc"
                        maxLength={3}
                        type="password"
                        className="input input-border card-cvc"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        gap: "1rem"
                      }}
                    >
                      <div
                        className={
                          "input-error " +
                          (errors.cardDate ? "input-error--open" : "")
                        }
                      >
                        {errors.cardDate}
                      </div>

                      <div
                        className={
                          "input-error " +
                          (errors.cardCVC ? "input-error--open" : "")
                        }
                      >
                        {errors.cardCVC}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "checkbox " + (type === "REST" ? "checkbox--rest" : "")
                  }
                >
                  <label htmlFor="addName">
                    <div className="checkbox__text d-flex ">
                      <input
                        checked={agreements}
                        readOnly={true}
                        id="addName"
                        type="checkbox"
                        name="add_name"
                        onChange={() => setAgreements(!agreements)}
                      />
                      <span className="checkbox__cr mr-10" style={{top: 5}}/>Я
                      согласен на обработку персональных данных и с условиями{" "}
                      <Link to="/" className="checkbox__link">
                        Договора оферты
                      </Link>
                    </div>
                  </label>

                  <div
                    className={
                      "input-error " +
                      (errors.agreements ? "input-error--open" : "")
                    }
                  >
                    {errors.agreements}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-main__footer modal-main__footer--border-radius-0">
            <button
              className={
                "button  modal-main__button " +
                (type === "REST" ? "button--t3" : "button--t2")
              }
              type="submit"
            >
              {!isLoading && <span style={{fontWeight: 700}}>Оплатить</span>}

              {isLoading && (
                <div style={{height: "20px"}}>
                  <Load/>
                </div>
              )}
            </button>
          </div>
        </form>

      </div>
    </div>
  );
};

export default PayContent;
