import Icon1 from "../assets/icons/icon-1.svg";
import Icon2 from "../assets/icons/icon-2.svg";
import Icon3 from "../assets/icons/icon-3.svg";
import Icon4 from "../assets/icons/icon-4.svg";
import Icon5 from "../assets/icons/icon-5.svg";
import Icon6 from "../assets/icons/icon-6.svg";
import Icon7 from "../assets/icons/icon-7.svg";
import Icon8 from "../assets/icons/icon-8.svg";
import Icon9 from "../assets/icons/icon-9.svg";
import Icon10 from "../assets/icons/icon-10.svg";
import Icon11 from "../assets/icons/icon-11.svg";
import Icon12 from "../assets/icons/icon-12.svg";
import Icon13 from "../assets/icons/icon-13.svg";
import Icon14 from "../assets/icons/icon-14.svg";
import Icon15 from "../assets/icons/icon-15.svg";
import Icon16 from "../assets/icons/icon-16.svg";
import Icon17 from "../assets/icons/icon-17.svg";
import Icon18 from "../assets/icons/icon-18.svg";
import Icon19 from "../assets/icons/icon-19.svg";
import Icon20 from "../assets/icons/icon-20.svg";
import Icon21 from "../assets/icons/icon-21.svg";
import Icon22 from "../assets/icons/icon-22.svg";
import Icon23 from "../assets/icons/icon-23.svg";
import Icon24 from "../assets/icons/icon-24.svg";
import Icon30 from "../assets/icons/icon-30.svg";
import Icon31 from "../assets/icons/icon-31.svg";
import Icon35 from "../assets/icons/icon-35.svg";
import Icon36 from "../assets/icons/icon-36.svg";
import Icon37 from "../assets/icons/icon-37.svg";
import Icon38 from "../assets/icons/icon-38.svg";
import Icon39 from "../assets/icons/icon-39.svg";
import Pic1 from "../assets/images/pic-1.png";
import Pic2 from "../assets/images/pic-2.png";
import Pic3 from "../assets/images/pic-3.png";
import Pic4 from "../assets/images/pic-4.png";
import Pic5 from "../assets/images/pic-5.png";
import Pic6 from "../assets/images/pic-6.png";
import Pic7 from "../assets/images/pic-7.png";
import Pic8 from "../assets/images/pic-8.png";
import Pic9 from "../assets/images/pic-9.png";
import Pic10 from "../assets/images/pic-10.png";
import Pic11 from "../assets/images/pic-11.svg";
import Pic14 from "../assets/images/pic-14.svg";
import Pic15 from "../assets/images/pic-15.png";
import Pic16 from "../assets/images/pic-16.svg";
import Pic17 from "../assets/images/pic-17.svg";
import Pic175 from "../assets/images/pic-17-5.svg";
import Pic18 from "../assets/images/pic-18.svg";
import Pic19 from "../assets/images/pic-19.jpg";
import Pic20 from "../assets/images/pic-20.jpg";
import Pic21 from "../assets/images/pic-21.png";
import Pic22 from "../assets/images/pic-22.png";
import Pic26 from "../assets/images/pic-26.svg";
import Pic27 from "../assets/images/pic-27.svg";
import Pic28 from "../assets/images/pic-28.svg";
import Pic29 from "../assets/images/pic-29.svg";
import Pic30 from "../assets/images/pic-30.svg";
import Pic31 from "../assets/images/pic-31.svg";
import CalendarBg from "../assets/images/pomyannik-calendar-uzor.svg";
import ChurchDateUzor from "../assets/images/pomyannik-church-date-uzor.svg";
import FonGreen from "../assets/images/fon-date-green.svg";
import FonGray from "../assets/images/fon-date-grey.svg";
import Shadow from "../assets/images/shadow.png";

export const IMAGES = {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Pic175,
  Icon18,
  Icon19,
  Icon20,
  Icon21,
  Icon22,
  Icon23,
  Icon24,
  Icon30,
  Icon31,
  Icon35,
  Icon36,
  Icon37,
  Icon38,
  Icon39,
  Pic1,
  Pic2,
  Pic3,
  Pic4,
  Pic5,
  Pic6,
  Pic7,
  Pic8,
  Pic9,
  Pic10,
  Pic11,
  // Pic12,
  Pic14,
  Pic15,
  Pic16,
  Pic17,
  Pic18,
  Pic19,
  Pic20,
  Pic21,
  Pic22,
  Pic26,
  Pic27,
  Pic28,
  Pic29,
  Pic30,
  Pic31,
  CalendarBg,
  ChurchDateUzor,
  FonGreen,
  FonGray,
  Shadow
};
