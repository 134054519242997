import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop(props) {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{props.children}</>;
}
