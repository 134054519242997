import React, {useContext, useState} from "react";
import {IMAGES} from "../../constants/images";
import moment from "moment";
import api from "../../api";
import "moment/locale/ru";
import {baseUrl} from "../../utils/helpers";
import {UserContext} from "../../context/user";
import {useToasts} from "react-toast-notifications";
import Load from "../../components/load/Load";

const HistoryItem = ({note, refresh}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {addToast} = useToasts();
  const SUser = useContext(UserContext);

  const isRest = () => note.type === "REST";
  const date = moment(note.created_at).format("DD MMMM YYYY");
  let contentText = "";

  JSON.parse(note.content).forEach((item) => {
    contentText += item + ", ";
  });

  contentText = contentText.substring(0, contentText.length - 2);

  const handleOpenModalPay = () => {
    let typeNotice = "draft";

    SUser.setShowModalPay((prevState) => ({
      ...prevState,
      type: note.type,
      typeNotice,
      show: true,
      price: note.price,
      invoice: note.id,
    }));
  };

  const repeatNotice = async () => {
    setIsLoading(true);

    const data = {
      type: note.type,
      content: note.content,
    };

    let typeNotice = "draft";

    if (note.church_date_id !== null) {
      data.church_date_id = note.church_date_id;
      // typeNotice = "draft";
    }
    if (note.notice_id !== null) {
      data.notice_id = note.notice_id;
      // typeNotice = "draft";
    }

    await api
      .postNote(data)
      .then(async (response) => {
        refresh();

        SUser.setShowModalPay((prevState) => ({
          ...prevState,
          type: note.type,
          typeNotice,
          show: true,
          price: response.note.price,
          invoice: response.note.id,
        }));
      })
      .catch((error) => {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });

        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      key={note.id}
      className={"history " + (isRest() ? "history--rest" : "")}
    >
      <div className="history__content">
        <div className="history__header">
          <span className={"title " + (isRest() ? "title--rest" : "")}>
            {!isRest() ? "О здравии" : "Об упокоении"}
          </span>
          <span className={"date " + (isRest() ? "date--rest" : "")}>
            {date}
          </span>
        </div>

        <div className="history__body">
          {note.notice !== null && note.church_date_id === null && (
            <>
              <div
                className={
                  "history__image-block " +
                  (isRest() ? "history__image-block--rest" : "")
                }
              >
                {note.notice.img ? (
                  <img
                    className="history__image"
                    src={`${baseUrl}storage/${note.notice?.img}`}
                    alt={note.notice.firstname}
                    width="60"
                    height="60"
                  />
                ) : isRest() ? (
                  <img
                    src={IMAGES.Pic20}
                    alt="Нет фото"
                  />
                ) : (
                  <img
                    src={IMAGES.Pic19}
                    alt="Нет фото"
                  />
                )}
              </div>

              <div className="history__info">
                <div
                  className={
                    "history__name " + (isRest() ? "history__name--rest" : "")
                  }
                >
                  {note.notice.firstname}{" "}
                  {note.notice.lastname && (
                    <strong>{note.notice.lastname}</strong>
                  )}
                </div>
                <div className="event-info__block">
                  {isRest() ? (
                    <img
                      src={IMAGES.Icon19}
                      alt="тэг"
                      className="event-info__image"
                    />
                  ) : (
                    <img
                      src={IMAGES.Icon15}
                      alt="тэг"
                      className="event-info__image"
                    />
                  )}
                  <p
                    className={
                      "event-info__text " +
                      (isRest() === "REST" ? "event-info__text--rest" : "")
                    }
                  >
                    {note.notice?.category.title}
                  </p>
                </div>
              </div>
            </>
          )}

          {note.church_date_id !== null && (
            <>
              <div
                className={
                  "history__image-block " +
                  (isRest() ? "history__image-block--rest" : "")
                }
              >
                {note.church_date?.img3 !== null ? (
                  <img
                    src={`${baseUrl}storage/${note.church_date?.img3}`}
                    className="history__image"
                    width="60"
                    height="60"
                    alt="Фото"
                  />
                ) : (
                  <img
                    src={IMAGES.Pic19}
                    className="history__image"
                    width="60"
                    height="60"
                    alt=""
                  />
                )}
                {/*<img src="https://picsum.photos/500/500"*/}
                {/*     className="history__image"*/}
                {/*     width="60" height="60" alt=""/>*/}
              </div>
              <div className="history__info">
                <div
                  className={
                    "history__name " + (isRest() ? "history__name--rest" : "")
                  }
                >
                  <strong>{note.church_date?.title}</strong>
                </div>
                <p className="history__content-text">{contentText}</p>
              </div>
            </>
          )}

          {note.notice_id === null && note.church_date_id === null && (
            <p className="history__content-text">{contentText}</p>
          )}
        </div>

        <div className="history__footer">
          <div className={"price mb-10 " + (isRest() ? "price--rest" : "")}>
            <span className="price__total">{note.price}</span>
            <span className="price__rub">₽</span>
          </div>
          {note.status === 0 ? (
            <button
              onClick={() => handleOpenModalPay()}
              className={
                "button history__button mb-10 " +
                (isRest() ? "history__button--rest" : "")
              }
            >
              <span>Оплатить</span>
            </button>
          ) : (
            <button
              onClick={() => repeatNotice()}
              className={
                "button history__button mb-10 " +
                (isRest() ? "history__button--rest" : "")
              }
            >
              {!isLoading && <span>Повторить</span>}

              {isLoading && (
                <div style={{height: "17px"}}>
                  <Load />
                </div>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryItem;
