import React from "react";
import {IMAGES} from "../../constants/images";
import {Link} from "react-router-dom";

const LinkApp = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <a
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.nativememo&hl=en%2F"
          target="_blank"
          className="link-app">
          <picture>
            <source srcSet={IMAGES.Pic27} media="(max-width: 567.9px)"/>
            <img src={IMAGES.Pic26} alt=""/>
          </picture>
        </a>
      </div>
    </div>
  );
};

export default LinkApp;