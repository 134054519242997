import React from "react";
import {motion} from "framer-motion";
import {useLocation} from "react-router-dom";

const PageTransition = ({children, top}) => {

  const location = useLocation();


  const linksHideEclipse = ["notice-health-create", "notice-rest-create",
    "notice-health-edit", "notice-rest-edit",
    "calendar", "", "church-date", "privacy"];

  // const linksIgnorTransition = ["history","categories","data","notifications","support"];
  const linksIgnorTransition = [];

  const AnimationSettings = (location) => {
    let _location = location?.split("/")[1];
    if (linksIgnorTransition.indexOf(_location) === -1) {
      // console.log('if')
      return {
        transition: {duration: 0.25},
        initial: {opacity: 0, y: 3, scale: 0.99},
        animate: {opacity: 1, y: 0, scale: 1},
        exit: {opacity: 0, y: -3, scale: 0.99}
      };
    } else {
      // console.log('else')
      return {};
    }
  };


  const hideEclipse = (location) => {
    let _location = location?.split("/")[1];
    return linksHideEclipse.indexOf(_location) !== -1;
  };

  return (
    <motion.div className="route-container" key="1" {...AnimationSettings(location.pathname)}>
      <div
        className={"elipse " + (hideEclipse(location.pathname) ? "elipse--hide" : "")}
        style={{top: top}}/>
      {children}
    </motion.div>
  );
};


export default PageTransition;
