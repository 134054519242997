import React from "react";
import {IMAGES} from "../../../constants/images";
import {Link} from "react-router-dom";

const ModalErrorNote = () => {
  return (
    <div className="modal-access-note">
      <div className="modal-access-note__content">
        <div className="note-content">
          <p className="modal-access-note__sub-title">
            Записка не оплачена, повторите попытку позже
          </p>
          <div className="modal-access-note__image-block">
            <img
              src={IMAGES.Pic175}
              className="modal-access-note__image"
              width="161"
              height="167px"
            />
          </div>
        </div>
      </div>

      <div className="modal-note__footer modal-window__footer flex-md-row flex-column">
        <Link to="/history" className="button button--t2 modal-window__button">
          <span>К истории</span>
        </Link>
        <Link to="/" className="button button--t2 modal-window__button">
          <span>На главную</span>
        </Link>
      </div>
    </div>
  );
};

export default ModalErrorNote;
