import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import OverlayScroll from "../components/OverlayScroll";
import {parseLink} from "../utils/helpers";
import {IMAGES} from "../constants/images";

const ListLayout = ({children}) => {
  const [activeLink, setActiveLink] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(()=>{
    setActiveLink(parseLink())
  },[])

  return (
    <OverlayScroll>
      <section className="container-fluid">
        <div className="container">
          <nav
            className={"navigation " + (open ? "navigation--open" : "")}
            onClick={e=> setOpen(!open)}
          >
            <div className="mobile-link-area">
              {activeLink}
              <img src={IMAGES.Icon30} alt="Открыть"
                   className="select-categories-area__icon" />
            </div>
            <ul className="navigation__list">
              <li className="navigation__item">
                <NavLink to="/birthdays"
                         className="navigation__link navigation__link--rest"
                >
                  Дни Рождения
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink to="/restdays"
                         className="navigation__link  navigation__link--rest">
                  Памятные даты
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        {children}
      </section>
    </OverlayScroll>
  );
};

export default ListLayout;
