import MainRouter from "./MainRouter";
import {UserContextProvider} from "./context/user";
import {NoticesContextProvider} from "./context/notices";
import {CategoriesContextProvider} from "./context/categories";
import {ListsContextProvider} from "./context/lists";
import {ChurchDatesContextProvider} from "./context/church_dates";
import {HelperContextProvider} from "./context/helper";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <HelperContextProvider>
      <UserContextProvider>
        <ListsContextProvider>
          <NoticesContextProvider>
            <CategoriesContextProvider>
              <ChurchDatesContextProvider>
                <BrowserRouter>
                  <MainRouter/>
                </BrowserRouter>
              </ChurchDatesContextProvider>
            </CategoriesContextProvider>
          </NoticesContextProvider>
        </ListsContextProvider>
      </UserContextProvider>
    </HelperContextProvider>
  );
}

export default App;
