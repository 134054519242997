import React, {useContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import {UserContext} from "../../context/user";
import api from "../../api";
import axios from "axios";
import Load from "../../components/load/Load";
import {defaultDescription} from "../../utils/helpers";
import MetaTags from "react-meta-tags";

const DataPasswordPage = ({layout: Layout}) => {
  const SUser = useContext(UserContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fioErrors, setFioErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});

  useEffect(() => {
    if (isLoading && SUser.user && email !== SUser.user.email) {
      setEmail(SUser.user.email);
      setName(SUser.user.name);
      setIsLoading(false);
    }
  }, [SUser.user]);

  const logout = () => {
    localStorage.removeItem("token");
    axios.defaults.headers.common["Authorization"] = null;
    SUser.setUser(null);
    navigate("/");
  };

  const submitChangeName = async (e) => {
    try {
      e.preventDefault();
      setFioErrors({});
      setIsLoading(true);

      await api.postMe({
        name,
      });

      SUser.setUser({...SUser.user, name});
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        setFioErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setFioErrors({message: error?.response?.data?.message});
      } else {
        console.log(error.message);
      }

      setIsLoading(false);
    }
  };

  const submitChangePassword = async (e) => {
    try {
      e.preventDefault();
      setPasswordErrors({});
      setIsLoading(true);

      await api.changePassword({
        password,
        new_password: newPassword,
      });

      setIsLoading(false);
      setNewPassword("");
      setPassword("");
    } catch (error) {
      if (error?.response?.data?.error) {
        setPasswordErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setPasswordErrors({message: error?.response?.data?.message});
      } else {
        console.log(error.message);
      }

      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area view-block-size">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <div className="draft-area__content data-password-content">
                <div className="history-scroll">
                  <form onSubmit={(e) => submitChangeName(e)}>
                    <div
                      className="input-container mb-10"
                      title="Email запрещено редактировать "
                    >
                      <input
                        name="email"
                        value={email}
                        disabled={true}
                        type="text"
                        className="input"
                        placeholder="E-mail"
                      />
                    </div>

                    <div
                      className={`input-container${
                        SUser.user.source === "GOOGLE" ? " mb-10" : ""
                      }`}
                    >
                      <input
                        disabled={SUser.user.source === "GOOGLE"}
                        type="text"
                        className="input"
                        placeholder="ФИО"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />

                      <div
                        className={
                          "input-error " +
                          (fioErrors.name ? "input-error--open" : "")
                        }
                      >
                        {fioErrors.name}
                      </div>
                    </div>

                    {SUser.user.source !== "GOOGLE" && (
                      <div className="event__buttons">
                        <button
                          type="submit"
                          className="button button--t2 event__button-create"
                          disabled={
                            isLoading ||
                            name.length < 3 ||
                            SUser.user.name === name
                          }
                        >
                          {!isLoading && <span>Сохранить</span>}
                          {isLoading && <Load />}
                        </button>
                      </div>
                    )}
                  </form>

                  {SUser.user.source !== "GOOGLE" && (
                    <form onSubmit={(e) => submitChangePassword(e)}>
                      <div className="input-container mb-10">
                        <input
                          type="password"
                          className="input"
                          placeholder="Старый пароль"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <div
                          className={
                            "input-error " +
                            (passwordErrors.password ? "input-error--open" : "")
                          }
                        >
                          {passwordErrors.password}
                        </div>
                      </div>
                      <div className="input-container">
                        <input
                          type="password"
                          className="input"
                          placeholder="Новый пароль"
                          name="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />

                        <div
                          className={
                            "input-error " +
                            (passwordErrors.new_password
                              ? "input-error--open"
                              : "")
                          }
                        >
                          {passwordErrors.new_password}
                        </div>

                        <div
                          className={
                            "input-error " +
                            (passwordErrors.message ? "input-error--open" : "")
                          }
                        >
                          {passwordErrors.message}
                        </div>
                      </div>
                      <div className="event__buttons">
                        <button
                          type="submit"
                          className="button button--t2 event__button-create"
                          disabled={
                            isLoading ||
                            password.length < 6 ||
                            newPassword.length < 6
                          }
                        >
                          {!isLoading && <span>Сохранить пароль</span>}
                          {isLoading && <Load />}
                        </button>
                      </div>
                    </form>
                  )}

                  <div
                    className={`event__buttons ${
                      SUser.user.source === "GOOGLE" ? "mt-60" : "mt-0"
                    }`}
                  >
                    <button
                      onClick={() => logout()}
                      className="button button--t2 event__button-create"
                    >
                      <span>Выйти</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DataPasswordPage;
