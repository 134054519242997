import React, {useEffect, useState} from "react";
import {IMAGES} from "../../constants/images";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import api from "../../api";
import Load from "../load/Load";
import moment from "moment";
import "moment/locale/ru";
import {baseUrl, unzeroDay} from "../../utils/helpers";

const NearChurchDate = () => {
  const [churchDates, setChurchDates] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    try {
      const res = await api.getNearChurchDates(moment().format("YYYY-MM-DD"));
      setChurchDates(res.church_dates);
      // setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // const resize = () => {
  //   window.addEventListener("resize", () => {
  //     // setIsLoading(true);
  //   });
  //
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500);
  // };
  // resize();

  useEffect(() => {
    init();
  }, []);

  return (
    <section className="container-fluid section-near-holiday">
      <div className="container">
        <div className="title-block section-near-holiday__title-block">
          <h2 className="title section-near-holiday__title">
            Ближайшие праздники
          </h2>
          <img src={IMAGES.Icon7} alt="Иконка"/>
        </div>
        <div className="near-holiday-slider-area">
          {/*{isLoading ? (*/}
          {/*  <div className="d-flex justify-content-center">*/}
          {/*    <Load />*/}
          {/*  </div>*/}
          {/*) : (*/}
          <Swiper
            // freeMode={true}
            spaceBetween={20}
            // slidesPerView={3.5}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            loop={false}
            breakpoints={{
              0: {
                initialSlide: -1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true
              },
              400: {
                initialSlide: -1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true
              },
              500: {
                initialSlide: -1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true
              },
              600: {
                initialSlide: -1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true
              },
              700: {
                initialSlide: -1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: true
              },
              800: {
                initialSlide: 1,
                // slidesPerView: 3.5,
                spaceBetween: 20,
                centeredSlides: true
              },
              900: {
                initialSlide: 1,
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: true
              },
              992: {
                initialSlide: 0,
                slidesPerView: 3.5,
                spaceBetween: 20,
                centeredSlides: false
              },
              1920: {
                initialSlide: 0,
                slidesPerView: 3.5,
                spaceBetween: 20,
                centeredSlides: false
              }
            }}
          >
            {churchDates?.map((church, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link
                    to={`/church-date/${church.id}`}
                    className="near-slider"
                  >
                    <div className="near-slider__content">
                      <div className="near-slider__date">
                          <span
                            className={
                              "near-slider__num " +
                              (church.gage === "SMALL"
                                ? "near-slider__num--small"
                                : "")
                            }
                          >
                            {unzeroDay(moment(church.date).format("DD"))}
                          </span>
                        <span
                          className={
                            "near-slider__month " +
                            (church.gage === "SMALL"
                              ? "near-slider__month--small"
                              : "")
                          }
                        >
                            {unzeroDay(
                              moment(church.date)
                                .format("DD MMMM")
                                .split(" ")[1]
                            )}
                          </span>
                      </div>
                      <div className="near-slider__bottom">
                        <div className="near-slider__pre">
                            <span
                              className={
                                "near-slider__pre-text " +
                                (church.gage === "SMALL"
                                  ? "near-slider__pre-text--small"
                                  : "")
                              }
                            >
                              {church.pre}
                            </span>
                        </div>
                        <div className="near-slider__title">
                            <span
                              className={
                                "near-slider__title-text " +
                                (church.gage === "SMALL"
                                  ? "near-slider__title-text--small"
                                  : "")
                              }
                            >
                              {church.title}
                            </span>
                        </div>
                      </div>
                    </div>

                    <img
                      src={`${baseUrl}storage/${church.img2}`}
                      alt="Праздник"
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/*)}*/}
        </div>
      </div>
    </section>
  );
};

export default NearChurchDate;
