import {useState, useRef, useContext} from "react";
import useClickOutside from "../../hooks/useClickOutside";
import {ReactComponent as Icon28} from "../../assets/icons/icon-28.svg";
import {ReactComponent as Icon29} from "../../assets/icons/icon-29.svg";
import {ReactComponent as Icon25} from "../../assets/icons/icon-25.svg";
import {CategoriesContext} from "../../context/categories";
import {useToasts} from "react-toast-notifications";

const Category = ({category}) => {
  const [isOpen, setIsOpen] = useState(false);
  const SCategories = useContext(CategoriesContext);
  const toogle = useRef();

  useClickOutside(toogle, () => setIsOpen(false));

  const handleEditCategory =(category)=>{
    setIsOpen(false)
    SCategories.setModalEditCategory(true)
    SCategories.setEditCategory(category)
  }
  const handleDeleteCategory=(category)=>{
    setIsOpen(false)
    SCategories.setModalDeleteCategory(true)
    SCategories.setEditCategory(category)
  }

  return (
    <div className="category">
      <div className="category__content">
        <span className="category__name">{category.title}</span>

        <div className="category__info">
          <div className="category__count">
            <span>{category.count} записей</span>
          </div>

          {category.type === 'PRIVATE'
          &&
          <div
            className="category__dots"
            style={isOpen ? {zIndex: 2} : {zIndex: 1}}
          >
            {isOpen && (
              <div className="drop-window" ref={toogle}>
                <button
                  role="button"
                  tabIndex={1}
                  className="button category__content category__toggle"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="category__dot" />
                  <span className="category__dot" />
                  <span className="category__dot" />
                </button>

                <div className="drop-window__content">
                  <button
                    onClick={()=>handleEditCategory(category)}
                    type="button"
                    className="button drop-window__button">
                    <div className="drop-window__button-icon-area">
                      <Icon28 className="drop-window__button-icon " />
                    </div>
                    <span>Редактировать</span>
                  </button>
                  <button
                    onClick={()=>handleDeleteCategory(category)}
                    type="button"
                    className="button drop-window__button">
                    <div className="drop-window__button-icon-area">
                      <Icon29 className="drop-window__button-icon " />
                    </div>
                    <span>Удалить</span>
                  </button>
                </div>
              </div>
            )}
            <button
              role="button"
              tabIndex={1}
              className="button category__content category__toggle"
              onClick={() => setIsOpen(true)}
            >
              <span className="category__dot" />
              <span className="category__dot" />
              <span className="category__dot" />
            </button>
          </div>
          }

        </div>
      </div>
    </div>
  );
};

export default Category;
