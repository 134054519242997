import React from "react";
import {IMAGES} from "../../../constants/images";
import {useState} from "react";
import api from "../../../api";
import Load from "../../load/Load";
import {useToasts} from "react-toast-notifications";

const Reset = ({setShow}) => {
  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [isKeySended, setisKeySended] = useState(false);
  const [email, setEmail] = useState("");
  const [key, setKey] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const sendKey = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setErrors({});

      await api.passwordRecovery(email);

      setisKeySended(true);
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setErrors({message: error?.response?.data?.message});
      } else {
        console.log(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setErrors({});

      await api.passwordRecoveryConfirmReset({
        key,
        password,
      });

      setEmail("");
      setKey("");
      setPassword("");
      setisKeySended(false);

      setShow(false);
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setErrors({message: error?.response?.data?.message});
      } else {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });
      }

      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => (!isKeySended ? sendKey(e) : resetPassword(e))}
      className="modal-window"
    >
      <div className="modal-main ">
        <button
          type="button"
          className="button modal-window__button-close"
          onClick={() => setShow(false)}
        >
          <img src={IMAGES.Icon5} alt="" className="m-0" />
        </button>
        <div className="modal-window__content">
          <div className="modal-window__header">
            <h2 className="modal-window__title">Восстановить пароль</h2>
          </div>
          <div className="modal-window__body">
            <div className="input-container mb-15 ">
              <input
                disabled={isKeySended}
                type="text"
                name="email"
                className="input"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className={
                  "input-error " + (errors.email ? "input-error--open" : "")
                }
              >
                {errors.email}
              </div>
            </div>

            {isKeySended && (
              <div className="input-container mb-15 ">
                <input
                  type="text"
                  className="input"
                  placeholder="Код подтверждения"
                  name="key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                />
                <div
                  className={
                    "input-error " + (errors.key ? "input-error--open" : "")
                  }
                >
                  {errors.key}
                </div>
              </div>
            )}

            {isKeySended && (
              <div className="input-container mb-15 ">
                <input
                  type="text"
                  className="input"
                  placeholder="Новый пароль"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div
                  className={
                    "input-error " +
                    (errors.password ? "input-error--open" : "")
                  }
                >
                  {errors.password}
                </div>

                <div
                  className={
                    "input-error " + (errors.message ? "input-error--open" : "")
                  }
                >
                  {errors.message}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="modal-note__footer modal-window__footer">
          <button
            type="submit"
            className="button button--t2 modal-window__button login-modal-button"
            disabled={isLoading || !email}
          >
            {!isLoading && !isKeySended && <span>Отправить код</span>}
            {!isLoading && isKeySended && <span>Сохранить пароль</span>}
            {isLoading && <Load />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Reset;
