import React from "react";
import Navigation from "../components/navigation/Navigation";
import OverlayScroll from "../components/OverlayScroll";

const ProfileLayout = ({children}) => {
  return (
    <>
      <OverlayScroll>
      <Navigation
        style={false}
        links={false}
      />
      {children}
      </OverlayScroll>
    </>
  );
};

export default ProfileLayout;
