import {baseUrl} from "../../utils/helpers";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

const ChurchDate = ({churchDates, handleClick}) => {
  const printChurchDate = (cd) => {
    return (
      <Link
        key={cd?.id}
        to={`/church-date/${cd?.id}`}
        onClick={() => handleClick(cd)}
        className="church-date-mini"
      >
        <div className="church-date-mini__bottom">
          <div className="church-date-mini__pre">
            <span
              className={
                "church-date-mini__pre-text " +
                (cd?.gage === "SMALL"
                  ? "church-date-mini__pre-text--small"
                  : "")
              }
            >
              {cd?.pre}
            </span>
          </div>
          <div className="church-date-mini__title">
            <span
              className={
                "church-date-mini__title-text " +
                (cd?.gage === "SMALL"
                  ? "church-date-mini__title-text--small"
                  : "")
              }
            >
              {cd?.title}
            </span>
          </div>
        </div>

        <img
          className="church-date-mini__bg"
          loading="lazy"
          src={`${baseUrl}storage/${cd?.img1}`}
          alt={cd.title}
        />
      </Link>
    );
  };

  if (churchDates.length === 1) {
    return printChurchDate(churchDates[0]);
  }

  if (churchDates.length > 1) {
    const cdsSwiper = churchDates.map((churchDate) => {
      return (
        <SwiperSlide key={churchDate?.id}>
          {printChurchDate(churchDate)}
        </SwiperSlide>
      );
    });

    return (
      <>
        <Swiper
          modules={[Pagination]}
          freeMode={true}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{clickable: true}}
        >
          {cdsSwiper}
        </Swiper>
      </>
    );
  }
};

export default ChurchDate;
