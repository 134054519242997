import NoticeItem from "../notice/NoticeItem";

const CalendarItemsN = ({ns}) => {
  const nsList = ns.map((notice) => {
    return <NoticeItem key={notice.id} notice={notice} className="notice-item--grid" />;
  });

  return <div className="notices-grid">{nsList}</div>;
};

export default CalendarItemsN;
