import {useState, useContext} from "react";
import axios from "axios";
import api from "../../../api";
import {IMAGES} from "../../../constants/images";
import {UserContext} from "../../../context/user";
import {useNavigate} from "react-router-dom";
import GoogleSignin from "../../google-signin/GoogleSignin";
import {HASH} from "../../../constants/hash";
import {Link} from "react-router-dom";
import Load from "../../load/Load";
import {useToasts} from "react-toast-notifications";

const Login = ({setShow}) => {
  const {addToast} = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const SUser = useContext(UserContext);
  const navigate = useNavigate();

  const submitLogin = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setErrors({});

      const user = await api.login({
        email,
        password,
      });

      setEmail("");
      setPassword("");

      localStorage.removeItem("__local_content--HEALTH");
      localStorage.removeItem("__local_content--REST");

      localStorage.setItem("token", user.user.token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.user.token}`;
      SUser.setUser(user.user);

      navigate("/");
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrors(error?.response?.data?.error);
      }
      if (error?.response?.data?.message) {
        setErrors({message: error?.response?.data?.message});
      } else {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });
      }

      console.log("error", error);

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={(e) => submitLogin(e)} className="modal-window modal-login">
      <div className="modal-main ">
        <button
          type="button"
          className="button modal-window__button-close"
          onClick={() => setShow(false)}
        >
          <img src={IMAGES.Icon5} alt="" className="m-0" />
        </button>
        <div className="modal-window__content">
          <div className="modal-window__header">
            <h2 className="modal-window__title">Вход на сайт</h2>
          </div>
          <div className="modal-window__body">
            <div className="input-container">
              <input
                type="text"
                className="input"
                placeholder="E-mail"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className={
                  "input-error " + (errors.email ? "input-error--open" : "")
                }
              >
                {errors.email}
              </div>
            </div>

            <div className="input-container">
              <input
                type="password"
                className="input"
                placeholder="Пароль"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div
                className={
                  "input-error " + (errors.password ? "input-error--open" : "")
                }
              >
                {errors.password}
              </div>

              <div
                className={
                  "input-error " + (errors.message ? "input-error--open" : "")
                }
              >
                {errors.message}
              </div>

              <Link className="input-link" to={HASH.RESET}>
                Забыли пароль?
              </Link>
            </div>
            <div className="modal-login__buttons">
              <button
                type="submit"
                className="button button--t2 modal-window__button login-modal-button"
                disabled={!(email && password)}
              >
                {!isLoading && <span>Войти</span>}
                {isLoading && <Load />}
              </button>

              <GoogleSignin />
            </div>

          </div>
        </div>

        <div className="modal-note__footer modal-window__footer flex-column">

          <Link to={HASH.SIGNUP}
            className="button button--t5 modal-login__button">
            <span>Зарегистрироваться</span>
          </Link>

        </div>
      </div>
    </form>
  );
};

export default Login;
