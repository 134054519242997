import React, {useContext} from "react";
import {IMAGES} from "../../constants/images";
import {UserContext} from "../../context/user";
import PayContent from "./modal-note/PayContent";

const ModalPay = ({setShow}) => {
  const SUser = useContext(UserContext);
  const type = SUser.showModalPay?.type;

  return (
      <PayContent type={type} setShow={setShow}/>
  );
};

export default ModalPay;
