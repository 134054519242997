import React, {useContext, useState} from "react";
import {NoticesContext} from "../../context/notices";
import Datetime from "react-datetime";
import api from "../../api";
import Load from "../load/Load";
import SelectCategory from "./SelectCategory";
import {useToasts} from "react-toast-notifications";
import {Link, useNavigate} from "react-router-dom";
import NoticePhoto from "./NoticePhoto";
import DatePickerComponent from "../date-picker/DatePickerComponent";
import {IMAGES} from "../../constants/images";
import NoticePhotoEdit from "./NoticePhotoEdit";

const Notice = ({type, edit}) => {
  const stateNotices = useContext(NoticesContext);
  const [isCropData, handleCropData] = useState(false);

  const isRest = () => type === "REST";

  const navigate = useNavigate();

  const {addToast} = useToasts();

  const Submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    stateNotices.setLoadingSuccess(true);
    stateNotices.setErrorFirstName("");
    stateNotices.setErrorLastName("");
    stateNotices.setErrorNoticeCategory("");
    stateNotices.setErrorDate1("");
    stateNotices.setErrorComment("");

    let formdata = new FormData();

    if (stateNotices.toggleCheckBox) {
      formdata.append("add_to_draft", 1);
    }

    formdata.append("firstname", stateNotices.firstName);
    if (stateNotices.lastName) formdata.append("lastname", stateNotices.lastName);
    formdata.append("notice_category_id", stateNotices.noticeCategory?.id);
    formdata.append("date1", Datetime.moment(stateNotices.date1).format("YYYY-MM-DD"));

    if (isCropData) {
      formdata.append("file_base64", stateNotices.file);
    } else if (edit) {
      formdata.append("img", stateNotices.file);
    }

    if (stateNotices.comment && stateNotices.comment !== 'null') formdata.append("comment", stateNotices.comment);

    if (isRest()) {
      formdata.append("date2", Datetime.moment(stateNotices.date2).format("YYYY-MM-DD"));
    }

    let func = !edit ? api.createNotice(formdata) : api.updateNotice(stateNotices.id, formdata);//либо сохраняем событие или создаем

    func.then(() => {
      {
        isRest()
          ?
          navigate("/restdays")
          :
          navigate("/birthdays");
      }

      stateNotices.ResetStateNotices();
      let message = !edit ? "Событие создано" : "Изменения сохранены";
      addToast(message, {
        id: "GOOD",
        appearance: "GOOD",
        autoDismiss: true
      });

    }).catch(e => {
      console.log("Submit Event e.response ", e.response);
      stateNotices.setErrorFirstName(e?.response?.data?.error?.firstname);
      stateNotices.setErrorLastName(e?.response?.data?.error?.lastname);
      stateNotices.setErrorNoticeCategory(e?.response?.data?.error?.notice_category_id);
      stateNotices.setErrorDate1(e?.response?.data?.error?.date1);
      stateNotices.setErrorDate2(e?.response?.data?.error?.date2);
      stateNotices.setErrorComment(e?.response?.data?.error?.comment);

      if (e?.response?.data?.message) {
        addToast(e?.response?.data?.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });
      }

    }).finally(() => {
      stateNotices.setLoadingSuccess(false);
    });
  };

  const handleDate1 = () => {
    return edit ? Datetime.moment(stateNotices?.date1)._d : stateNotices?.date1;
  };

  const handleDate2 = () => {
    return edit ? Datetime.moment(stateNotices?.date2)._d : stateNotices?.date2;
  };


  return (
    <section className="container-fluid">
      <div className="container">

        <div className="event-area">
          {/*<h1 className="event-area__title">*/}
          {/*  День Рождения*/}
          {/*</h1>*/}
          <form className="event" onSubmit={Submit}>
            <div className="row">
              <div className="col-md-6 col-12">

                {!edit
                  ?
                  <NoticePhoto
                    handleCropData={handleCropData}
                    isRest={isRest}
                  />
                  :
                  <NoticePhotoEdit
                    handleCropData={handleCropData}
                    isRest={isRest}
                  />
                }

              </div>
              <div className="col-md-6 col-12">
                <div className="event__fields">
                  <div className="input-container mb-15">
                    <input type="text"
                          value={stateNotices.firstName}
                          onChange={e => stateNotices.setFirstName(e.target.value)}
                          className="input" placeholder="Имя"/>
                    <div
                      className={"input-error " + (stateNotices.errorFirstName ? "input-error--open" : "")}>{stateNotices.errorFirstName}</div>
                  </div>

                  <div className="input-container mb-15">
                    <input type="text"
                          onChange={e => stateNotices.setLastName(e.target.value)}
                          value={stateNotices.lastName}
                          className="input" placeholder="Фамилия"/>
                    <div
                      className={"input-error " + (stateNotices.errorLastName ? "input-error--open" : "")}>{stateNotices.errorLastName}</div>
                  </div>

                  <div className={"input-container-dates " + (isRest() ? "_rest" : "")}>
                    <div className="input-container mb-15">
                      <div className="date-picker-area">

                        <DatePickerComponent
                          placeholderText="День рождения"
                          dateFormat="d MMMM, yyyy"
                          selected={handleDate1()}
                          onChange={stateNotices?.setDate1}
                        />

                        <img className="date-picker-area__icon" src={IMAGES.Icon37} alt="Дата"/>
                      </div>

                      <div
                        className={"input-error " + (stateNotices.errorDate1 ? "input-error--open" : "")}>{stateNotices.errorDate1}</div>

                    </div>
                    {
                      isRest()
                      &&
                      <div className="input-container mb-15">
                        <div className="date-picker-area">

                          <DatePickerComponent
                            placeholderText="День памяти"
                            dateFormat="d MMMM, yyyy"
                            selected={handleDate2()}
                            onChange={stateNotices?.setDate2}
                          />
                          <img className="date-picker-area__icon" src={IMAGES.Icon37} alt="Дата"/>
                        </div>

                        <div
                          className={"input-error " + (stateNotices.errorDate2 ? "input-error--open" : "")}>
                          {stateNotices.errorDate2}
                        </div>

                      </div>
                    }

                  </div>

                  <div className="input-container mb-15">
                    <SelectCategory/>
                    <p className="input-container__text">
                      Друзья, Родственники, Родные...
                    </p>

                    <div
                      className={"input-error " + (stateNotices.errorNoticeCategory ? "input-error--open" : "")}>{stateNotices.errorNoticeCategory}</div>

                  </div>
                  <div className="input-container mb-15">
                    <textarea
                      onChange={e => stateNotices.setComment(e.target.value)}
                      value={!stateNotices.comment || stateNotices.comment === "null" ? " " : stateNotices?.comment}
                      className="textarea"
                      placeholder="Комментарий"/>
                    <p className="input-container__text">
                      Комментарий не более 120 символов
                    </p>

                    <div
                      className={"input-error " + (stateNotices.errorComment ? "input-error--open" : "")}>{stateNotices.errorComment}</div>

                  </div>
                  {!edit
                  &&
                  <div className="checkbox">
                    <label htmlFor="addName">
                      <div
                        className="checkbox__text d-flex align-items-center">
                        <input
                          onChange={() => stateNotices.setToggleCheckBox(!stateNotices.toggleCheckBox)}
                          checked={stateNotices.toggleCheckBox}
                          id="addName"
                          type="checkbox"
                          name="add_name"
                        />
                        <span className="checkbox__cr mr-10"/>
                        Добавить имя в Помянник
                      </div>
                    </label>
                  </div>
                  }
                </div>
                <div className="event__buttons d-flex flex-column event-buttons-edit">
                  <button
                    disabled={stateNotices.loadingSuccess}
                    className={"button event__button-create " + (isRest() ? " button--t3" : " button--t2")}
                    type="submit">
                    {stateNotices.loadingSuccess
                      ?
                      <Load/>
                      :
                      <span>
                        {edit
                          ?
                          "Сохранить"
                          :
                          "Создать событие"
                        }

                    </span>
                    }
                  </button>
                  <Link to="/" className="button button--t2 event__button-create">
                    <span>Отмена</span>
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </section>
  );
};

export default Notice;
