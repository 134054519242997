import React, {useEffect, useContext} from "react";
import {IMAGES} from "../../constants/images";
import {CategoriesContext} from "../../context/categories";
import Category from "../../components/category/Category";
import {useToasts} from "react-toast-notifications";
import Load from "../../components/load/Load";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton, defaultDescription} from "../../utils/helpers";
import MetaTags from "react-meta-tags";

const CategoriesPage = ({layout: Layout}) => {
  const SCategories = useContext(CategoriesContext);

  const {addToast} = useToasts();

  useEffect(() => {
    if (SCategories.loadingScreen) {
      SCategories.initCategory();
    }
  }, [SCategories.loadingScreen]);

  const categoryList = SCategories.categories.map((category) => {
    return <Category key={category.id} category={category} />;
  });

  const submit = async (e) => {
    e.preventDefault();
    await SCategories.createCategory(addToast);
  };

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area draft-area min-h">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <div className="draft-area__content categories-content">
                <div className="history-scroll">
                  <div className="categories">
                    {SCategories.loadingScreen ? (
                      <SkeletonTheme
                        baseColor={colorSkeleton.baseColor}
                        highlightColor={colorSkeleton.highlightColor}
                      >
                        <Skeleton
                          count={2}
                          containerClassName="skeleton "
                          className="skeleton-category"
                        />
                      </SkeletonTheme>
                    ) : (
                      categoryList
                    )}
                  </div>
                  <form onSubmit={submit} className="form-create-category">
                    <div className="input-container">
                      <input
                        value={SCategories.title}
                        type="text"
                        className="input "
                        placeholder="Название категории"
                        onChange={(e) => SCategories.setTitle(e.target.value)}
                      />
                      <p className="input-container__text">
                        Введите имя категории, которую хотите добавить
                      </p>
                    </div>
                    <div className="event__buttons">
                      <button
                        className="button button--t2 event__button-create"
                        type="submit"
                        disabled={
                          SCategories.title.length <= 3 ||
                          SCategories.loadingAddCategories
                        }
                      >
                        {SCategories.loadingAddCategories ? (
                          <Load />
                        ) : (
                          <span>Добавить категорию</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CategoriesPage;
