import React from "react";
import Notice from "../../components/notice/Notice";
import MetaTags from "react-meta-tags";
import {defaultDescription} from "../../utils/helpers";

const NoticeHealthCreatePage = ({layout: Layout}) => {
  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta
          name="description"
          content={defaultDescription}
        />
      </MetaTags>

      <Notice type={"REST"} edit={false} />
    </Layout>
  );
};

export default NoticeHealthCreatePage;
