import Datetime from "react-datetime";
import "moment-timezone";

// export const baseUrl = "https://laravel-memo.na4u.ru/";
export const baseUrl = "https://pomyannik.elitsy.ru/";
// export const baseUrl = "http://localhost:8000/";

// склонение слов
export const Declination = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const dayCalendar = (data) => {
  return {
    воскресенье: "ВС",
    понедельник: "ПН",
    вторник: "ВТ",
    среда: "СР",
    четверг: "ЧТ",
    пятница: "ПТ",
    суббота: "СБ",
  }[data];
};

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  const theDate = Datetime.moment(startDate);
  while (theDate <= endDate) {
    dates = [...dates, Datetime.moment(theDate).format("YYYY-MM-DD")];
    theDate.add(1, "day");
  }
  return dates;
};

export const unzeroDay = (day) => {
  if (day[0] === "0") return day.slice(1, day.length);
  return day;
};

export const wordGradient = (count, words) => {
  // склонения слов
  const cases = [2, 0, 1, 1, 1, 2];
  return words[
    count % 100 > 4 && count % 100 < 20
      ? 2
      : cases[count % 10 < 5 ? count % 10 : 5]
  ];
};

export const colorSkeleton = {
  baseColor: "#EEF0F5",
  highlightColor: "#fff",
};

export const parseLink = () => {
  const links = document.querySelectorAll(".navigation__link");
  for (let link of links) {
    if (link.classList.contains("active")) {
      return link.textContent;
    }
  }
};

export const greatDates = [
  161, 162, 163, 164, 175, 176, 180, 181, 182, 183, 186, 188, 189, 190, 191,
  194, 196, 197,
];

export const defaultDescription = "Памятные даты и церковные праздники";
