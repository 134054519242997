import React, {useEffect, useState, createRef} from "react";

const Content = ({initialContent = null, type, onSave, onChange}) => {
  const [content, setContent] = useState([{id: 0, value: ""}]);
  const [saveTimout, setSaveTimeout] = useState(null);
  const [refs, setRefs] = useState([]);

  const _interval = 1000;

  useEffect(() => {
    if (initialContent === null) {
      setRefs([createRef()]);
      setContent([{id: 0, value: ""}]);
    } else {
      const newRefs = [];
      const newContent = [];

      console.log("initialContent", typeof initialContent);

      JSON.parse(initialContent).forEach((item, index) => {
        newContent.push({id: index, value: item});
        newRefs.push(createRef());
      });

      newContent.push({id: newContent.length, value: ""});
      newRefs.push(createRef());


      setRefs(newRefs);
      setContent(newContent);
    }
  }, [initialContent]);

  const changeItem = (value, id) => {
    const newRefs = [];
    const newContent = [];
    let saveInterval = 0;

    content.forEach((item, index) => {
      if (item.id === id) {
        if (value.length > 0 && item.value.length > 0) {
          // ничего не удаляем, не добавляем
          newRefs.push(refs[index]);
          newContent.push({...item, value});

          saveInterval = _interval;
        } else if (value.length > 0 && item.value.length === 0) {
          newContent.push({...item, value});

          // добавляем
          newRefs.push(createRef());
          newContent.push({id: content[content.length - 1].id + 1, value: ""});
          saveInterval = _interval;
        } else {
          saveInterval = _interval;
        }
      } else {
        newRefs.push(refs[index]);
        newContent.push(item);
      }
    });

    // setContent(newContent);

    if (saveInterval > 0) {
      saving(newContent, saveInterval);
    }
  };

  const timeToSave = (data) => {
    if (!onSave) {
      return;
    }
    onSave(data);

    clearTimeout(saveTimout);
    setSaveTimeout(null);
  };

  const saving = (items, saveInterval) => {
    const data = [];

    items.forEach((item) => {
      if (!!item.value) {
        data.push(item.value);
      }
    });

    if (saveTimout !== null) {
      clearTimeout(saveTimout);
    }
    const timeout = setTimeout(timeToSave, saveInterval, JSON.stringify(data));

    setSaveTimeout(timeout);
    onChange(JSON.stringify(data));
  };

  const handleEnter = (e, index) => {
    if (e.key === "Enter") {
      if((refs.length - 1) > index) {
        refs[index + 1]?.current?.focus();
      }
    }
  }

  const list = content.map((item, index) => {
    return (
      <div
        className={
          "input-container animation " +
          (index === content.length - 1 ? "open" : "mb-15 open")
        }
        key={item.id}
      >
        <input
          type="text"
          onKeyPress={(e) => handleEnter(e, index)}
          ref={refs[index]}
          value={item.value}
          className={"input " + (type === "REST" ? "input--rest" : "")}
          placeholder="Введите имя"
          onChange={(e) => changeItem(e.target.value, item.id)}
        />
      </div>
    );
  });

  return (
    <form className="note-content__form">
      <div className="note-content__form-scroll">
        {list}
        <p
          className={
            "input-container__text " +
            (type === "REST" ? "input-container__text--rest" : "")
          }
        >
          Имя пишется в Родительном падеже
        </p>
      </div>
    </form>
  );
};

export default Content;
