import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {authentication} from "../../firebase-config";
import {signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {IMAGES} from "../../constants/images";
import api from "../../api";
import {UserContext} from "../../context/user";

const GoogleSignin = () => {
  const SUser = useContext(UserContext);
  const navigate = useNavigate();

  const submitGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (userInfo) => {
        const data = {
          email: userInfo.user.email,
          name: userInfo.user.displayName,
          password: userInfo.user.uid,
        };

        const localContentHealth = localStorage.getItem("__local_content--HEALTH");
        const localContentRest = localStorage.getItem("__local_content--REST");

        if (localContentHealth) {
          data.health = localContentHealth;
        }
        if (localContentRest) {
          data.rest = localContentRest;
        }

        const user = await api.googleSignIn(data);

        localStorage.removeItem('__local_content--HEALTH');
        localStorage.removeItem('__local_content--REST');

        localStorage.setItem('token', user.user.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${user.user.token}`;
        SUser.setUser(user.user);

        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <button type="button" className="button button-google" onClick={() => submitGoogle()}>
      <img src={IMAGES.Icon6} alt="Google" />
      <span>Войти с помощью Google</span>
    </button>
  );
};

export default GoogleSignin;
