import React, {useContext, useState} from "react";
import {HelperContext} from "../context/helper";
import PageTransition from "./PageTransition";
import ReactDOM from "react-dom";

const OverlayScroll = ({children}) => {

  const SHelper = useContext(HelperContext);
  const [op, setOp] = useState(0);
  const [top, setTop] = useState(0);

  let _height = window.innerHeight - SHelper.sizeFooter - SHelper.sizeHeader
  let  routeContainer = document.querySelector('.route-container')

  document.addEventListener('scroll',function(e) {
    let _pos = window.pageYOffset
    let count = 0
    if(routeContainer?.getBoundingClientRect()?.height  >= _pos + 740){
      setTop(_pos)
    }
    if(_pos <= 200){
      count = count + (_pos / 100)
      setOp(count)
    }
  });

  return (
    <>
      <PageTransition top={top}>
        <div
          style={
            {
              // minHeight: `${_height}px`,
              paddingBottom: `${ SHelper.sizeFooter + (window.innerWidth <= 991.9 ? 10 : 30)}px`,
              // paddingTop: `${ SHelper.sizeHeader}px`
              paddingTop: `${+SHelper.sizeHeader + (window.innerWidth <= 991.9 ? 20 : 30)}px`,
              overflow: 'hidden'
            }
          }>
          {ReactDOM.createPortal(
            <div
              className="scroll-overlay"
              style={{opacity: op, top: SHelper.sizeHeader}}>
              <span className="scroll-overlay__bg"/>
              <span className="scroll-overlay__gr"/>
            </div>,
            document.getElementById('root')
          )}
        {/*<OverlayScrollbarsComponent*/}
        {/*  style={{*/}
        {/*    // minHeight: `${window.innerHeight}px`,*/}
        {/*    minHeight: `${_height}px`,*/}
        {/*    maxHeight: `${_height}px`,*/}
        {/*    // border: "1px solid red",*/}
        {/*    paddingBottom: "20px"*/}
        {/*  }}*/}

        {/*  options={*/}
        {/*    {*/}
        {/*      resize: "none",*/}
        {/*      className: " os-theme-dark custom-theme main-page-theme ",*/}
        {/*      scrollbars: {*/}
        {/*        snapHandle: true,*/}
        {/*        visibility: "auto",*/}
        {/*        autoHide: "never", //scroll //leave*/}
        {/*        autoHideDelay: 50*/}
        {/*      },*/}
        {/*      callbacks: {*/}
        {/*        // onHostSizeChanged: function(e){*/}
        {/*        //   console.log(e)*/}
        {/*        // },*/}
        {/*        // onUpdated : function(e){*/}
        {/*        //   console.log('onUpdated ',e)*/}
        {/*        // },*/}
        {/*        onScroll: function(e) {*/}
        {/*          let _pos = e.target.scrollTop;*/}
        {/*          let count = 0;*/}
        {/*          if (_pos <= 200) {*/}
        {/*            count = count + (_pos / 100);*/}
        {/*            setOp(count);*/}
        {/*            if (op > defaultOpacity) {*/}
        {/*              setOp(defaultOpacity);*/}
        {/*            }*/}
        {/*          }*/}
        {/*        },*/}
        {/*        onScrollStop: function(e) {*/}
        {/*          if (op > defaultOpacity) {*/}
        {/*            setOp(defaultOpacity);*/}
        {/*          }*/}
        {/*        }*/}
        {/*      }*/}
        {/*    }*/}
        {/*  }*/}
        {/*>*/}

          {children}
        {/*</OverlayScrollbarsComponent>*/}
        </div>
      </PageTransition>
    </>
  );
};

export default OverlayScroll;
