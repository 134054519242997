import DraftContent from "../../components/draft/DraftContent";
import MetaTags from "react-meta-tags";
import {defaultDescription} from "../../utils/helpers";

const HealthPage = ({layout: Layout}) => {
  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <DraftContent type="HEALTH" />
    </Layout>
  );
};

export default HealthPage;
