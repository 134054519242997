import {IMAGES} from "../constants/images";
import NearChurchDate from "../components/near-church-date/NearChurchDate";
import CalendarMini from "../components/calendar-mini/CalendarMini";
import React, {useContext} from "react";
import OverlayScroll from "../components/OverlayScroll";
import {UserContext} from "../context/user";
import LinkApp from "../components/link-app/LinkApp";
import {Link} from "react-router-dom";
import {HASH} from "../constants/hash";
import MetaTags from "react-meta-tags";
import {defaultDescription} from "../utils/helpers";

const MainPage = () => {
  const SUser = useContext(UserContext);

  return (
    <OverlayScroll>
      <MetaTags>
        <title>Помянник</title>
        <meta
          name="description"
          content={defaultDescription}
        />
      </MetaTags>

      {SUser.user && (
        <section className="container-fluid">
          <CalendarMini />
        </section>
      )}

      {!SUser.user && (
        <>
          <section className="container-fluid section-main-banner">
            <div className="container">
              <h1 className="main-banner__title">
                Сохраняйте CВОИ памятные даты
              </h1>
              <div className="main-banner d-flex justify-content-between">
                <div className="main-banner__content">
                  <div className="main-banner__content-text">
                    <h2 className="main-banner__sub-title">Мы напомним!</h2>
                    <p className="main-banner__text">
                      Используйте помянник, чтобы сохранять напоминания о важных
                      для вас датах, всегда иметь под рукой значимые события
                      православного календаря и вовремя поздравлять близких вам
                      людей с именинами и праздниками. Сервис позволяет подать
                      записки на монастырские богослужения во дни памяти или
                      именины ваших близких и на церковные праздники.
                    </p>
                  </div>
                </div>
                <div className="main-banner__image-block">
                  <img src={IMAGES.Pic1} alt="Памятные даты" />
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid section-functional">
            <div className="container">
              <h2 className="main-banner__sub-title">Удобный функционал</h2>
              <div className="section-functional__list">
                <div className="functional-item">
                  <div className="functional-item__image-block">
                    <img src={IMAGES.Pic28} alt="" />
                  </div>
                  <span className="functional-item__text">
                    Церковный календарь
                    <br />с описанием праздников
                  </span>
                </div>
                <div className="functional-item">
                  <div className="functional-item__image-block">
                    <img src={IMAGES.Pic29} alt="" />
                  </div>
                  <span className="functional-item__text">Помянник</span>
                </div>
                <div className="functional-item">
                  <div className="functional-item__image-block">
                    <img src={IMAGES.Pic30} alt="" />
                  </div>
                  <span className="functional-item__text">
                    Календарь ваших
                    <br />
                    памятных дат
                  </span>
                </div>
                <div className="functional-item">
                  <div className="functional-item__image-block">
                    <img src={IMAGES.Pic31} alt="" />
                  </div>
                  <span className="functional-item__text">
                    Отправка записок
                    <br />в монастырь онлайн
                  </span>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {SUser.user && <NearChurchDate />}

      {!SUser.user && <LinkApp />}

      {!SUser.user && (
        <section className="container-fluid section-step">
          <div className="container">
            <h2 className="main-banner__sub-title">Простота использования</h2>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="step">
                  <img
                    src={IMAGES.Pic5}
                    alt=""
                    className="step__arrow-image step__arrow-image-step-1"
                  />
                  <div className="step__image-block">
                    <img src={IMAGES.Pic2} alt="Регистрация" />
                  </div>
                  <p className="step__text">
                    Быстрая
                    <br />
                    <strong>
                      <Link to={HASH.SIGNUP}>Регистрация</Link>
                    </strong>
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step">
                  <img
                    src={IMAGES.Pic6}
                    alt=""
                    className="step__arrow-image step__arrow-image-step-2"
                  />

                  <div className="step__image-block">
                    <img src={IMAGES.Pic3} alt="Создать дату" />
                  </div>
                  <p className="step__text">
                    Создавайте и сохраняйте
                    <br />
                    ваши памятные даты
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step">
                  <div className="step__image-block">
                    <img src={IMAGES.Pic4} alt="Подать записку" />
                  </div>
                  <p className="step__text">
                    Мы напомним вам
                    <br />о них заранее и вы сможете
                    <br />
                    подать записку в храм онлайн
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {!SUser.user && (
        <>
          <section className="container-fluid section-memo">
            <div className="container">
              <div className="memo">
                <img src={IMAGES.Pic8} alt="храм" className="memo__image" />
                <div className="memo__content">
                  <div className="title-block memo__title-block">
                    <h2 className="title">Мой помянник</h2>
                    <img src={IMAGES.Icon8} alt="Иконка" />
                  </div>
                  <p className="memo__text">
                    Внесите в помянник имена дорогих вам людей, чтобы
                    сформировать готовую записку для каждодневного молитвенного
                    поминовения, вывода на печать и подачи в храм.
                  </p>
                  <Link
                    to="/draft-health"
                    className="button button--t2 memo__button"
                  >
                    <span>СОЗДАТЬ помянник</span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <section
        className="container-fluid section-info"
        style={{marginBottom: "2rem"}}
      >
        <div className="container">
          <div className="d-flex title-block section-info__title-block">
            <div className="title section-info__title">
              <img src={IMAGES.Pic14} alt="" />
            </div>
            <img src={IMAGES.Icon9} alt="" />
          </div>
          <p className="section-info__text">
            Отправка записок в приложении Помянник осуществляется с помощью
            проверенного и надежного сервиса
            <strong> Елицы Записки</strong>. Требы исполняются на богослужениях
            в Свято-Троицком Серафимо-Дивеевском монастыре
          </p>
        </div>
      </section>
    </OverlayScroll>
  );
};

export default MainPage;
