import React, {useContext, useEffect, useLayoutEffect, useState} from "react";

const GetSize = (element) => {
  const [size, setSize] = useState(null);

  useLayoutEffect(() => {
    window.addEventListener("resize", ()=> {
      // console.log(element?.current?.clientHeight);
      setSize(element?.current?.clientHeight);
    });
    setSize(element?.current?.clientHeight);
  }, []);

  return size;
};

export default GetSize;
