import React, {createContext, useRef, useState, createRef} from "react";
import api from "../api";

export const ListsContext = createContext();

export const ListsContextProvider = ({children}) => {
  //загрузка для экрана при входе
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [data, setData] = useState(null);

  const [modalDeleteNotice, setModalDeleteNotice] = useState(false);
  const [deletingNotice, setDeletingNotice] = useState({});
  const [modalDeleteNoticeLoading, setModalDeleteNoticeLoading] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const initGetNoticesNoDate = async () => {
    setLoadingScreen(true);
    setData(null);
    await api.getNoticesNoDate().then(res => {
      setData(res.notices);
    }).catch(e => {
      console.log("init ListsContextProvider", e);
    }).finally(e => {
      // console.log("finally");
      setLoadingScreen(false);
    });
  };

  const deleteNotice = (addToast) => {
    setModalDeleteNoticeLoading(true);
    api.deleteNotice(deletingNotice?.id).then(res => {
      console.log("them");
      setDeleteId(deletingNotice?.id);
      setTimeout(() => {
        let newData = data.filter(item => item.id !== deletingNotice?.id);
        setData(newData);
        addToast("Событие удалено", {
          id: "GOOD",
          appearance: "GOOD",
          autoDismiss: true
        });
      },500);
    }).catch(e => {
      addToast(e.response.data.message, {
        id: "BAD",
        appearance: "BAD",
        autoDismiss: true
      });
      // console.log(e?.response?.data);
    }).finally(e => {
      setModalDeleteNoticeLoading(false);
      setModalDeleteNotice(false);
    });
  };
  return (
    <ListsContext.Provider
      value={{
        loadingScreen, setLoadingScreen,
        data, setData,
        modalDeleteNotice, setModalDeleteNotice,
        deletingNotice, setDeletingNotice,
        modalDeleteNoticeLoading,
        initGetNoticesNoDate,
        deleteNotice,
        deleteId, setDeleteId
      }}>
      {children}
    </ListsContext.Provider>
  );
};
