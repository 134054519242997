import React, {useContext, useEffect} from "react";
import MetaTags from "react-meta-tags";
import ListNotice from "../../components/list-item/ListNotice";
import {ListsContext} from "../../context/lists";
import {IMAGES} from "../../constants/images";
import {defaultDescription} from "../../utils/helpers";

const MemorableDatesPage = ({layout: Layout}) => {
  const SLists = useContext(ListsContext);

  useEffect(() => {
    SLists.initGetNoticesNoDate();
  }, []);
  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <div className="container">
        <div className="event-view-area draft-area min-h">
          <div className="event-view history-area">
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image1"
              alt=""
            />
            <img
              src={IMAGES.Pic18}
              className="event-view__content-image2"
              alt=""
            />
            <div className="draft-area__content history-content">
              <div className="history-scroll">
                <ListNotice
                  type="REST"
                  data={SLists?.data?.filter((item) => item.date2 !== null)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MemorableDatesPage;
