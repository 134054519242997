import {createContext, useState} from 'react';

export const NoticesContext = createContext();

export const NoticesContextProvider = ({children}) => {

  //загрузка для экрана при входе
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [id, setId] = useState(null); //id для редактирования события
  const [file, setFile] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [noticeCategory, setNoticeCategory] = useState(null);
  const [errorNoticeCategory, setErrorNoticeCategory] = useState(null);
  const [comment, setComment] = useState('');
  const [errorComment, setErrorComment] = useState('');
  const [date1, setDate1] = useState('');
  const [errorDate1, setErrorDate1] = useState(null);
  const [date2, setDate2] = useState('');
  const [errorDate2, setErrorDate2] = useState(null);
  //чекбокс в notice
  const [toggleCheckBox, setToggleCheckBox] = useState(false);

  const [loadingSuccess, setLoadingSuccess] = useState(false);

  const [snapPoints, setSnapPoints] = useState([]);

  //Это состояние datepicker их в context нельзя запихивать, от туда они не работают
  const [datePickerHealth, setDatePickerHealth] = useState(new Date());
  const [openDatePickerHealth, setOpenDatePickerHealth] = useState(false);
  const [datePickerRest, setDatePickerRest] = useState(new Date());
  const [openDatePickerRest, setOpenDatePickerRest] = useState(false);

  const editNotice = (data) => {
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setFile(data.img);
    setComment(data.comment);
    setDate1(data.date1);
    setDatePickerHealth(new Date(datePickerHealth));
    setDate2(data.date2);
    setDatePickerRest(new Date(datePickerRest));
    setNoticeCategory(data.category);
    setId(data.id);
    setToggleCheckBox(data.add_to_draft);
  };


  const ResetStateNotices = () => {
    setLoadingScreen(true)
    setFirstName('');
    setErrorFirstName(null);
    setLastName('');
    setErrorLastName(null);
    setNoticeCategory(null);
    setErrorNoticeCategory(null);
    setComment('');
    setDate1('');
    setDatePickerHealth(new Date())
    setErrorDate1('');
    setErrorComment('');
    setDate2('');
    setDatePickerRest(new Date())
    setErrorDate2('');
    setToggleCheckBox(false);
    setFile('');

  };

  return (
    <NoticesContext.Provider
      value={{
        id, setId,
        file, setFile,
        loadingScreen, setLoadingScreen,
        firstName, setFirstName,
        errorFirstName, setErrorFirstName,
        lastName, setLastName,
        errorLastName, setErrorLastName,
        noticeCategory, setNoticeCategory,
        errorNoticeCategory, setErrorNoticeCategory,
        comment, setComment,
        date1, setDate1,
        errorDate1, setErrorDate1,
        date2, setDate2,
        errorDate2, setErrorDate2,
        toggleCheckBox, setToggleCheckBox,
        loadingSuccess, setLoadingSuccess,
        ResetStateNotices,
        editNotice,
        datePickerHealth, setDatePickerHealth,
        openDatePickerHealth, setOpenDatePickerHealth,
        datePickerRest, setDatePickerRest,
        openDatePickerRest, setOpenDatePickerRest,
        errorComment, setErrorComment
      }}>
      {children}
    </NoticesContext.Provider>
  );
};
