import {useContext} from "react";
import {Outlet, Navigate} from "react-router-dom";
import {UserContext} from "../../context/user";

const ProtectedRoute = () => {
  const SUser = useContext(UserContext);
  const isAuth = !!SUser.user;

  if (isAuth) return <Outlet />;
  return <Navigate to='/#login' />;
};

export default ProtectedRoute;
