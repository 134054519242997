import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {IMAGES} from "../constants/images";
import api from "../api";
import moment from "moment";
import "moment/locale/ru";
import {baseUrl, Declination, defaultDescription, unzeroDay} from "../utils/helpers";
import Load from "../components/load/Load";
import {UserContext} from "../context/user";
import OverlayScroll from "../components/OverlayScroll";
import {useToasts} from "react-toast-notifications";
import MetaTags from "react-meta-tags";

const NoticeViewPage = ({type}) => {
  const SUser = useContext(UserContext);
  const {addToast} = useToasts();

  const {id} = useParams();
  //храним событие по которому кликнули
  const [noticeView, setNoticeView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingNotice, setLoadingNotice] = useState(false);

  const isRest = (notice) => notice?.date2 !== null;

  useEffect(() => {
    api
      .getNotice(id)
      .then((resp) => {
        setNoticeView(resp.notice);
      })
      .catch((e) => {
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    return () => {
      SUser.resetModalPay();
    };
  }, []);

  const showModalPay = (type, typeNotice, price, invoice) => {
    SUser.setTypeNoticePay(noticeView);
    SUser.setShowModalPay((prevState) => ({
      ...prevState,
      type: type,
      typeNotice: typeNotice,
      show: true,
      price,
      invoice
    }));
  };

  const sendNotice = async () => {
    setLoadingNotice(true);

    const data = {
      type: isRest(noticeView) ? 'REST' : 'HEALTH',
      content: JSON.stringify([noticeView.firstname]),
      notice_id: noticeView.id
    };

    await api
      .postNote(data)
      .then(async (response) => {
        showModalPay(
          type === "REST" ? "REST" : "HEALTH",
          "draft",
          response.note.price,
          response.note.id
        );
      })
      .catch((error) => {
        addToast(error.message, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true,
        });

        console.log("error", error);
      })
      .finally(() => {
        setLoadingNotice(false);
      });
    // showModalPay(isRest(noticeView) ? "REST" : "HEALTH", "notice")
  };

  return (
    <OverlayScroll>
      <MetaTags>
        <title>Помянник</title>
        <meta
          name="description"
          content={defaultDescription}
        />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area view-block-size event-view-page">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{height: 300}}
              >
                <Load />
              </div>
            ) : (
              <div className="event-view">
                <div className="event-view__content">
                  <img
                    src={IMAGES.Pic18}
                    className="event-view__content-image1"
                    alt=""
                  />
                  <img
                    src={IMAGES.Pic18}
                    className="event-view__content-image2"
                    alt=""
                  />

                  <div className="event-view__photo-block">
                    {isRest(noticeView) ? (
                      <Link
                        to={`/notice-rest-edit/${noticeView?.id}`}
                        className="event-view__edit"
                      >
                        <img src={IMAGES.Icon13} alt="Редактировать" />
                      </Link>
                    ) : (
                      <Link
                        to={`/notice-health-edit/${noticeView?.id}`}
                        className="event-view__edit"
                      >
                        <img src={IMAGES.Icon13} alt="Редактировать" />
                      </Link>
                    )}

                    <div
                      className={
                        "event-view__photo-content " +
                        (isRest(noticeView)
                          ? "event-view__photo-content--rest"
                          : "")
                      }
                    >
                      {noticeView?.img !== null ? (
                        <img
                          className="event-view__photo"
                          src={`${baseUrl}storage/${noticeView?.img}`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="event-view__photo--stub"
                          src={isRest(noticeView) ? IMAGES.Pic22 : IMAGES.Pic21}
                          alt="Нет фото"
                        />
                      )}
                    </div>
                  </div>
                  <div className="event-view__body">
                    <div className="event-view__info-area">
                      {noticeView?.date1 ===
                        moment(new Date()).format("YYYY-MM-DD") && (
                        <div className="event-view__info">
                          <span>Сегодня День Рождения</span>
                        </div>
                      )}

                      {noticeView?.date2 ===
                        moment(new Date()).format("YYYY-MM-DD") && (
                        <div className="event-view__info event-view__info--rest">
                          <span>Сегодня День Памяти</span>
                        </div>
                      )}
                    </div>
                    <div className="event-info">
                      <div className="event-info__block">
                        {isRest(noticeView) ? (
                          <img
                            src={IMAGES.Icon16}
                            alt="тэг"
                            className="event-info__image"
                          />
                        ) : (
                          <img
                            src={IMAGES.Icon14}
                            alt="тэг"
                            className="event-info__image"
                          />
                        )}

                        <p
                          className={
                            "event-info__text " +
                            (isRest(noticeView) ? "event-info__text--rest" : "")
                          }
                        >
                          {isRest(noticeView) ? "День памяти" : "День Рождения"}
                        </p>
                      </div>
                      <div className="event-info__block">
                        {isRest(noticeView) ? (
                          <img
                            src={IMAGES.Icon17}
                            alt="тэг"
                            className="event-info__image"
                          />
                        ) : (
                          <img
                            src={IMAGES.Icon15}
                            alt="тэг"
                            className="event-info__image"
                          />
                        )}
                        <p
                          className={
                            "event-info__text " +
                            (isRest(noticeView) ? "event-info__text--rest" : "")
                          }
                        >
                          {noticeView?.category?.title}
                        </p>
                      </div>
                    </div>
                    <p
                      className={
                        "event-view__name " +
                        (isRest(noticeView) ? "event-view__name--rest" : "")
                      }
                    >
                      {noticeView.lastname && (
                        <strong>{noticeView.lastname} </strong>
                      )}
                      {noticeView?.firstname}
                    </p>
                    <div className="event-view-date">
                      <p
                        className={
                          "event-view-date__date1 " +
                          (isRest(noticeView)
                            ? "event-view-date__date1--rest"
                            : "")
                        }
                      >
                        {/*31 января 1976*/}
                        {unzeroDay(moment(noticeView.date1).format("LL")).slice(
                          0,
                          -2
                        )}

                        {isRest(noticeView) && (
                          <>
                            {" — "}
                            {unzeroDay(
                              moment(noticeView.date2).format("LL")
                            ).slice(0, -2)}
                          </>
                        )}
                      </p>
                      <p
                        className={
                          "event-view-date__date2 " +
                          (isRest(noticeView)
                            ? "event-view-date__date2--rest"
                            : "")
                        }
                      >
                        {/*45 лет*/}
                        {isRest(noticeView)
                          ? moment(noticeView.date2).diff(
                              noticeView.date1,
                              "years",
                              false
                            )
                          : moment().diff(
                              noticeView.date1,
                              "years",
                              false
                            )}{" "}
                        {Declination(
                          moment(noticeView.date2).diff(
                            noticeView.date1,
                            "years",
                            false
                          ),
                          ["год", "года", "лет"]
                        )}
                      </p>
                    </div>
                    <div
                      className={
                        "event-view__comment " +
                        (isRest(noticeView) ? "event-view__comment--rest" : "")
                      }
                    >
                      {noticeView?.comment}
                    </div>

                    <button
                      type="button"
                      onClick={() => sendNotice()}
                      className={
                        "button event-view__button " +
                        (isRest(noticeView) ? " button--t3" : " button--t2 ")
                      }
                    >
                      {!loadingNotice && <span>Подать записку</span>}

                      {loadingNotice && (
                        <div style={{height: "20px"}}>
                          <Load />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </OverlayScroll>
  );
};

export default NoticeViewPage;
