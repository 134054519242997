import OverlayScroll from "../../components/OverlayScroll";
import ModalSuccessNote from "../../components/modals/modal-access-note/ModalSuccessNote";
import {IMAGES} from "../../constants/images";

const PaymentSuccessPage = () => {
  return (
    <OverlayScroll>
      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area draft-area min-h payment-page">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <div className="draft-area__content">
                <ModalSuccessNote />
              </div>
            </div>
          </div>
        </div>
      </section>
    </OverlayScroll>
  );
};

export default PaymentSuccessPage;
