import {createContext, useState} from "react";
import api from "../api";
// import {useToasts} from "react-toast-notifications";

export const CategoriesContext = createContext();

export const CategoriesContextProvider = ({children}) => {
  //загрузка для экрана при входе
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [categories, setCategories] = useState([]);

  const [modalEditCategory, setModalEditCategory] = useState(false);
  //категория для удаления и редактирования хранится здесь
  const [editCategory, setEditCategory] = useState({});
  const [loadingEditCategory, setLoadingEditCategory] = useState(false);
  const [errorEditCategory, setErrorEditCategory] = useState(null);

  const [modalDeleteCategory, setModalDeleteCategory] = useState(false);

  const [title, setTitle] = useState("");  //Название категории при создании
  const [errorTitle, setErrorTitle] = useState(null);
  const [loadingAddCategories, setLoadingAddCategories] = useState(false);


  const initCategory = async () => {
    try {
      const categories = await api.getNoticeCategories();
      setCategories(categories.notice_categories);
      setLoadingScreen(false);
    } catch (error) {

    }
  };

  const createCategory = async (addToast) => {
    setLoadingAddCategories(true);
    const data = {
      title: title
    };

    await api.createCategory(data).then(() => {
      addToast("Категория создана", {
        id: "GOOD",
        appearance: "GOOD",
        autoDismiss: true
      });
      initCategory();
      setErrorTitle("");
      setTitle("");
      setLoadingAddCategories(false);
    }).catch(e => {
      // console.log('er', e);
      if (e?.response?.data.error.title) {
        setErrorTitle(e.response.data.error.title);
        addToast(e.response.data.error.title, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });
      } else {
        setErrorTitle("Что-то пошло не так");
      }
    }).finally(() => {
      setLoadingAddCategories(false);
    });
  };

  const editCategorySubmit = async (addToast) => {
    setLoadingEditCategory(true);
    await api.editCategory(editCategory).then(res => {
      addToast("Изменения сохранены", {
        id: "GOOD",
        appearance: "GOOD",
        autoDismiss: true
      });
      setModalEditCategory(false);
      initCategory();
    }).catch(e => {
      // console.log('editCategorySubmit error', e?.response?.data);
      addToast(e?.response?.data?.error.title, {
        id: "BAD",
        appearance: "BAD",
        autoDismiss: true
      });

      setErrorEditCategory(e?.response?.data?.error.title);

    }).finally(() => {
      setLoadingEditCategory(false);
    });
  };

  const deleteCategory = async (addToast) => {
    setLoadingEditCategory(true);
    const {id} = editCategory
    try {
      await api.deleteCategory(id);
      let newData = categories.filter(item => item.id !== id);
      setCategories(newData);
      addToast("Категория удалена", {
        id: "GOOD",
        appearance: "GOOD",
        autoDismiss: true
      });

    } catch (e) {
      // console.log("deleteCategory error", e);
      if (e?.response?.data.error) {
        addToast(e?.response?.data.error, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });
      } else {
        addToast(`Что-то пошло не так ${e.message}`, {
          id: "BAD",
          appearance: "BAD",
          autoDismiss: true
        });
      }
    }
    finally {
      setModalDeleteCategory(false)
      setLoadingEditCategory(false);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        setCategories,
        loadingScreen,
        setLoadingScreen,
        modalEditCategory,
        setModalEditCategory,
        createCategory,
        title, setTitle,
        errorTitle, setErrorTitle,
        loadingAddCategories, setLoadingAddCategories,
        initCategory,
        editCategory, setEditCategory,
        loadingEditCategory, setLoadingEditCategory,
        editCategorySubmit,
        errorEditCategory, setErrorEditCategory,
        deleteCategory,
        modalDeleteCategory, setModalDeleteCategory
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
