import {useState, useMemo, useContext} from "react";
import {IMAGES} from "../../constants/images";
import {UserContext} from "../../context/user";
import api from "../../api";
import {useToasts} from "react-toast-notifications";
import {defaultDescription} from "../../utils/helpers";
import MetaTags from "react-meta-tags";

const SupportPage = ({layout: Layout}) => {
  const {user} = useContext(UserContext);
  const [supportHeader, setSupportHeader] = useState("");
  const [supportText, setSupportText] = useState("");
  const [errors, setErrors] = useState({});
  const [disabledButton, setDisabledButton] = useState(true);
  const {addToast} = useToasts();

  useMemo(() => {
    setDisabledButton(true);
    if (supportText.length > 0) {
      setDisabledButton(false);
    }
  }, [supportText]);

  const Submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      email: user?.email,
      name: user?.name,
      theme: supportHeader,
      message: supportText,
    };

    api
      .postFeedback(data)
      .then((resp) => {
        setSupportHeader("");
        setSupportText("");
        setErrors({});

        addToast("Сообщение отправлено", {
          id: "GOOD",
          appearance: "GOOD",
          autoDismiss: true,
        });
      })
      .catch((e) => {
        if (e?.response?.data) {
          if (e?.response?.data?.message) {
            addToast(e.response.data.message, {id: "BAD", appearance: "BAD"});
          }

          if (e?.response?.data?.error) {
            setErrors(e.response.data.error);
            console.log("setErrors", e.response.data.error);
          }
        } else {
          addToast(e.message, {id: "BAD", appearance: "BAD"});
        }
      });
  };

  return (
    <Layout>
      <MetaTags>
        <title>Помянник</title>
        <meta name="description" content={defaultDescription} />
      </MetaTags>

      <section className="container-fluid">
        <div className="container">
          <div className="event-view-area view-block-size">
            <div className="event-view history-area">
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image1"
                alt=""
              />
              <img
                src={IMAGES.Pic18}
                className="event-view__content-image2"
                alt=""
              />
              <form
                onSubmit={Submit}
                className="draft-area__content support-content"
              >
                <div className="input-container mb-30">
                  <input
                    value={supportHeader || ""}
                    onChange={(e) => setSupportHeader(e.target.value)}
                    type="text"
                    className="input"
                    placeholder="Тема сообщения"
                  />
                  <div
                    className={
                      "input-error " + (errors.theme ? "input-error--open" : "")
                    }
                  >
                    {errors.theme}
                  </div>
                </div>
                <div className="input-container mb-15">
                  <textarea
                    value={supportText || ""}
                    onChange={(e) => setSupportText(e.target.value)}
                    className="textarea textarea__support"
                    placeholder="Текст сообщения"
                  />

                  <div
                    className={
                      "input-error " +
                      (errors.message ? "input-error--open" : "")
                    }
                  >
                    {errors.message}
                  </div>
                </div>
                <div className="event__buttons">
                  <button
                    type="submit"
                    className="button button--t2 event__button-create"
                    disabled={disabledButton}
                  >
                    <span>Отправить сообщение</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SupportPage;
